import axios from 'axios'

const BASE_URL = process.env.REACT_APP_BASE_URL; //"http://gasappdev.app-cms.com/";

// Set the base url for all AXIOS API calls
axios.defaults.baseURL = BASE_URL;

export const API = (function(axios) {
    return {
        setAuthenticationToken : (token) => {
            if (token !== undefined && token !== null) {
                axios.defaults.headers.common["authToken"] = token;
            } else if (token === null) {
                delete axios.defaults.headers.common["authToken"];
            }
        },
        onAuthFailed : (callback) => {
            if (callback instanceof Function) {
                axios.defaults.validateStatus = (status) => {
                    if (status === 401 || status === 403) {
                        callback()
                    } else {
                        return status;
                    }
                }
            }
        },
        parseResponse(resp) {
            var out = {
                success : false,
                data : null,
                error : "Unknown error occurred"
            };

            if (resp != null) {
                out.success = resp.data.success;
                out.error = resp.data.error;
                out.data = resp.data.data;
            }

            return out;
        },
        USER_TYPES : {
            ADMINISTRATOR : 1,
            STANDARD : 2
        },
        MERCHANT_TYPES : {
            INDEPENDENT : 1,
            CORPORATE : 2
        }
    }
})(axios);

export const ENDPOINTS = {
    login : {
        submit : "merchant-portal/login",
        validatePasswordReset : "merchant-portal/login/validatePasswordReset",
        requestPasswordReset : "merchant-portal/login/requestUserPasswordReset",
        submitPasswordReset : "merchant-portal/login/submitUserPasswordReset"
    },
    merchant : {
        get : "merchant-portal/merchant/getMerchant",
        update : "merchant-portal/merchant/submitMerchant",
        uploadImage : "merchant-portal/merchant/uploadMerchantImage"
    },
    images : {
        uploadImage : "merchant-portal/images/uploadImage",
        getImage : "merchant-portal/images/getImage",
        deleteImage : "merchant-portal/images/deleteImage",
        getList : "merchant-portal/images/getImages"
    },
    deal : {
        getCounts : "merchant-portal/deal/getDealSlotCounts",
        getImpressions : "merchant-portal/deal/getImpressions",
        getList : "merchant-portal/deal/getDeals",
        get : "merchant-portal/deal/getDeal",
        submit : "merchant-portal/deal/submitDeal",
        uploadImage : "merchant-portal/deal/uploadDealImage",
        cancel : "merchant-portal/deal/cancelDeal",
        searchAddress : "merchant-portal/deal/searchAddress",
        setLibraryImage : "merchant-portal/deal/setLibraryImage",
        getPartners : "merchant-portal/deal/getPartners",
        getBranchDealsReport : "merchant-portal/deal/getBranchDealsReport",
        getBranchesWithoutDeals : "merchant-portal/deal/getBranchesWithoutDeals"
    },
    user : {
        getList : "merchant-portal/user/getMerchantUsers",
        getUser : "merchant-portal/user/getUser",
        getUserTypes : "merchant-portal/user/getMerchantUserTypes",
        getLocations : "merchant-portal/user/getMerchantLocations",
        submitUser : "merchant-portal/user/submitMerchantUser",
        deleteUser : "merchant-portal/user/deleteMerchantUser",
        getPlanDetails : "merchant-portal/user/getPlanDetails",
        changePassword : "merchant-portal/user/changeUserPassword",
        saveBranches : "merchant-portal/user/saveBranches",
        getBranches :  "merchant-portal/user/getBranches"
    },
    location : {
        getLocation : "merchant-portal/location/getLocation",
        getLocations : "merchant-portal/location/getLocations",
        submitLocation : "merchant-portal/location/submitLocation",
        uploadImage : "merchant-portal/location/uploadMerchantLocationImage",
        getCounts : "merchant-portal/location/getLocationCounts",
        deleteLocation : "merchant-portal/location/deleteLocation"
    },
    pricing : {
        getPrices : "merchant-portal/pricing/getPricingOptions",
        changePricingOption : "merchant-portal/pricing/changePricingOption"
    }
};
