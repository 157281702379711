import React from "react";
import StatefulComponent from "../StatefulComponent";
import Axios from "axios";
import {API, ENDPOINTS} from "../../networking/API";
import HeaderComponent from "../common/HeaderComponent";
import AlertModal from "../common/AlertModal";

import '../../assets/css/user-editor-screen.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class UserEditorScreenComponent extends StatefulComponent {

    constructor(props, context) {
        super(props, context);

        this.handleScreenRestriction([API.USER_TYPES.ADMINISTRATOR]);

        var userSubmissionId = localStorage.getItem("successfulUserSubmissionId");
        localStorage.removeItem("successfulUserSubmissionId");

        this.initState({
            user : null,
            userTypes : [],
            locations : [],
            userSubmissionId : userSubmissionId
        });

        this.getUserTypesFromNetwork = this.getUserTypesFromNetwork.bind(this);
        this.getLocationsFromNetwork = this.getLocationsFromNetwork.bind(this);
        this.getUserFromNetwork = this.getUserFromNetwork.bind(this);
        this.putUserInState = this.putUserInState.bind(this);
        this.formWasSubmitted = this.formWasSubmitted.bind(this);
        this.deleteUserOverNetwork = this.deleteUserOverNetwork.bind(this);
    }

    componentDidMount() {
        this.getUserTypesFromNetwork();
        this.getLocationsFromNetwork();

        if (this.props.match.params.id != null && this.props.match.params.id !== "new") {
            this.getUserFromNetwork()
        }
    }

    putUserInState(user) {
        console.log("GOT USER");
        console.log(user);

        this.setState({
            user : user,
            userName : user.name,
            userEmailAddress : user.email,
            userMerchantType : user.merchantUserTypeId,
            userLocation : user.merchantLocationId
        });
    }

    getUserFromNetwork() {
        let formData = new FormData();
        formData.append("id", this.props.match.params.id);

        var context = this;

        Axios.post(ENDPOINTS.user.getUser, formData)
            .then(function(response) {
                let resp = API.parseResponse(response);
                if (resp.success) {
                    context.putUserInState(resp.data);
                } else {
                    context.showModal("Error", resp.error);
                }
            })
            .catch(function(err) {
                context.showModal("Error", "An unknown error occurred. [101]");
            });
    }

    getUserTypesFromNetwork() {
        var context = this;

        Axios.get(ENDPOINTS.user.getUserTypes)
            .then(function(response) {
                let resp = API.parseResponse(response);
                if (resp.success) {
                    let newState = {
                        userTypes : resp.data
                    };

                    if (resp.data.length > 0) {
                        if (context.state.userMerchantType == null) {
                            newState.userMerchantType = resp.data[0].id;
                        }
                    }

                    context.setState(newState);
                } else {
                    context.showModal("Error", resp.error);
                }
            })
            .catch(function(err) {
                console.log(err);
                context.showModal("Error", "An unknown error occurred. [102]");
            });
    }

    getLocationsFromNetwork() {
        var context = this;

        Axios.get(ENDPOINTS.user.getLocations)
            .then(function(response) {
                let resp = API.parseResponse(response);
                if (resp.success) {
                    let newState = {
                        locations : resp.data
                    };

                    if (resp.data.length > 0) {
                        if (context.state == null || context.state.userLocation == null) {
                            newState.userLocation = resp.data[0].id;
                        }
                    }

                    context.setState(newState);
                } else {
                    context.showModal("Error", resp.error);
                }
            })
            .catch(function(err) {
                console.log(err);
                context.showModal("Error", "An unknown error occurred. [103]");
            })
    }

    formWasSubmitted() {
        console.log(this.state);

        var validationRules = [
            {
                key : "userName",
                label : "Name",
                postName : "name"
            },
            {
                key : "userEmailAddress",
                label : "Email Address",
                postName : "email"
            },
            {
                key : "userMerchantType",
                label : "User Type",
                postName : "userTypeId"
            },
            {
                key : "userLocation",
                label : "Assigned Location",
                postName : "locationId"
            },
            {
                key : "userPassword",
                label : "Password",
                postName : "password",
                optional : this.state.user != null
            },
            {
                key : "userPasswordRetype",
                label : "Password",
                postName : "passwordRetype",
                optional : this.state.user != null
            }
        ];

        if (this.state.userPassword != null && this.state.userPassword !== "") {
            if (this.state.userPassword !== this.state.userPasswordRetype) {
                this.showModal("Error","Password and Password Confirmation do not match. Please try again.");
                return;
            }
        }

        var validationResult = this.validateCreateFormData(this.state, validationRules);

        if (validationResult.success) {
            this.submitUserOverNetwork(validationResult.formData);
        } else {
            this.showModal("Error", validationResult.error);
        }
    }

    submitUserOverNetwork(formData) {
        if (this.state.submittingForm) return;
        this.setState({
            submittingForm : true
        });

        if (this.state.user != null) {
            formData.append("id", this.state.user.id);
        }

        var context = this;
        Axios.post(ENDPOINTS.user.submitUser, formData)
            .then((response) => {
                let resp = API.parseResponse(response);
                if (resp.success) {
                    localStorage.setItem("successfulUserSubmissionId", resp.data.id);
                    window.location.href = "/users/" + resp.data.id;
                } else {
                    context.showModal("Error", resp.error);
                }

                this.setState({
                    submittingForm : false
                });
            })
            .catch((err) => {
                this.setState({
                    submittingForm : false
                });
                context.showModal("Error", "An unknown error occurred. [104]");
            });
    }

    deleteUserOverNetwork(event, confirmed) {
        if (this.state.user == null) return;

        if (confirmed === undefined || !confirmed) {
            this.showModal("Please confirm", "Are you sure you want to delete this User? This action cannot be undone.", [
                {
                    label : "Delete User",
                    style : "btn-danger",
                    click : () => {
                        this.deleteUserOverNetwork(event, true);
                    }
                },
                {
                    label : "Cancel",
                    style : "btn-success",
                    click : () => {
                        this.hideModal();
                    }
                }
            ]);
        } else {
            let formData = new FormData();
            formData.append("id", this.state.user.id);

            Axios.post(ENDPOINTS.user.deleteUser, formData)
                .then((r) => {
                   let resp = API.parseResponse(r);
                   if (resp.success) {
                       localStorage.setItem("userWasDeleted", this.state.user.id);
                       window.location.href = "/users";
                   } else {
                       this.showModal("Error", resp.error);
                   }
                })
                .catch((e) => {
                    console.log(e);
                    this.showModal("Error", "An unknown error has occurred. [109]");
                });
        }
    }

    render() {
        var userTypeElements = [];
        for (let i = 0; i < this.state.userTypes.length; i++) {
            let userType = this.state.userTypes[i];

            userTypeElements.push(
                <option value={userType.id}>{userType.name}</option>
            );
        }

        var locationElements = [];
        for (let i = 0; i < this.state.locations.length; i++) {
            let location = this.state.locations[i];

            locationElements.push(
                <option value={location.id}>{location.name}</option>
            );
        }

        var messageElem = [];
        var deleteButton = [];

        if (this.state.user != null) {
            var loggedInUser = JSON.parse(localStorage.getItem("user"));
            if (this.state.user.id !== loggedInUser.id) {
                deleteButton.push(
                    <button className={"btn btn-danger"} onClick={this.deleteUserOverNetwork}>Delete User</button>
                );
            }
        }

        if (this.state.user != null) {
            if (this.state.userSubmissionId != null) {
                if (this.state.userSubmissionId == this.state.user.id) {
                    messageElem.push(
                        <div className="alert alert-success" role="alert">Changes have been saved.</div>
                    );
                }
            }
        }

        return (
            <div className={"container"}>
                <HeaderComponent />

                <div className={"page-header"}>
                    <div className={"page-title"}>
                        <span className={"btn btn-outline-primary"} onClick={() => window.location.href = "/users/"}>
                            <FontAwesomeIcon icon={"chevron-left"} />
                        </span>
                        { (this.state.user != null) ? "Edit User" : "New User" }
                    </div>
                    <div className={"page-actions"}>
                        {deleteButton}
                    </div>
                </div>

                {messageElem}

                <div className={"section-container user-editor-screen"}>
                    <div className={"section-content"}>
                        <div className={"row"}>
                            <div className={"col-12 col-md-6"}>
                                <label>First Name</label>
                                <input type={"text"} className={"form-control"} name={"userName"} value={this.state.userName} onChange={this.handleChange} />
                            </div>

                            <div className={"col-12 col-md-6"}>
                                <label>Email Address</label>
                                <input type={"text"} className={"form-control"} name={"userEmailAddress"} value={this.state.userEmailAddress} onChange={this.handleChange} autoComplete={"off"} />
                            </div>
                        </div>

                        <div className={"row form-block"}>
                            <div className={"col-12 col-md-6"}>
                                <label>Password</label>
                                <input type={"password"} className={"form-control"} name={"userPassword"} value={this.state.userPassword} onChange={this.handleChange} placeholder={(this.state.user != null) ? "(Keep current password)" : ""} autoComplete={"new-password"} />
                            </div>

                            <div className={"col-12 col-md-6"}>
                                <label>Confirm Password</label>
                                <input type={"password"} className={"form-control"} name={"userPasswordRetype"} value={this.state.userPasswordRetype} onChange={this.handleChange} autoComplete={"new-password"} />
                            </div>
                        </div>

                        <div className={"row form-block"}>
                            <div className={"col-12 col-md-6"}>
                                <label>User Type</label>
                                <select className={"form-control"} name={"userMerchantType"} value={this.state.userMerchantType} onChange={this.handleChange}>
                                    {userTypeElements}
                                </select>
                            </div>

                            <div className={"col-12 col-md-6"}>
                                <label>Assign to Location</label>
                                <select className={"form-control"} name={"userLocation"} value={this.state.userLocation} onChange={this.handleChange}>
                                    {locationElements}
                                </select>
                            </div>
                        </div>

                        <div className={"row submission-area"}>
                            <div className={"col-12 text-center"}>
                                <button className={"btn btn-success"} onClick={this.formWasSubmitted}>{ (this.state.user == null) ? "Create User" : "Save Changes"}</button>
                            </div>
                        </div>
                    </div>
                </div>

                <AlertModal
                    open={ (this.state.modal != null) ? this.state.modal.open : false }
                    title={ (this.state.modal != null) ? this.state.modal.title : "" }
                    content={ (this.state.modal != null) ? this.state.modal.content : [] }
                    buttons={ (this.state.modal != null) ? this.state.modal.buttons : [] }
                    dismissHandler={ () => this.modalWasDismissed() }
                />
            </div>
        );
    }

}

export default UserEditorScreenComponent;