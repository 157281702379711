import StatefulComponent from "../StatefulComponent";
import HeaderComponent from "../common/HeaderComponent";
import React from "react";
import SegmentedButtonComponent from "../common/SegmentedButtonComponent";
import LocationDealReportScreenComponent from "./LocationDealReportScreenComponent";

import "./LocationDealReportScreenComponent.css";
import LocationNoDealReportContainerComponent from "./LocationNoDealReportContainerComponent";

export default class LocationDealReportContainerComponent extends StatefulComponent {

    REPORT_TYPE_LOCATION_DEALS = 1;
    REPORT_TYPE_LOCATION_NO_DEALS = 2;

    constructor(props, context) {
        super(props, context);

        let segmentedButtons = [
            { id : this.REPORT_TYPE_LOCATION_DEALS, label : "Location Deals"},
            { id : this.REPORT_TYPE_LOCATION_NO_DEALS, label : "Locations without Deals"}
        ];

        this.initState({
            segmentedButtons,
            selectedSegmentId : this.REPORT_TYPE_LOCATION_DEALS
        })
    }

    segmentDidChange = (id) => {
        this.setState({
            selectedSegmentId : id
        });
    }

    render() {
        let content = [];
        if (this.state.selectedSegmentId === this.REPORT_TYPE_LOCATION_DEALS) {
            content = (
                <LocationDealReportScreenComponent {...this.props} />
            )
        } else if (this.state.selectedSegmentId === this.REPORT_TYPE_LOCATION_NO_DEALS) {
            content = (
                <LocationNoDealReportContainerComponent {...this.props} />
            )
        }

        return (
            <div className={"container location-deal-report-screen-component"}>
                <HeaderComponent />

                <div className={"page-header"}>
                    <div className={"page-title"}>Deal Report</div>
                    <div className={"page-actions"} />
                </div>

                {content}

            </div>
        )
    }
}