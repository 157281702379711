import React from 'react';
import '../../assets/css/segmented-button.css';

class SegmentedButtonComponent extends React.Component {



    constructor(props, context) {
        super(props, context);

        var selectedId = -1;
        if (this.props.selectedId != null) {
            console.log("SELECTED ID: " + this.props.selectedId);
            selectedId = this.props.selectedId;
        }

        this.state = {
            selectedId : selectedId
        };
        this.setState(this.state);

        this.segmentWasClicked = this.segmentWasClicked.bind(this);
    }

    segmentWasClicked(id) {
        this.setState({
            selectedId : id
        });

        if (this.props.changeHandler) {
            this.props.changeHandler(id);
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.selectedId === undefined){
            this.setState({
                selectedId : undefined
            });

        }
    }

    render() {
        var context = this;

        var buttons = [];

        this.props.buttons.forEach(function(element, index) {
            var className = "segment";
            if (context.state.selectedId == element.id) {
                className += " active";
            }

            buttons.push(
                <div className={className} key={element.id} onClick={() =>  context.segmentWasClicked(element.id)}>{ element.label }</div>
            );
        });

        return (
            <div className={"segmented-buttons"}>
                { buttons }
            </div>
        );
    }

}

export default SegmentedButtonComponent;