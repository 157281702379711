import React from 'react';
import "./LoadingSpinner.css";

export default class LoadingSpinner extends React.Component {

    render() {
        if (!this.props.shown && !this.props.open) return;

        return (
            <div className={"loading-spinner"}>
                <div className={"slice one"}>
                    <div className={"dot"} />
                </div>

                <div className={"slice two"}>
                    <div className={"dot"} />
                </div>

                <div className={"slice three"}>
                    <div className={"dot"} />
                </div>

                <div className={"slice four"}>
                    <div className={"dot"} />
                </div>
            </div>
        )
    }

}