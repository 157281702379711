import React from 'react';

import moment from 'moment';
import InfiniteScroll from 'react-infinite-scroller';

import "../../assets/css/deal-list.css";

const DEAL_TYPE_ACTIVE = 1;
const DEAL_TYPE_INACTIVE = 2;

class DealListComponent extends React.Component {

    constructor(props, context) {
        super(props, context);

        this.dealWasClicked = this.dealWasClicked.bind(this);
    }

    dealWasClicked(id) {
        if (this.props.clickHandler != null) {
            this.props.clickHandler(id);
        }
    }

    render() {
        var items = [];

        if (this.props.items != null) {
            var context = this;

            var headline = null;
            this.props.items.forEach(function(deal, index) {
                if (context.props.mode == DEAL_TYPE_INACTIVE) {
                    var newHeadline = moment(deal.startDate).format("MMMM");
                    if (newHeadline != headline) {
                        headline = newHeadline;
                        items.push(
                            <div key={headline} className={"col-12"}>
                                <h2>{ headline }</h2>
                            </div>
                        );
                    }
                }

                items.push(
                    <div key={deal.id} className={"col-12 col-md-6 col-lg-4"} onClick={() => context.dealWasClicked(deal.id)}>
                        <div className={"deal-list-item"}>
                            <div className={"deal-image"} style={{ backgroundImage : "url(" + deal.imageUrl + ")" }} />
                            <div className={"deal-title"}>{ deal.title }</div>
                            <div className={"deal-description"}>{ deal.longDescription }</div>

                            <div className={"deal-info"}>
                                <div className={"info-segment"}>
                                    <div className={"title"}>Start Date</div>
                                    <div className={"content"}>{ moment(deal.startDate).format("DD/MM/YYYY") }</div>
                                </div>
                                <div className={"info-segment"}>
                                    <div className={"title"}>End Date</div>
                                    <div className={"content"}>{ moment(deal.endDate).format("DD/MM/YYYY") }</div>
                                </div>

                            </div>

                            <div className={"deal-info"}>
                                <div className={"info-segment"}>
                                    <div className={"title"}>Impressions</div>
                                    <div className={"content"}>{ deal.impressions }</div>
                                </div>
                                <div className={"info-segment"}>
                                    <div className={"title"}>Clicks</div>
                                    <div className={"content"}>{ deal.clicks }</div>
                                </div>
                                <div className={"info-segment"}>
                                    <div className={"title"}>Likes</div>
                                    <div className={"content"}>{ deal.karmaCache }</div>
                                </div>
                            </div>

                            <div className={"deal-info"}>
                                <div className={"info-segment"}>
                                    <div className={"title"}>Total Likes</div>
                                    <div className={"content"}>{ deal.upvoteCount }</div>
                                </div>
                                {/*<div className={"info-segment"}>
                                    <div className={"title"}>Downvotes</div>
                                    <div className={"content"}>{ deal.downvoteCount }</div>
                                </div>*/}
                            </div>
                        </div>
                    </div>
                );
            });
        }

        if (items.length === 0 && !this.props.isLoading) {
            items.push(<span className={"empty-message"}>No Merchant Deals to display</span>);
        }

        return (
            <InfiniteScroll
                className={"row deal-list-items"}
                pageStart={1}
                loadMore={this.props.loadMore}
                hasMore={this.props.hasMore}
                useWindow={true}>
                {items}
            </InfiniteScroll>
        );
    }

}

export default DealListComponent;