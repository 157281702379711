import React from "react";
import '../../assets/css/map-marker.css';

class Marker extends React.Component {

    render() {
        return (
            <div className={"google-maps-pin"} />
        )
    }

}

export default Marker;