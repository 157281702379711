import React from 'react';

import '../../assets/css/ep-table.css';

class TableComponent extends React.Component {

    SORT_DIRECTION_NONE = "none";
    SORT_DIRECTION_ASCENDING = "asc";
    SORT_DIRECTION_DESCENDING = "desc";

    constructor(props, context) {
        super(props, context);

        var columns = [];

        if (this.props.children != null) {
            React.Children.map(this.props.children, function(child) {
                if (child.type == Column) {
                    let columnDef = {
                        name : child.props.name,
                        title : child.props.title,
                        className : child.props.className
                    };

                    if (child.props.render != null) {
                        columnDef.render = child.props.render;
                    }

                    if (child.props.hasOwnProperty("sortable")) {
                        columnDef.sortable = child.props.sortable;
                    }

                    columns.push(columnDef);
                }
            });
        } else if (this.props.columns != null) {
            columns = this.props.columns;
        }

        this.state = {
            columns : columns
        };
    }

    handleClick(row) {
        if (this.props.onRowClick != null) {
            this.props.onRowClick(row);
        }
    }

    handleHeadingClick = (column) => {
        if (this.props.onSort != null) {
            if (column != null) {
                if (column.sortable) {
                    let currentSort = this.SORT_DIRECTION_NONE;

                    let sortedColumn = null;
                    if (this.props.hasOwnProperty("sortedColumn")) {
                        sortedColumn = this.props.sortedColumn;
                    }

                    if (sortedColumn != null) {
                        if (sortedColumn.name === column.name) {
                            currentSort = sortedColumn.direction;
                        }
                    }

                    if (currentSort === this.SORT_DIRECTION_ASCENDING) {
                        currentSort = this.SORT_DIRECTION_DESCENDING;
                    } else {
                        currentSort = this.SORT_DIRECTION_ASCENDING;
                    }

                    this.props.onSort({
                        name : column.name,
                        direction : currentSort
                    });
                }
            }
        }
    }

    render() {
        var headerColumns = [];
        var tableRows = [];

        let sortedColumn = null;
        if (this.props.hasOwnProperty("sortedColumn")) {
            sortedColumn = this.props.sortedColumn;
        }

        if (this.state.columns != null) {
            for (var i = 0; i < this.state.columns.length; i++) {
                let column = this.state.columns[i];

                let sortable = false;
                if (column.hasOwnProperty("sortable")) {
                    sortable = column.sortable === true;
                }

                let sortDirection = this.SORT_DIRECTION_NONE;
                if (sortedColumn != null) {
                    if (sortedColumn.name === column.name) {
                        sortDirection = sortedColumn.direction;
                    }
                }

                let columnHeaderClass = column.className;
                if (sortable) {
                    if (columnHeaderClass === undefined || columnHeaderClass == null) {
                        columnHeaderClass = "";
                    } else {
                        columnHeaderClass += " ";
                    }

                    columnHeaderClass = "sortable";

                    if (sortDirection === this.SORT_DIRECTION_ASCENDING) {
                        columnHeaderClass += " sortable-asc";
                    } else if (sortDirection === this.SORT_DIRECTION_DESCENDING) {
                        columnHeaderClass += " sortable-desc";
                    } else {
                        columnHeaderClass += " sortable-none";
                    }
                }

                headerColumns.push(
                    <th key={"th_" + i} className={columnHeaderClass} onClick={() => this.handleHeadingClick(column)} style={{textAlign:"center"}}>
                        {column.title}
                    </th>
                );
            }

            if (this.props.data != null) {
                for (var i = 0; i < this.props.data.length; i++) {
                    var columns = [];

                    let data = this.props.data[i];

                    for (var x = 0; x < this.state.columns.length; x++) {
                        let column = this.state.columns[x];
                        let colValue = "";

                        if (data.hasOwnProperty(column.name)) {
                            var colData = data[column.name];

                            if (column.hasOwnProperty("render")) {
                                colValue = column.render(colData, data)
                            } else {
                                colValue = colData;
                            }
                        }

                        if (this.props.suppressNulls === true) {
                            if (colValue === null || colValue === "null") {
                                colValue = "";
                            }
                        }

                        columns.push(
                            <td key={"td_" + i + "_" + x} className={column.className}>
                                { colValue }
                            </td>
                        );
                    }

                    tableRows.push(
                        <tr key={i} onClick={() => this.handleClick(data)}>
                            { columns }
                        </tr>
                    );
                }
            }
        }

        if (tableRows.length == 0) {
            var emptyClassName = "text-center";
            if (this.props.emptyClassName != null) {
                emptyClassName = this.props.emptyClassName;
            }

            var emptyMessage = "No data to display";
            if (this.props.emptyMessage != null) {
                emptyMessage = this.props.emptyMessage;
            }

            tableRows.push(<tr>
                <td colSpan={headerColumns.length} className={emptyClassName}>{emptyMessage}</td>
            </tr>);
        }

        let tableClassName = "ep-table";
        if (this.props.className !== undefined && this.props.className != null) {
            tableClassName += " " + this.props.className;
        }

        return (
            <table className={tableClassName}>
                <thead>
                <tr>
                    { headerColumns }
                </tr>
                </thead>
                <tbody>
                { tableRows }
                </tbody>
            </table>
        );
    }

}

export default TableComponent;

class Column extends React.Component {

}

class TablePaginator extends React.Component {

    constructor(props, context) {
        super(props, context);

        this.incrementPage = this.incrementPage.bind(this);
        this.decrementPage = this.decrementPage.bind(this);
    }

    handleClick(index) {
        if (this.props.onClick != null) {
            this.props.onClick(index);
        }
    };

    incrementPage() {
        var totalCount = 0;
        if (this.props.totalCount != null) {
            totalCount = Math.max(0, this.props.totalCount);
        }

        var pageSize = 10;
        if (this.props.pageSize != null) {
            pageSize = Math.max(1, this.props.pageSize);
        }

        var maxPages = parseInt(Math.ceil(totalCount / pageSize));
        var nextPage = parseInt(Math.min(maxPages, this.props.page + 1));

        this.handleClick(nextPage);
    }

    decrementPage() {
        var nextPage = Math.max(1, this.props.page - 1);
        this.handleClick(nextPage);
    }

    render() {
        var totalCount = 0;
        if (this.props.totalCount != null) {
            totalCount = Math.max(0, this.props.totalCount);
        }

        var pageSize = 10;
        if (this.props.pageSize != null) {
            pageSize = Math.max(1, this.props.pageSize);
        }

        var page = 1;
        if (this.props.page != null) {
            page = Math.max(1, this.props.page);
        }

        var maxItems = 10;
        if (this.props.maxItems != null) {
            maxItems = this.props.maxItems;
        }

        var items = [];

        items.push(<span className={"page-prev"} onClick={this.decrementPage}>&#9664;</span>);

        var totalPages = parseInt(Math.ceil(totalCount / pageSize));
        totalPages = Math.max(totalPages, 1);
        if (totalPages > maxItems) {
            var halfPage = maxItems / 2;

            var insertPosition = false;
            var countTo = halfPage;
            var backendCount = (totalPages - (halfPage - 1));
            if (page > halfPage && (page <= backendCount)) {
                halfPage--;
                insertPosition = true;
            }

            for (let i = 0; i < halfPage; i++) {
                var extraClass = "";
                if (page === (i + 1)) {
                    extraClass = " active";
                }
                items.push(<span className={"page-item" + extraClass} onClick={() => this.handleClick((i + 1))}>{(i + 1)}</span>);
            }

            if (insertPosition) {
                items.push(<span className={"page-gap"}>&#8230;</span>);
                items.push(<span className={"page-item active"} onClick={() => this.handleClick(page)}>{page}</span> );
            }
            items.push(<span className={"page-gap"}>&#8230;</span>);

            for (let i = backendCount; i < totalPages; i++) {
                var extraClass = "";
                if (page === (i + 1)) {
                    extraClass = " active";
                }
                items.push(<span className={"page-item" + extraClass} onClick={() => this.handleClick((i + 1))}>{(i + 1)}</span>);
            }
        } else {
            for (let i = 0; i < totalPages; i++) {
                var extraClass = "";
                if (page === (i + 1)) {
                    extraClass = " active";
                }
                items.push(<span className={"page-item" + extraClass} onClick={() => this.handleClick((i + 1))}>{(i + 1)}</span>);
            }
        }

        items.push(<span className={"page-next"} onClick={this.incrementPage}>&#9658;</span>);

        var out = (
            <span className={"ep-table-paginate"}>
                {items}
            </span>
        );

        return out;
    };

}

export { Column, TablePaginator };