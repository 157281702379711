import React from "react";
import StatefulComponent from "../StatefulComponent";
import HeaderComponent from "../common/HeaderComponent";
import TableComponent, {Column, TablePaginator} from "../common/TableComponent";
import Axios from "axios";
import {API, ENDPOINTS} from "../../networking/API";
import RemainingCountComponent from "../common/RemainingCountComponent";

class LocationListScreenComponent extends StatefulComponent {

    filterKeywordTimeout = undefined;

    constructor(props, context) {
        super(props, context);

        this.handleScreenRestriction([API.USER_TYPES.ADMINISTRATOR]);

        var user = localStorage.getItem("user");
        if (user != null) {
            user = JSON.parse(user);
        }

        var showDeleteConfirmation = false;
        if (localStorage.getItem("locationDidDelete") != null) {
            showDeleteConfirmation = true;
            localStorage.removeItem("locationDidDelete");
        }

        this.initState({
            user,
            tablePage : 1,
            tableTotal : 0,
            tableData : [],
            tableLimit : 20,
            showDeleteConfirmation : showDeleteConfirmation,
            sortedColumn : { name : "name", direction : "asc" },
            locationFilter : ""
        });

        this.locationImageInput = React.createRef();

        this.getTableDataFromNetwork = this.getTableDataFromNetwork.bind(this);
        this.getLocationTotalsFromNetwork = this.getLocationTotalsFromNetwork.bind(this);

    }

    componentDidMount() {
        this.getTableDataFromNetwork(1);
        this.getLocationTotalsFromNetwork();
    }

    tablePageDidChange(page) {
        this.setState({
            tablePage : page
        });

        this.getTableDataFromNetwork(page);
    }

    tableRowWasClicked(row) {
        this.moveToLocationEditor(row.id);
    }

    moveToLocationEditor(id) {
        window.location.href = "/locations/" + id;
    }

    moveToDealReportScreen = () => {
        this.props.history.push("/locations-deal-report/");
    }

    getLocationTotalsFromNetwork() {
        Axios.get(ENDPOINTS.location.getCounts)
            .then((r) => {
                let resp = API.parseResponse(r);
                if (resp.success) {
                    this.setState({
                        locationsAvailableCount : resp.data.availableLocations,
                        locationsTotalCount : resp.data.maxLocations,
                        locationsUsedCount : resp.data.usedLocations
                    });
                } else {
                    this.showModal("Error", resp.error);
                }
            })
    }

    getTableDataFromNetwork(page, sort) {
        if (this.state.fetchingTableData) return;
        this.setState({
            fetchingTableData : true
        });

        if (page === undefined) {
            page = this.state.tablePage;
        }

        if (sort === undefined) {
            console.log("Sort was undefined");
            sort = this.state.sortedColumn;
        }

        let formData = new FormData();
        formData.append("page", page);
        formData.append("limit", this.state.tableLimit);

        if (sort !== undefined && sort != null) {
            formData.append("sort", JSON.stringify(sort));
        }

        if (this.state.locationFilter !== undefined && this.state.locationFilter !== "") {
            formData.append("keyword", this.state.locationFilter);
        }

        var context = this;

        Axios.post(ENDPOINTS.location.getLocations, formData)
            .then(function(response) {
                let resp = API.parseResponse(response);
                if (resp.success) {
                    context.setState({
                        fetchingTableData : false,
                        tableData : resp.data.data,
                        tableTotal : resp.data.count
                    });
                } else {
                    context.setState({
                        fetchingTableData : false
                    });
                    context.showModal("Error", resp.error);
                }
            })
            .catch(function(err) {
                console.log(err);
                context.setState({
                    fetchingTableData : false
                });
                context.showModal("Error", "An unknown error occurred. [101]");
            });
    }

    tableDidSort = (sort) => {
        this.setState({
            sortedColumn : sort
        });

        console.log("SORT CLICKED!");
        console.log(sort);

        this.getTableDataFromNetwork(undefined, sort);
    }

    filterDidChange = (event) => {
        this.handleChange(event);

        clearTimeout(this.filterKeywordTimeout);
        this.filterKeywordTimeout = setTimeout(() => {
            this.getTableDataFromNetwork(1, undefined);
        }, 800);
    }

    render() {
        var alertElem = [];

        if (this.state.showDeleteConfirmation) {
            alertElem.push(
                <div className="alert alert-success" role="alert">Location was successfully deleted.</div>
            );
        }

        let reportButton = [];
        if (this.state.user.merchantUserTypeId === API.USER_TYPES.ADMINISTRATOR) {
            reportButton = (
                <button className={"btn btn-info"} onClick={this.moveToDealReportScreen}>Deal Report</button>
            )
        }

        return(
            <div className={"container"}>
                <HeaderComponent />

                <div className={"page-header"}>
                    <div className={"page-title"}>Locations</div>
                    <div className={"page-actions"}>
                        <RemainingCountComponent
                            total={ this.state.locationsTotalCount - this.state.locationsAvailableCount }
                            suffix={"Locations"} />

                        {reportButton}

                        <button className={"btn btn-success"} disabled={this.state.locationsAvailableCount <= 0} onClick={() => this.moveToLocationEditor("new")}>Add a new Location</button>
                    </div>
                </div>

                {alertElem}

                <div className={"row form-block filter-area"}>
                    <div className={"col-12"}>
                        <label htmlFor={"locationFilter"}>Search</label>
                        <input type={"text"} className={"form-control"} name={"locationFilter"} value={this.state.locationFilter} onChange={this.filterDidChange} placeholder={"Search for Locations"} />
                    </div>
                </div>

                <div className={"row form-block"}>
                    <div className={"col-12"}>
                        <TableComponent
                            className={"alternating clickable"}
                            data={this.state.tableData}
                            onRowClick={(row) => { this.tableRowWasClicked(row);}}
                            onSort={(sort) => { this.tableDidSort(sort) }}
                            sortedColumn={this.state.sortedColumn}>
                            <Column name={"name"} title={"Location Name"} sortable={true} />
                            <Column name={"emailAddress"} title={"Location Email"} sortable={true} />
                            <Column name={"addressName"} title={"Address Name"} sortable={true} />
                            <Column name={"addressPostcode"} title={"Address Postcode"} sortable={true} />
                        </TableComponent>

                        <div className={"form-block ep-table-paginate-container"}>
                            <div className={"push"} />
                            <TablePaginator
                                page={this.state.tablePage}
                                totalCount={this.state.tableTotal}
                                pageSize={this.state.tableLimit}
                                onClick={(page) => this.tablePageDidChange(page)} />
                        </div>

                    </div>
                </div>
            </div>
        );
    }

}

export default LocationListScreenComponent;