import React from "react";
import StatefulComponent from "../StatefulComponent";
import HeaderComponent from "../common/HeaderComponent";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import AlertModal from "../common/AlertModal";

import '../../assets/css/location-editor-screen.css';
import Axios from "axios";
import {API, ENDPOINTS} from "../../networking/API";
import GoogleMapReact from "google-map-react";
import SliderComponent from "../common/SliderComponent";
import Marker from "../common/Marker";

class LocationEditorScreenComponent extends StatefulComponent {

    constructor(props, context) {
        super(props, context);

        this.handleScreenRestriction([API.USER_TYPES.ADMINISTRATOR]);

        var locationSubmissionId = localStorage.getItem("locationSubmissionId");
        localStorage.removeItem("locationSubmissionId");

        this.initState({
            location : null,
            locationSubmissionId : locationSubmissionId,
            defaultLocImagePath : ""
        });

        this.locationImageInput = React.createRef();

        this.getLocationFromNetwork = this.getLocationFromNetwork.bind(this);
        this.handleLocationChange = this.handleLocationChange.bind(this);
        this.imageSelectionDidChange = this.imageSelectionDidChange.bind(this);
        this.formWasSubmitted = this.formWasSubmitted.bind(this);
        this.uploadImageIfRequired = this.uploadImageIfRequired.bind(this);
        this.finishSubmission = this.finishSubmission.bind(this);
        this.deleteLocationOverNetwork = this.deleteLocationOverNetwork.bind(this);

        this.mapSliderDidChange = this.mapSliderDidChange.bind(this);
        this.googleMapDidLoad = this.googleMapDidLoad.bind(this);
        this.googleMapWasClicked = this.googleMapWasClicked.bind(this);
        this.mapAdvancedWasClicked = this.mapAdvancedWasClicked.bind(this);
        this.getMapMarkers = this.getMapMarkers.bind(this);
        this.applyMapCircle = this.applyMapCircle.bind(this);
    }

    componentDidMount() {
        if (this.props.match.params.id != null && this.props.match.params.id !== "new") {
            this.getLocationFromNetwork(this.props.match.params.id);
        }
        this.getMerchantFromNetwork();
    }

    getLocationFromNetwork(id) {
        let formData = new FormData();
        formData.append("id", id);

        var context = this;

        Axios.post(ENDPOINTS.location.getLocation, formData)
            .then((response) => {
                let resp = API.parseResponse(response);
                if (resp.success) {
                    context.handleLocationChange(resp.data);
                } else {
                    context.showModal("Error", resp.error, [
                        {
                            label : "Go back",
                            click : function() {
                                window.location.href = "/locations/";
                            }
                        }
                    ]);
                }
            })
            .catch((err) => {
                context.showModal("Error", "An unknown error has occurred. [102]");
            });
    }

    getMerchantFromNetwork() {
        let context = this;
        Axios.post(ENDPOINTS.merchant.get)
            .then(function(resp) {
                if (resp !== null) {

                    if (resp.data.success) {
                        if (resp.data.data !== null) {

                            if (resp.data.data.defaultLocImagePath !== null) {
                                context.handleDefaultImageChange(resp.data.data.defaultLocImagePath);
                            }
                        }
                    }
                }
            })
            .catch(function(err) {
                console.log(err);
            });
    }

    handleDefaultImageChange(location) {
        this.setState({
            defaultLocImagePath : location,
        });
    }

    handleLocationChange(location) {
        this.setState({
            location : location,
            locationName : location.name,
            locationLatitude : location.latitude,
            locationLongitude : location.longitude,
            locationRadius : location.radiusMetres,
            locationAddressName : location.addressName,
            locationAddressLine1 : location.addressLine1,
            locationAddressLine2 : location.addressLine2,
            locationAddressCity : location.addressCity,
            locationAddressCounty : location.addressCounty,
            locationAddressPostcode : location.addressPostcode,
            locationPhoneNumber : location.phoneNumber,
            locationEmailAddress : location.emailAddress,
            locationWebsite : location.website,
            imagePreviewUri : location.imageUri
        });
    }

    imageSelectionDidChange() {
        if (this.locationImageInput.current.files.length > 0) {
            this.locationImageUpload = this.locationImageInput.current.files[0];

            var context = this;

            var reader = new FileReader();
            reader.onload = function() {
                context.setState({
                    imagePreviewUri : reader.result
                });
            };
            reader.readAsDataURL(this.locationImageUpload);
        }
    }

    formWasSubmitted() {
        if (this.state.isUploading) return;

        var validationResult = this.validateCreateFormData(this.state, [
                {
                    key : "locationName",
                    label : "Location Name",
                    postName : "name"
                },
                {
                    key : "locationAddressName",
                    label : "Address Name",
                    postName : "addressName"
                },
                {
                    key : "locationAddressLine1",
                    label : "Address Line 1",
                    postName : "addressLine1",
                    optional : true
                },
                {
                    key : "locationAddressLine2",
                    label : "Address Line 2",
                    postName : "addressLine2",
                    optional : true
                },
                {
                    key : "locationAddressCity",
                    label : "Town/City",
                    postName : "addressCity",
                    optional : true
                },
                {
                    key : "locationAddressCounty",
                    label : "County",
                    postName : "addressCounty",
                    optional : true
                },
                {
                    key : "locationAddressPostcode",
                    label : "Postcode",
                    postName : "addressPostcode"
                },
                {
                    key : "locationPhoneNumber",
                    label : "Phone Number",
                    postName : "phoneNumber"
                },
                {
                    key : "locationEmailAddress",
                    label : "Email Address",
                    postName : "emailAddress",
                    optional : true
                },
                {
                    key : "locationWebsite",
                    label : "Website",
                    postName : "website",
                    optional : true
                },
                {
                    key : "locationLatitude",
                    label : "Location Latitude",
                    postName : "latitude"
                },
                {
                    key : "locationLongitude",
                    label : "Location Longitude",
                    postName : "longitude"
                },
                {
                    key : "locationRadius",
                    label : "Location Radius Metres",
                    postName : "radiusMetres"
                },
            ]);

        if (validationResult.success) {
            this.setState({
                isUploading : true
            });

            var formData = validationResult.formData;
            if (this.state.location != null) {
                formData.append("id", this.state.location.id);
            }

            var context = this;

            Axios.post(ENDPOINTS.location.submitLocation, formData)
                .then((response) => {
                    let resp = API.parseResponse(response);
                    if (resp.success) {
                        context.uploadImageIfRequired(resp.data.id);
                    } else {
                        context.showModal("Error", resp.error);

                        context.setState({
                            isUploading : false
                        });
                    }
                })
                .catch((err) => {
                    context.showModal("Error", "An unknown error has occurred. [104]");

                    context.setState({
                        isUploading : false
                    });
                });
        } else {
            this.showModal("Error", validationResult.error);
        }
    }

    uploadImageIfRequired(locationId) {
        if (this.locationImageUpload != null) {
            var formData = new FormData();
            formData.append("merchantLocationId", locationId);
            formData.append("image", this.locationImageUpload);

            var context = this;

            Axios.post(ENDPOINTS.location.uploadImage, formData, {
                onUploadProgress : (data) => {
                    if (data.lengthComputable) {
                        var percent = ((data.loaded / data.total) * 100);

                        console.log("UPLOAD: " + percent + "%");
                        context.setState({
                            uploadProgressPercent : percent
                        });
                    }
                }
            })
                .then((response) => {
                    let resp = API.parseResponse(response);
                    if (resp.success) {
                        context.finishSubmission(locationId);
                    } else {
                        context.showModal("Error", resp.error);
                        context.setState({
                            isUploading : false
                        });
                    }
                })
                .catch((err) => {
                   context.showModal("Error", "An unknown error has occurred. [101]");
                    context.setState({
                        isUploading : false
                    });
                });
        } else {
            this.finishSubmission(locationId);
        }
    }

    finishSubmission(locationId) {
        this.setState({
            uploadProgressPercent : 100
        });

        localStorage.setItem("locationSubmissionId", locationId);

        window.location.href = "/locations/" + locationId;
    }

    deleteLocationOverNetwork(event, confirmed) {
        if (this.state.location == null) return;

        console.log("SHOW DELETE MODAL");
        console.log(confirmed);

        if (confirmed === undefined || !confirmed) {
            this.showModal("Please confirm", "Are you sure you want to delete this Location? This action cannot be undone.", [
                {
                    label : "Delete Location",
                    style : "btn-danger",
                    click : () => {
                        this.deleteLocationOverNetwork(event, true);
                    }
                },
                {
                    label : "Cancel",
                    style : "btn-success",
                    click : () => {
                        this.hideModal();
                    }
                }
            ]);
        } else {
            let formData = new FormData();
            formData.append("id", this.state.location.id);

            Axios.post(ENDPOINTS.location.deleteLocation, formData)
                .then((r) => {
                    let resp = API.parseResponse(r);
                    if (resp.success) {
                        localStorage.setItem("locationDidDelete", this.state.location.id);

                        window.location.href = "/locations";
                    } else {
                        this.showModal("Error", resp.error);
                    }
                })
                .catch((e) => {
                    console.log(e);
                    this.showModal("Error", "An unknown error has occurred. [109]")
                });
        }
    }

    mapSliderDidChange(value) {
        this.setState({
            locationRadius : value
        });
    }

    mapAdvancedWasClicked() {
        var advancedShown = false;
        if (this.state.locationShowAdvanced != null) {
            advancedShown = this.state.locationShowAdvanced;
        }

        this.setState({
            locationShowAdvanced : !advancedShown
        });
    }

    googleMapDidLoad(map, maps) {
        this.googleMap = map;
        this.googleMaps = maps;

        this.moveMapToPosition();
        this.applyMapCircle();
    }

    googleMapWasClicked(data) {
        this.setState({
            locationLatitude : data.lat,
            locationLongitude : data.lng
        });
    }

    getMapMarkers() {
        var markers = [];

        if (this.state.locationLatitude != null && this.state.locationLongitude != null) {
            markers.push(<Marker
                key={1}
                lat={this.state.locationLatitude}
                lng={this.state.locationLongitude} />);
        }

        // TODO Show Location Markers?

        this.applyMapCircle();

        return markers;
    }

    moveMapToPosition() {
        if (this.state.locationLatitude != null && this.state.locationLongitude != null) {
            this.googleMap.setCenter({lat:this.state.locationLatitude, lng:this.state.locationLongitude});
        }
    }

    applyMapCircle() {
        if (this.googleMaps == null) {
            console.log("Called applyMapCircle but googleMaps was NULL");
            return;
        }

        if (this.state.locationRadius == null
            || this.state.locationLatitude == null
            || this.state.locationLongitude == null) {
            return;
        }

        var latLng = {
            lat : this.state.locationLatitude,
            lng : this.state.locationLongitude
        };

        var radiusMetres = this.state.locationRadius;

        if (this.mapCircle != null) {
            this.mapCircle.setMap(null);
        }

        this.mapCircle = new this.googleMaps.Circle({
            strokeColor : "#CC0000",
            strokeOpacity : 0.8,
            strokeWeight : 2,
            fillColor : "#CC0000",
            fillOpacity : 0.3,
            map : this.googleMap,
            center : latLng,
            radius : radiusMetres
        });
    }

    lookupAddressOverNetwork = () => {
        if (this.state.addressLookupNetworkInFlight) return;
        this.setState({
            addressLookupNetworkInFlight : true
        });

        let formData = new FormData();
        formData.append("query", this.state.dealLookupAddress);

        Axios.post(ENDPOINTS.deal.searchAddress, formData)
            .then((r) => {
                let inlineError = null;

                let resp = API.parseResponse(r);
                if (resp.success) {
                    // TODO set location lat/lng, move Google Maps camera
                    if (resp.data.length > 0) {
                        this.setState({
                            locationLatitude: resp.data[0].lat,
                            locationLongitude : resp.data[0].lng
                        });

                        this.googleMap.setZoom(12);
                    } else {
                        // TODO Show feedback that the address couldn't be found
                        inlineError = "No results for that address. Please try another address.";
                    }
                } else {
                    this.showModal("Error", "An error occurred when trying to lookup address: " + resp.error, [
                        {
                            label : "OK",
                            click : () => {
                                this.hideModal();
                            }
                        }
                    ]);
                }

                this.setState({
                    addressLookupNetworkInFlight : false,
                    addressLookupError : inlineError
                });
            })
            .catch((e) => {
                console.log(e);
                this.showModal("Error", "An unknown error has occurred. Please reload the page.", [
                    {
                        label : "OK",
                        click : () => {
                            this.hideModal();
                        }
                    }
                ]);

                this.setState({
                    addressLookupNetworkInFlight : false
                });
            });
    }

    render() {
        var messageElem = [];
        var deleteButton = [];

        if (this.state.location != null) {
            if (this.state.locationSubmissionId != null) {
                if (this.state.locationSubmissionId == this.state.location.id) {
                    messageElem.push(
                        <div className="alert alert-success" role="alert">Changes have been saved.</div>
                    );
                }
            }

            deleteButton.push(
                <button className={"btn btn-danger"} onClick={this.deleteLocationOverNetwork}>Delete Location</button>
            );
        }

        let defaultCamera = {
            lat : (this.state.locationLatitude != null) ? this.state.locationLatitude : 53.4134046,
            lng : (this.state.locationLongitude != null) ? this.state.locationLongitude : -1.8996704
        };
        let cameraCentre = null;
        if (this.state.locationLatitude != null) {
            defaultCamera.lat = this.state.locationLatitude;
            defaultCamera.lng = this.state.locationLongitude;

            cameraCentre = {
                lat: this.state.locationLatitude,
                lng: this.state.locationLongitude
            };
        }

        let addressLookupPending = [];
        if (this.state.addressLookupNetworkInFlight) {
            addressLookupPending = (
                <div className={"pending"}>Please wait, searching for address...</div>
            )
        }

        let addressLookupInlineError = [];
        if (this.state.addressLookupError != null) {
            addressLookupInlineError = (
                <div className={"error"}>{this.state.addressLookupError}</div>
            );
        }

        return (
            <div className={"container"}>
                <HeaderComponent />

                <div className={"page-header"}>
                    <div className={"page-title"}>
                        <span className={"btn btn-outline-primary"} onClick={() => window.location.href = "/locations/"}>
                            <FontAwesomeIcon icon={"chevron-left"} />
                        </span>
                        { (this.state.location != null) ? "Edit Location" : "New Location" }
                    </div>
                    <div className={"page-actions"}>
                        {deleteButton}
                    </div>
                </div>

                {messageElem}

                <div className={"section-container location-editor-screen"}>
                    <div className={"section-content"}>
                        <div className={"row"}>
                            <div className={"col-12"}>
                                <label>Location Name</label>
                                <input type={"text"} className={"form-control"} name={"locationName"} value={this.state.locationName} onChange={this.handleChange} />
                            </div>
                        </div>

                        <div className={"row form-block"}>
                            <div className={"col-12"}>
                                <label className={"no-margin"}>Location Header Image</label>
                                <div className={"image-selection recess-area form-block"}>
                                    <div className={"row"}>
                                        <div className={"col-12 col-md-4 image-container"}>
                                            <div className={"image-preview"} ref={this.imagePreview} style={{ backgroundImage : (this.state.imagePreviewUri != null) ? "url(" + this.state.imagePreviewUri + ")" : "url(" + this.state.defaultLocImagePath + ")"  }} />
                                        </div>
                                        <div className={"col-12 col-md-8 text-center"}>
                                            <div className={"image-filename"}>{ (this.state.uploadFile != null) ? this.state.uploadFile.name : "No image selected" }</div>
                                            <div className={"form-block"}>
                                                <div className={"input-hide"}>
                                                    <input type={"file"} ref={this.locationImageInput} onChange={ this.imageSelectionDidChange } />
                                                </div>
                                                <span className={"btn btn-primary"} onClick={() => this.locationImageInput.current.click()}>Select Image</span>
                                            </div>
                                            <div className={"form-block text-small"}>
                                                <p>Please note the following when selecting your deal image:</p>
                                                <ul className={"text-left"}>
                                                    <li>Maximum allowed file size is 2 megabytes.</li>
                                                    <li>Please avoid including text in your image, it may not be legible in certain situations</li>
                                                    <li>Please consider using using a lower quality setting when mastering images to keep file sizes lower.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={"row form-block"}>
                            <div className={"col-12"}>
                                <label>Deal Location</label>
                            </div>
                            <div className={"col-12"}>
                                <div className={"map-selection-container"}>
                                    <div className={"row"}>
                                        <div className={"col-12"}>
                                            <div className={"map"}>
                                                <GoogleMapReact
                                                    bootstrapURLKeys={{ key : "AIzaSyASR6vqBuN-lvHwVF2oy3o8JutLDGk0hfI" }}
                                                    defaultCenter={defaultCamera}
                                                    center={cameraCentre}
                                                    defaultZoom={(this.state.locationLatitude != null) ? 17 : 5 }
                                                    onClick={this.googleMapWasClicked}
                                                    onGoogleApiLoaded={({map, maps}) => this.googleMapDidLoad(map, maps)}
                                                    yesIWantToUseGoogleMapApiInternals>

                                                    { this.getMapMarkers() }

                                                </GoogleMapReact>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={"map-controls"}>
                                        <div className={"row"}>
                                            <div className={"hidden-xs col-md-2"} />
                                            <div className={"col-12 col-md-8"}>
                                                <label className={"no-margin"}>Address Lookup</label>
                                                <div className={"input-group"}>
                                                    <input type={"text"} className={"form-control"} name={"dealLookupAddress"} value={this.state.dealLookupAddress} onChange={this.handleChange} hint={"Type a location. Only places within the UK."} />
                                                    <div className={"input-group-append"}>
                                                        <span className={"input-group-text lookup-append-button"} onClick={this.lookupAddressOverNetwork}>Lookup</span>
                                                    </div>
                                                </div>
                                                {addressLookupPending}
                                                {addressLookupInlineError}
                                            </div>
                                        </div>
                                        <div className={"row"}>
                                            <div className={"col-12"}>
                                                <label className={"no-margin"}>Notification Radius</label>
                                                <div className={"small-label"}>When users opt-in to Location features within Gas App, they will receive Notifications when they enter the radius (in metres) defined here. (Maximum 3000 metres)</div>
                                            </div>
                                            <div className={"col-12 col-md-6 col-lg-3"}>
                                                <div className={"input-group"}>
                                                    <input type={"number"} className={"form-control"} name={"locationRadius"} value={(this.state.locationRadius) ? this.state.locationRadius : "" } placeholder={"3000"} onChange={this.handleChange} />
                                                    <span className={"input-group-append"}>
                                                    <span className={"input-group-text"}>metres</span>
                                                </span>
                                                </div>

                                            </div>
                                            <div className={"col-12 col-md-6 col-lg-9"}>
                                                <SliderComponent
                                                    max={3000}
                                                    min={30}
                                                    value={(this.state.locationRadius)}
                                                    onChange={(value) => this.mapSliderDidChange(value)} />
                                            </div>
                                        </div>
                                        <div className={"row"} style={(this.state.locationShowAdvanced) ? { display : "flex" } : { display : "none" }}>
                                            <div className={"col-12 col-md-6"}>
                                                <label>Latitude</label>
                                                <input type={"text"} name={"locationLatitude"} className={"form-control"} value={this.state.locationLatitude} placeholder={"Latitude"} onChange={this.handleChange} />
                                            </div>
                                            <div className={"col-12 col-md-6"}>
                                                <label>Longitude</label>
                                                <input type={"text"} name={"locationLongitude"} className={"form-control"} value={this.state.locationLongitude} placeholder={"Longitude"} onChange={this.handleChange} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Address Lines */}
                        <div className={"row form-block"}>
                            <div className={"col-12 col-md-6"}>
                                <div className={"form-block"}>
                                    <label>Business Name</label>
                                    <input type={"text"} className={"form-control"} name={"locationAddressName"} value={this.state.locationAddressName} onChange={this.handleChange} />
                                </div>

                                <div className={"form-block"}>
                                    <label>Address Line 1</label>
                                    <input type={"text"} className={"form-control"} name={"locationAddressLine1"} value={this.state.locationAddressLine1} onChange={this.handleChange} />
                                </div>

                                <div className={"form-block"}>
                                    <label>Address Line 2</label>
                                    <input type={"text"} className={"form-control"} name={"locationAddressLine2"} value={this.state.locationAddressLine2} onChange={this.handleChange} />
                                </div>

                                <div className={"form-block"}>
                                    <label>Town/City</label>
                                    <input type={"text"} className={"form-control"} name={"locationAddressCity"} value={this.state.locationAddressCity} onChange={this.handleChange} />
                                </div>

                                <div className={"form-block"}>
                                    <label>County</label>
                                    <input type={"text"} className={"form-control"} name={"locationAddressCounty"} value={this.state.locationAddressCounty} onChange={this.handleChange} />
                                </div>

                                <div className={"form-block"}>
                                    <label>Post Code</label>
                                    <input type={"text"} className={"form-control"} name={"locationAddressPostcode"} value={this.state.locationAddressPostcode} onChange={this.handleChange} />
                                </div>
                            </div>

                            {/* Contact Details */}
                            <div className={"col-12 col-md-6"}>
                                <div className={"form-block"}>
                                    <label>Phone Number</label>
                                    <input type={"text"} className={"form-control"} name={"locationPhoneNumber"} value={this.state.locationPhoneNumber} onChange={this.handleChange} />
                                </div>

                                <div className={"form-block"}>
                                    <label>Email Address <sup>(Optional)</sup></label>
                                    <input type={"text"} className={"form-control"} name={"locationEmailAddress"} value={this.state.locationEmailAddress} onChange={this.handleChange} />
                                </div>

                                <div className={"form-block"}>
                                    <label>Website <sup>(Optional)</sup></label>
                                    <input type={"text"} className={"form-control"} name={"locationWebsite"} value={this.state.locationWebsite} onChange={this.handleChange} />
                                </div>
                            </div>
                        </div>

                        <div className={"row form-block"}>
                            <div className={"col-12 text-center"}>
                                <div className={"submission-buttons"} style={{ display : (!this.state.isUploading) ? "block" : "none" }}>
                                    <button className={"btn btn-success"} onClick={this.formWasSubmitted}>{ (this.state.location == null) ? "Create Location" : "Save Changes"}</button>
                                </div>
                                <div className={"upload-progress-area"} style={{ display : (this.state.isUploading) ? "block" : "none" }}>
                                    <span className={"upload-notice-label"}>Saving, please wait...</span>
                                    <div className={"progress"}>
                                        <div className={"progress-bar progress-bar-striped progress-bar-animated"} style={{ width : this.state.uploadProgressPercent + "%" }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <AlertModal
                    open={ (this.state.modal != null) ? this.state.modal.open : false }
                    title={ (this.state.modal != null) ? this.state.modal.title : "" }
                    content={ (this.state.modal != null) ? this.state.modal.content : [] }
                    buttons={ (this.state.modal != null) ? this.state.modal.buttons : [] }
                    dismissHandler={ () => this.modalWasDismissed() }
                />
            </div>
        );
    }

}

export default LocationEditorScreenComponent;