import React from "react";
import StatefulComponent from "../StatefulComponent";
import HeaderComponent from "../common/HeaderComponent";
import GoogleMapReact from 'google-map-react';
import SliderComponent from "../common/SliderComponent";
import Marker from "../common/Marker";
import {API, ENDPOINTS} from "../../networking/API";
import Axios from "axios";
import moment from "moment";
import DatePicker from "react-datepicker/es";
import AlertModal from "../common/AlertModal";
import DealInfoComponent from "./DealInfoComponent";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import "react-datepicker/dist/react-datepicker.css";
import "../../assets/css/deal-editor-screen.css";
import TableComponent, {Column, TablePaginator} from "../common/TableComponent";

const startOfMonth = moment().startOf('month').toDate();

class DealEditorScreenComponent extends StatefulComponent {

    endOfMonth = null;
    isRenewal = false;

    user = undefined;

    locationFilterTimeout = undefined;

    constructor(props, context) {
        super(props, context);

        console.log(this.props.user);

        if (this.props.user !== undefined) {
            if (this.props.user != null) {
                // Enforce end of subscription only for Independent Merchant Types
                if (this.props.user.merchantTypeId == API.MERCHANT_TYPES.INDEPENDENT) {
                    if (this.props.user.hasOwnProperty("subscriptionEnd")) {
                        // Set the final time to the subscription end time
                        this.endOfMonth = moment(this.props.user.subscriptionEnd, "X").toDate();
                    }
                }
            }
        }

        if (this.props.renewal !== undefined) {
            this.isRenewal = this.props.renewal;
        }

        console.log("END OF MONTH: ");
        console.log(this.endOfMonth);

        if (this.props.match.params.id == null) {
            console.log("Did not provide ID. Shouldn't have routed here.");
            window.location.href = "/deals/";
            return;
        }

        this.user = JSON.parse(localStorage.getItem("user"));
        var discl = localStorage.getItem("disclaimer");

        this.initState({
            dealId : this.props.match.params.id,
            locations : [],
            selectedLocationIds : [],
            tablePage : 1,
            tableTotal : 0,
            tableData : [],
            tableLimit : 25, // 25 images per page,
            sortedColumn : {name : "uploadDate", direction: "desc"},
            imageFilter : "",
            dealLocationRadius : 3000,
            disclaimer : discl
        });

        this.dealImageInput = React.createRef();

        this.imageSelectionDidChange = this.imageSelectionDidChange.bind(this);

        this.mapSliderDidChange = this.mapSliderDidChange.bind(this);
        this.googleMapDidLoad = this.googleMapDidLoad.bind(this);
        this.googleMapWasClicked = this.googleMapWasClicked.bind(this);
        this.mapAdvancedWasClicked = this.mapAdvancedWasClicked.bind(this);
        this.getMapMarkers = this.getMapMarkers.bind(this);
        this.applyMapCircle = this.applyMapCircle.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);

        this.fetchDeal = this.fetchDeal.bind(this);
        this.submitDeal = this.submitDeal.bind(this);
        this.uploadDealImage = this.uploadDealImage.bind(this);

        this.getLocationsFromNetwork = this.getLocationsFromNetwork.bind(this);
        this.handleLocationSelection = this.handleLocationSelection.bind(this);

        this.lookupAddressOverNetwork = this.lookupAddressOverNetwork.bind(this);
        this.moveMapToPosition = this.moveMapToPosition.bind(this);

        this.renewDeal = this.renewDeal.bind(this);
        this.filterLocations = this.filterLocations.bind(this);
        this.clearLocationFilter = this.clearLocationFilter.bind(this);
        this.fetchUserBranches = this.fetchUserBranches.bind(this);
        this.applyUserBranches = this.applyUserBranches.bind(this);

    }

    componentDidMount() {
        super.componentDidMount();

        this.getPartners();
        this.fetchDeal();
        this.getLocationsFromNetwork();
        this.getTableDataFromNetwork();
    }

    componentWillUnmount() {
        super.componentWillUnmount();

        clearTimeout(this.locationFilterTimeout);
    }

    dismissClicked() {
        var context = this;
        this.setState({"modalSiteList":undefined})
    }

    selectedListWasClicked(sitelist) {
        var context = this;
        this.setState({"modalSiteList":sitelist})
    }

    getPartners() {
        var formData = new FormData();

        Axios.post(ENDPOINTS.deal.getPartners, formData)
            .then((response) => {
                if (response.data.success) {

                    let partners = response.data.partners.map((row, c) => {

                        return <option key={c} value={row.id}>{row.name}</option>

                    })

                    this.setState({
                        partners: partners
                    })
                } else {
                    console.log(response.data.error);
                    this.showModal("Error", response.data.error);
                }
            })
            .catch(error => {
                console.log("ERROR");
                console.log(error);

                this.showModal("Error", "Could not fetch users at this time. Please try again later. [101]")
            });
    }


    fetchUserBranches() {
        var context = this;
        var formData = new FormData();

        Axios.post(ENDPOINTS.user.getBranches, formData)
            .then(function(response) {
                var resp = API.parseResponse(response);
                if (resp.success) {
                    context.applyUserBranches(resp.data)
                } else {
                    console.log(resp.error);
                }
            })
            .catch(function(err) {
                console.log(err);
            });

    }


    applyUserBranches(branches) {

        if (branches && branches.length > 0) {

            for (let c = 0; c < this.state.locations.length; c++) {
                let loc = this.state.locations[c]
                if (loc.id == branches[0]) {

                    this.setState({
                        dealLocationLatitude: loc.latitude,
                        dealLocationLongitude : loc.longitude
                    });
                    break
                }
            }
            this.setState({
                selectedLocationIds : branches
            });
        }


    }

    imageSelectionDidChange(event) {
        if (event.target.files.length > 0) {
            var uploadImage = event.target.files[0];

            this.setState({
                uploadImage : uploadImage,
                selectedImage : undefined
            });

            var context = this;

            var reader = new FileReader();
            reader.onload = function() {
                context.setState({
                    imagePreviewUri : reader.result
                });
            };
            reader.readAsDataURL(uploadImage);
        }
    }

    mapSliderDidChange(value) {
        this.setState({
            dealLocationRadius : value
        });
    }

    mapAdvancedWasClicked() {
        var advancedShown = false;
        if (this.state.dealLocationShowAdvanced != null) {
            advancedShown = this.state.dealLocationShowAdvanced;
        }

        this.setState({
            dealLocationShowAdvanced : !advancedShown
        });
    }

    googleMapDidLoad(map, maps) {
        this.googleMap = map;
        this.googleMaps = maps;

        this.moveMapToPosition();
        this.applyMapCircle();
    }

    googleMapWasClicked(data) {
        this.setState({
            dealLocationLatitude : data.lat,
            dealLocationLongitude : data.lng
        });
    }

    getMapMarkers() {
        var markers = [];

        if (this.state.dealLocationLatitude != null && this.state.dealLocationLongitude != null) {
            markers.push(<Marker
                key={1}
                lat={this.state.dealLocationLatitude}
                lng={this.state.dealLocationLongitude} />);
        }

        // TODO Show Location Markers?

        this.applyMapCircle();

        return markers;
    }

    moveMapToPosition() {
        if (this.state.dealLocationLatitude != null && this.state.dealLocationLongitude != null) {
            this.googleMap.setCenter({lat:this.state.dealLocationLatitude, lng:this.state.dealLocationLongitude});
        }
    }

    applyMapCircle() {
        if (this.googleMaps == null) {
            console.log("Called applyMapCircle but googleMaps was NULL");
            return;
        }

        if (this.state.dealLocationRadius == null
        || this.state.dealLocationLatitude == null
        || this.state.dealLocationLongitude == null) {
            return;
        }

        var latLng = {
            lat : this.state.dealLocationLatitude,
            lng : this.state.dealLocationLongitude
        };

        var radiusMetres = this.state.dealLocationRadius;

        if (this.mapCircle != null) {
            this.mapCircle.setMap(null);
        }

        this.mapCircle = new this.googleMaps.Circle({
            strokeColor : "#CC0000",
            strokeOpacity : 0.8,
            strokeWeight : 2,
            fillColor : "#CC0000",
            fillOpacity : 0.3,
            map : this.googleMap,
            center : latLng,
            radius : radiusMetres
        });
    }



    tablePageDidChange(page) {
        this.setState({
            tablePage : page
        });

        this.getTableDataFromNetwork(page);
    }


    getTableDataFromNetwork(page, sort) {
        if (this.state.fetchingTableData) return;
        this.setState({
            fetchingTableData : true
        });

        if (page === undefined) {
            page = this.state.tablePage;
        }

        if (sort === undefined) {
            sort = this.state.sortedColumn;
        }

        let formData = new FormData();
        formData.append("page", page);
        formData.append("limit", this.state.tableLimit);

        if (sort !== undefined) {
            formData.append("sort", JSON.stringify(sort));
        }

        if (this.state.imageFilter !== undefined && this.state.imageFilter !== "") {
            formData.append("keyword", this.state.imageFilter);
        }

        var context = this;

        Axios.post(ENDPOINTS.images.getList, formData)
            .then(function(response) {
                let resp = API.parseResponse(response);
                if (resp.success) {

                    let mappedTableData = resp.data.data.map((row, c) => {


                        let img =   <div className={"image-preview"} style={{ backgroundImage : (row.publicUrl != null) ? "url(" + row.publicUrl + ")" : "" , border : "none", paddingTop : "51%"}} />
                        row.img = img
                        return row

                    })

                    context.setState({
                        fetchingTableData : false,
                        tableData : mappedTableData,
                        tableTotal : resp.data.total
                    });
                } else {
                    context.setState({
                        fetchingTableData : false
                    });
                    context.showModal("Error", resp.error);
                }
            })
            .catch(function(err) {
                console.log(err);
                context.setState({
                    fetchingTableData : false
                });
                context.showModal("Error", "An unknown error occurred. [1701]");
            });
    }

    tableWasSorted = (sort) => {
        this.getTableDataFromNetwork(this.state.tablePage, sort);

        this.setState({
            sortedColumn : sort
        });
    }

    filterDidChange = (event) => {
        clearTimeout(this.filterKeywordTimeout);
        this.filterKeywordTimeout = setTimeout(() => {
            this.getTableDataFromNetwork(1);
        }, 800);

        this.setState({
            tablePage : 1
        });

        this.handleChange(event);
    }


    handleDateChange(dataKey, date) {
        console.log(date);

        var state = {};
        state[dataKey] = date;

        this.setState(state);
    }

    populateDealState(deal) {
        var serverDateFormat = "YYYY-MM-DD HH:mm:ss";
        // Fri May 01 2020 00:00:00 GMT+0100 (British Summer Time)
        // Fri May 01 2020 00:00:00 GMT+0100 (British Summer Time)
        var uiDateFormat = "ddd MMM DD YYYY HH:mm:ss [GMT]Z";

        var dealStartDate = deal.startDate;
        if (dealStartDate != null) {
            dealStartDate = moment(deal.startDate, serverDateFormat).toDate();
        }

        var dealEndDate = deal.endDate;
        if (dealEndDate != null) {
            dealEndDate = moment(deal.endDate, serverDateFormat).toDate();
        }

        if (this.isRenewal) {
            dealStartDate = moment().toDate();
            dealEndDate = moment().add(1, "days").toDate();
        }

        this.setState({
            agreed: true,
            deal : deal,
            dealTitle : deal.title,
            dealShortDescription : deal.shortDescription,
            dealLongDescription : deal.longDescription,
            dealPriceHeadline : deal.priceHeadline,
            dealPriceSubtitle : deal.priceSubtitle,
            dealUrl : deal.url,
            dealUrlLabel : deal.urlLabel,
            dealLocationLatitude : deal.latitude,
            dealLocationLongitude : deal.longitude,
            dealLocationRadius : deal.radiusMetres,
            dealStartDate : dealStartDate,
            dealEndDate : dealEndDate,
            imagePreviewUri : deal.imageUrl,
            selectedLocationIds : deal.locationIds,
            dealApp : deal.app,
            dealPartner : deal.partnerID === null ? -1 : deal.partnerID
        });
    }

    fetchDeal() {
        var context = this;

        if (this.state.dealId !== "new") {
            var formData = new FormData();
            formData.append("id", this.state.dealId);

            Axios.post(ENDPOINTS.deal.get, formData)
                .then(function(response) {
                   var resp = API.parseResponse(response);
                   if (resp.success) {
                       context.populateDealState(resp.data);
                   } else {
                       console.log(resp.error);
                   }
                })
                .catch(function(err) {
                    console.log(err);
                });
        } else {

            this.setState({
                dealApp : "both",
                dealPartner : -1,
                dealUrlLabel : "More Info"
            });
        }
    }

    submitDeal() {


        if (this.state.isUploading) return;

        var validationResult = this.validateCreateFormData(this.state, [
            {
                key : "dealTitle",
                label : "Deal Title",
                postName : "title"
            },
            {
                key : "dealApp",
                label : "Deal App(s)",
                postName : "app"
            },
            {
                key : "dealPartner",
                label : "Partner",
                type : "int",
                postName : "partnerID"
            },
            {
                key : "dealShortDescription",
                label : "Short Description",
                postName : "shortDescription"
            },
            {
                key : "dealLongDescription",
                label : "Long Description",
                postName : "longDescription"
            },
            {
                key : "dealLocationLatitude",
                type : "float",
                label : "Deal Location",
                postName : "latitude"
            },
            {
                key : "dealLocationLongitude",
                type : "float",
                label : "Deal Latitude",
                postName : "longitude"
            },
            {
                key : "dealLocationRadius",
                type : "int",
                label : "Notification Radius",
                postName : "radiusMetres",
                default : 3000
            },
            {
                key : "dealPriceHeadline",
                label : "Price",
                optional : true,
                postName : "priceHeadline"
            },
            {
                key : "dealPriceSubtitle",
                label : "Price Info",
                optional : true,
                postName : "priceSubtitle"
            },
            {
                key : "dealUrl",
                label : "Call To Action URL",
                optional : true,
                postName : "url"
            },
            {
                key : "dealUrlLabel",
                label : "Call To Action Label",
                optional : true,
                postName : "urlLabel"
            }
        ]);

        if (this.state.selectedLocationIds.length === 0) {
            this.showModal("Error", "Please select at least 1 location");

            this.setState({
                isUploading : false
            });
            return;
        }

        let isExistingDeal = this.state.dealId != null && this.state.dealId !== "new" && !this.isRenewal;

        if (!isExistingDeal && (this.state.uploadImage === undefined && this.state.selectedImage === undefined)) {
            this.showModal("Error", "Please provide a Deal Image.");

            this.setState({
                isUploading : false
            });
            return;
        }
        
        if (validationResult.success) {
            var formData = validationResult.formData;

            if (isExistingDeal) {
                formData.append("id", this.state.dealId);
            }

            // If this is a renewal, provide the renewal flag and the old ID to clone data from
            if (this.isRenewal) {
                formData.append("renewal", this.isRenewal ? 1 : 0);
                formData.append("renewalOldId", this.state.dealId);
            }

            if (this.state.dealStartDate != null) {
                formData.append("startDate", moment(this.state.dealStartDate).format("X"));
            } else {
                this.showModal("Error", "Please provide a Deal Start Date");
                return;
            }

            if (this.state.dealEndDate != null) {
                formData.append("endDate", moment(this.state.dealEndDate).format("X"));
            } else {
                this.showModal("Error", "Please provide a Deal End Date");
                return;
            }
            this.setState({
                isUploading : true
            });
            formData.append("locationIds", JSON.stringify(this.state.selectedLocationIds));

            var context = this;

            Axios.post(ENDPOINTS.deal.submit, formData)
                .then(function (response) {
                    var resp = API.parseResponse(response);

                    if (resp.success) {
                        context.setState({
                            dealId : resp.data.id
                        });
                        context.uploadDealImage(resp.data.id);
                    } else {
                        console.log(resp.error);
                        context.showModal("Error", resp.error);

                        context.setState({
                            isUploading : false
                        });
                    }
                })
                .catch(function (err) {
                    console.log(err);
                    context.showModal("Error", "An unknown error has occurred. Please try again later. [109]");

                    context.setState({
                        isUploading : false
                    });
                });
        } else {
            console.log("VALIDATION ERROR: " + validationResult.error);
            this.showModal("Error", validationResult.error);

            this.setState({
                isUploading : false
            });
        }
    }

    uploadDealImage(id) {
        if (this.state.uploadImage != null) {
            var formData = new FormData();
            formData.append("massiveDealId", id);
            formData.append("image", this.state.uploadImage);

            var context = this;

            Axios.post(ENDPOINTS.deal.uploadImage, formData, {
                onUploadProgress: function (data) {
                    if (data.lengthComputable) {
                        var percent = ((data.loaded / data.total) * 100);

                        console.log("UPLOAD: " + percent + "%");
                        context.setState({
                            uploadProgressPercent: percent
                        });
                    }
                }
            })
                .then(function (response) {
                    var resp = API.parseResponse(response);
                    if (resp.success) {
                        context.submissionDidFinish(id);
                    } else {
                        context.showModal("Error", resp.error);

                        context.setState({
                            isUploading: false
                        });
                    }
                })
                .catch(function (err) {
                    console.log(err);
                    context.showModal("Error", "An unknown error has occurred. Please try again later. [110]")

                    context.setState({
                        isUploading: false
                    });
                });

        } else if (this.state.selectedImage != null) {
            var formData = new FormData();
            formData.append("massiveDealId", id);
            formData.append("imageId", this.state.selectedImage.id);

            var context = this;

            Axios.post(ENDPOINTS.deal.setLibraryImage, formData, {
                onUploadProgress: function (data) {
                    if (data.lengthComputable) {
                        var percent = ((data.loaded / data.total) * 100);

                        console.log("UPLOAD: " + percent + "%");
                        context.setState({
                            uploadProgressPercent: percent
                        });
                    }
                }
            })
                .then(function (response) {
                    var resp = API.parseResponse(response);
                    if (resp.success) {
                        context.submissionDidFinish(id);
                    } else {
                        context.showModal("Error", resp.error);

                        context.setState({
                            isUploading: false
                        });
                    }
                })
                .catch(function (err) {
                    console.log(err);
                    context.showModal("Error", "An unknown error has occurred. Please try again later. [1710]")

                    context.setState({
                        isUploading: false
                    });
                });

        } else {
            // Set progress to 100% so the user can see it has finished
            this.setState({
                uploadProgressPercent : 100
            });
            this.submissionDidFinish(id);
        }
    }

    submissionDidFinish(id) {
        console.log("Upload complete!");
        window.location.href = "/deals/" + id;
    }

    getLocationsFromNetwork(keyword) {
        var context = this;

        if (this.state.fetchingLocations) return;

        this.setState({
            fetchingLocations : true
        });

        let formData = new FormData();
        formData.append("page", 1);
        formData.append("limit", 1000);

        if (keyword !== undefined) {
            if (keyword != null && keyword !== "") {
                formData.append("keyword", keyword);
            }
        }

        Axios.post(ENDPOINTS.location.getLocations, formData)
            .then((r) => {
                let newState = {
                    fetchingLocations : false
                };

                let resp = API.parseResponse(r);
                if (resp.success) {
                    console.log("LOCATIONS:");
                    console.log(resp.data);
                    newState.locations = resp.data.data;
                } else {
                    this.setState({
                        fetchingLocations : false
                    });
                    console.log(resp.error);
                }

                this.setState(newState, context.userBranchCheck());
            })
            .catch((e) => {
                console.log(e);

                this.setState({
                    fetchingLocations : false
                });
            });
    }

    userBranchCheck()
    {
        if (this.state.dealId === "new") {
            //get the users branches so we can tick the default branches
            this.fetchUserBranches();
        }
    }

    handleLocationSelection(event) {
        let locationId = event.target.value;

        let selectedLocationIds = this.state.selectedLocationIds;

        console.log("LocationID: " + locationId + " :: Checked? " + (event.target.checked ? "YES" : "NO"));

        let defaultedDealLat = undefined
        let defaultedDealLon = undefined

        if (event.target.checked) {

            // If no deal loc set, the deal loc is now this location...
            if (!this.state.dealLocationLatitude){

                for (let c = 0; c < this.state.locations.length; c++) {
                    let loc = this.state.locations[c]
                    if (loc.id == locationId) {
                        defaultedDealLat = loc.latitude
                        defaultedDealLon = loc.longitude
                        break
                    }

                }
            }
            // Add if it doesn't exist
            let shouldAdd = true;
            for (let i = 0; i < selectedLocationIds.length; i++) {
                if (selectedLocationIds[i] == locationId) {
                    shouldAdd = false;
                    break;
                }
            }

            if (shouldAdd) {
                selectedLocationIds.push(locationId);
            }
        } else {
            let idIndex = -1;

            for (let i = 0; i < selectedLocationIds.length; i++) {
                if (selectedLocationIds[i] == locationId) {
                    idIndex = i;
                    break;
                }
            }

            if (idIndex >= 0) {
                selectedLocationIds.splice(idIndex, 1);
            }
        }

        if (defaultedDealLat && defaultedDealLon) {
             this.setState({
                selectedLocationIds : selectedLocationIds,
                dealLocationLatitude: defaultedDealLat,
                dealLocationLongitude : defaultedDealLon
            });
        } else {
            this.setState({
                selectedLocationIds : selectedLocationIds
            });
        }

    }

    lookupAddressOverNetwork() {
        if (this.state.addressLookupNetworkInFlight) return;
        this.setState({
            addressLookupNetworkInFlight : true
        });

        let formData = new FormData();
        formData.append("query", this.state.dealLookupAddress);

        Axios.post(ENDPOINTS.deal.searchAddress, formData)
            .then((r) => {
                let inlineError = null;

                let resp = API.parseResponse(r);
                if (resp.success) {
                    // TODO set location lat/lng, move Google Maps camera
                    if (resp.data.length > 0) {
                        this.setState({
                            dealLocationLatitude: resp.data[0].lat,
                            dealLocationLongitude : resp.data[0].lng
                        });

                        this.googleMap.setZoom(12);
                    } else {
                        // TODO Show feedback that the address couldn't be found
                        inlineError = "No results for that address. Please try another address.";
                    }
                } else {
                    this.showModal("Error", "An error occurred when trying to lookup address: " + resp.error, [
                        {
                            label : "OK",
                            click : () => {
                                this.hideModal();
                            }
                        }
                    ]);
                }

                this.setState({
                    addressLookupNetworkInFlight : false,
                    addressLookupError : inlineError
                });
            })
            .catch((e) => {
                console.log(e);
                this.showModal("Error", "An unknown error has occurred. Please reload the page.", [
                    {
                        label : "OK",
                        click : () => {
                            this.hideModal();
                        }
                    }
                ]);

                this.setState({
                    addressLookupNetworkInFlight : false
                });
            });
    }

    renewDeal() {
        window.location.href = "/deal-renew/" + this.state.dealId;
    }

    handleChange(event) {

        if (event.target.name === "dealLongDescription") {
            const target = event.target;
            const value = target.value;
            const name = "dealShortDescription";

            this.setState({
                [name] : value
            });
            super.handleChange(event)

        } else {
            super.handleChange(event)
        }

    }
    filterLocations(e) {
        this.handleChange(e);

        let target = e.target;
        let keyword = target.value;

        clearTimeout(this.locationFilterTimeout);
        this.locationFilterTimeout = setTimeout(() => {
            this.getLocationsFromNetwork(keyword);
        }, 700);
    }

    clearLocationFilter() {
        this.setState({
            "locationsFilter" : ""
        });
        this.getLocationsFromNetwork();
    }

    sevenDayShortcut = () => {

        let today = moment().startOf('day').toDate();

        let weekLater  = moment().add(6,'days').endOf("day").toDate();
       // var dealStartDate = moment(deal.startDate, serverDateFormat).toDate();

        this.setState({
            dealStartDate: today,
            dealEndDate: weekLater
        })
    }
    testCallToActionWasClicked = () => {
        if (this.state.dealUrl !== undefined && this.state.dealUrl !== "") {
            let dealUrl = this.state.dealUrl;

            if (!dealUrl.includes("http://") && !dealUrl.includes("https://")) {
                dealUrl = "http://" + dealUrl;
            }

            window.open(dealUrl);
        } else {
            this.showModal("Error", "Could not launch Call to Action URL. It was blank.");
        }
    }

    checkAllLocations = () => {
        let locationIds = [];
        let defaultedDealLat = undefined
        let defaultedDealLon = undefined

        if (this.state.locations != null) {
            for (let i = 0; i < this.state.locations.length; i++) {

                // If no deal loc set, the deal loc is now this location...
                if (!this.state.dealLocationLatitude){

                    var loc = this.state.locations[i]
                    defaultedDealLat = loc.latitude
                    defaultedDealLon = loc.longitude
                    this.setState({
                        selectedLocationIds : locationIds
                    });
                }

                locationIds.push(this.state.locations[i].id);
            }
        }
        if (defaultedDealLat && defaultedDealLon) {
            this.googleMap.setZoom(15);
            this.setState({
                selectedLocationIds: locationIds,
                dealLocationLatitude: defaultedDealLat,
                dealLocationLongitude : defaultedDealLon
            });
        } else {
            this.setState({
                selectedLocationIds: locationIds
            });
        }
    }

    clearLocationSelections = () => {
        this.setState({
            selectedLocationIds : []
        });
    }


    showImageLib() {
        this.setState({
            showingImageLib : true
        },   window.scrollTo(0, 0) );
    }

    tableRowWasClicked(row) {

        this.setState({
            selectedImage : row,
            uploadImage : undefined,
            imagePreviewUri : row.publicUrl
        });
        this.hideImageLib()
    }

    hideImageLib() {
        this.setState({
            showingImageLib : false
        });
    }

    render() {

        if (this.state.showingImageLib) {

            return (<div className={"container"}>
                <HeaderComponent />

                <div className={"page-header"}>
                    <div className={"page-title"}><span className={"btn btn-outline-primary"} onClick={() => this.hideImageLib()}>
                            <FontAwesomeIcon icon={"chevron-left"} />
                        </span> Select an Image</div>
                    <div className={"page-actions"}>

                    </div>
                </div>


                <div className={"row form-block filter-area"}>
                    <div className={"col-12"}>
                        <label htmlFor={"imageFilter"}>Search</label>
                        <input type={"text"} className={"form-control"} name={"imageFilter"} value={this.state.imageFilter} onChange={this.filterDidChange} placeholder={"Search for Images"} />
                    </div>
                </div>

                <div className={"row form-block"}>
                    <div className={"col-12"}>
                        <TableComponent
                            className={"alternating clickable"}
                            data={this.state.tableData}
                            onRowClick={(row) => { console.log(row); this.tableRowWasClicked(row);}}
                            sortedColumn={this.state.sortedColumn}
                            onSort={(sort) => this.tableWasSorted(sort)}>
                            <Column name={"img"} title={"Image Thumbnail"} sortable={false} />
                            <Column name={"uploadDate"} title={"Date Added"} sortable={true} />
                            <Column name={"fileName"} title={"Filename"} sortable={true} />
                            <Column name={"width"} title={"Width"} sortable={true} />
                            <Column name={"height"} title={"Height"} sortable={true} />

                        </TableComponent>

                        <div className={"form-block ep-table-paginate-container"}>
                            <div className={"push"} />
                            <TablePaginator
                                page={this.state.tablePage}
                                totalCount={this.state.tableTotal}
                                pageSize={this.state.tableLimit}
                                onClick={(page) => this.tablePageDidChange(page)} />
                        </div>

                    </div>
                </div>

            </div>)
        }


        // Hide Submit button when deal inactive
        let saveButtonLabel = "Submit Deal";
        let saveInstructions = "";
        saveButtonLabel = " Save ";

        var submitButton = (<span className={"btn btn-success"} onClick={this.submitDeal}>{ saveButtonLabel }</span>);
        if (this.state.deal != null) {
            if (!this.isRenewal) {
                var expired = false;
                if (this.state.deal.endDate != null) {
                    expired = moment(this.state.deal.endDate) < moment();
                }
                if ((this.state.deal.active == 0 || expired)) {
                    submitButton = (
                        <div>

                        </div>
                    )
                }
            } else {
                let submitWarningMessage = [];
                if (this.user.merchantTypeId == API.MERCHANT_TYPES.INDEPENDENT) {
                    submitWarningMessage = (
                        <div className={"card"} style={{marginBottom: "10px", padding: "5px"}}>Re-activating this deal will use one of your Deal Slots. All statistics for this deal will be reset.</div>
                    );
                }

                submitButton = (
                    <div>
                        {submitWarningMessage}
                        {submitButton}
                    </div>
                )
            }
        }

        var locationElems = [];
        var activeSites = [];
        var selectedSites = "No branches selected"
        var allSelectedSites = "No branches selected"
        if (this.state.locationsNetworkInFlight) {
            // TODO Show loading progress
        } else {


            if (this.state.locations != null) {

                //if only 1 loc and new deal pre-select it...
                if (this.state.locations.length == 1 && !this.isRenewal && this.state.dealId == "new" && (this.state.selectedLocationIds == null || this.state.selectedLocationIds.length == 0)) {
                    let location = this.state.locations[0];

                    this.setState({
                        selectedLocationIds : [location.id],
                        dealLocationLatitude: location.latitude,
                        dealLocationLongitude : location.longitude
                    });
                   // this.googleMap.setZoom(15);
                }
                for (var i = 0; i < this.state.locations.length; i++) {
                    let location = this.state.locations[i];

                    let isChecked = false;
                    if (this.state.selectedLocationIds != null) {
                        for (var x = 0; x < this.state.selectedLocationIds.length; x++) {
                            if (this.state.selectedLocationIds[x] == location.id) {
                                isChecked = true;
                                break;
                            }
                        }
                    }

                    locationElems.push(<div className={"col-xs-12 col-md-6"}>
                        <label className={"location-selection"}>
                        <span className={"check"}>
                            <input type={"checkbox"} value={location.id} onChange={this.handleLocationSelection}
                                   checked={isChecked}/>
                        </span>
                            <span className={"content"}>
                            <span className={"title"}>{location.name}</span>
                            <span
                                className={"subtitle"}>{location.addressName} ({location.latitude}, {location.longitude})</span>
                        </span>
                        </label>
                    </div>);
                    if (isChecked){
                        activeSites.push(location.name)
                    }
                }
                if (activeSites.length == 0) {
                    selectedSites = <span>No branches selected</span>
                    allSelectedSites = <span>No branches selected</span>
                } else {


                    allSelectedSites = activeSites.map((row, c) => {

                        if (row === "Click for more..."){
                            return <span>{row} </span>
                        } else {
                            return <span className={"site-in-list"}>{row.replace(/\s/g, '\u00A0')} </span>
                        }

                    })

                    if (activeSites.length > 8) {
                        activeSites = activeSites.slice(0, 8)
                        activeSites.push("Click for more...")
                    }

                    selectedSites = activeSites.map((row, c) => {

                        if (row === "Click for more..."){
                            return <span>{row} </span>
                        } else {
                            return <span className={"site-in-list"}>{row.replace(/\s/g, '\u00A0')} </span>
                        }

                    })
                }

            }
        }

        let assignLocationsElement = [];
            let assignAllButton = [];
            if (this.user.merchantUserTypeId == API.USER_TYPES.ADMINISTRATOR) {
                assignAllButton = (
                    <span style={{whiteSpace: "nowrap"}}>
                        <span className={"btn btn-primary"} onClick={this.checkAllLocations}>Select All</span>&nbsp;
                        <span className={"btn btn-warning"} onClick={this.clearLocationSelections}>Clear All</span>
                    </span>
                );
            }

            assignLocationsElement = (<div className={"row form-block"}></div>);
            if (this.user.merchantUserTypeId == API.USER_TYPES.ADMINISTRATOR) {
                var displayElems = [];

                if (locationElems.length > 0) {
                    displayElems = locationElems;
                } else {
                    displayElems.push(<div className={"col-xs-12"} style={{margin:"20px"}}>
                       No matches found.
                    </div>)
                }
                assignLocationsElement = (
                    <div className={"row form-block"}>
                        <div className={"col-12"}>
                            <label className={"no-margin"}>Assign Locations</label>
                            <div className={"small-label"}>Locations will draw Gas App Uk users to a location more
                                conveniently situated to them. You can add your Deal to as many Locations as you want.
                            </div>
                        </div>
                        <div className={"col-12"}>
                            <div className={"location-selection-container"}>
                                <div className={"location-selection-filter"}>
                                    {assignAllButton}
                                    <div className="spacer"/>
                                    <div className={"title"} style={{marginLeft:"10px"}}>Selected:</div>
                                    <div className={"text_elips"} onClick={() => this.selectedListWasClicked(allSelectedSites)}>
                                        {selectedSites}
                                    </div>
                                    <div className="spacer"/>

                                    <div className={"input-area"}>
                                        <div className={"input-group"} >
                                            <input type={"text"} className={"form-control"}
                                                   placeholder={"Filter Locations..."} name={"locationsFilter"}
                                                   onChange={this.filterLocations} value={this.state.locationsFilter}/>
                                            <div className="input-group-append">
                                                <span className="input-group-text"
                                                      onClick={this.clearLocationFilter}>Clear</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className={"location-selection-scroller"}>
                                    <div className={"row"}>
                                        {displayElems}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <AlertModal
                            open={ (this.state.modal != null) ? this.state.modal.open : false }
                            title={ (this.state.modal != null) ? this.state.modal.title : "" }
                            content={ (this.state.modal != null) ? this.state.modal.content : [] }
                            buttons={ (this.state.modal != null) ? this.state.modal.buttons : [] }
                            dismissHandler={ () => this.modalWasDismissed() }
                        />

                    </div>
                );
            }


        let addressLookupInlineError = [];
        if (this.state.addressLookupError != null) {
            addressLookupInlineError = (
                <div className={"error"}>{this.state.addressLookupError}</div>
            );
        }

        let addressLookupPending = [];
        if (this.state.addressLookupNetworkInFlight) {
            addressLookupPending = (
                <div className={"pending"}>Please wait, searching for address...</div>
            )
        }

        let defaultCamera = {
            lat : (this.state.dealLocationLatitude != null) ? this.state.dealLocationLatitude : 53.4134046,
            lng : (this.state.dealLocationLongtidue != null) ? this.state.dealLocationLongtidue : -1.8996704
        };
        let cameraCentre = null;
        if (this.state.dealLocationLatitude != null) {
            defaultCamera.lat = this.state.dealLocationLatitude;
            defaultCamera.lng = this.state.dealLocationLongitude;

            cameraCentre = {
                lat: this.state.dealLocationLatitude,
                lng: this.state.dealLocationLongitude
            };
        }

        let pageTitle = "New Deal";
        if (this.state.dealId != null && this.state.dealId != "new" && !this.isRenewal) {
            pageTitle = "Edit Deal";
        } else if (this.isRenewal) {
            pageTitle = "Re-activate Deal";
        }

        return (
            <div className={"container"}>
                <HeaderComponent />

                <div className={"page-header"}>
                    <div className={"page-title"}>
                        <span className={"btn btn-outline-primary"} onClick={() => window.location.href = "/deals/"}>
                            <FontAwesomeIcon icon={"chevron-left"} />
                        </span>
                        { pageTitle }
                    </div>
                </div>

                <DealInfoComponent
                    deal={(!this.isRenewal) ? this.state.deal : null}
                    dealID={(!this.isRenewal) ? this.state.dealId : null}


                />

                <div className={"section-container deal-editor-form"}>
                    <div className={"section-content"}>
                        <div className={"row"}>
                            <div className={"col-12"}>
                                <label>Deal title</label>
                                <input type={"text"} name={"dealTitle"} className={"form-control"} value={(this.state.dealTitle != null) ? this.state.dealTitle : "" } onChange={this.handleChange} />
                            </div>
                        </div>

                        <div className={"row form-block"}>
                            <div className={"col-12"}>
                                <label className={"no-margin"}>Show this deal in -</label>
                                <div className={"small-label"}></div>

                                <select className={"form-control"}  value={this.state.dealApp} name="dealApp" onChange={this.handleChange}>
                                    <option value="both">Both Apps</option>
                                    <option value="gas">Gas App Uk Only</option>
                                    <option value="plumb">Plumb App Uk Only</option>
                                </select>

                            </div>
                        </div>

                        <div className={"row form-block"}>
                            <div className={"col-12"}>
                                <label className={"no-margin"}>Deal Description</label>
                                <div className={"small-label"}>Note: Maximum 250 characters</div>

                                <textarea name={"dealLongDescription"} className={"form-control"} value={ (this.state.dealLongDescription != null) ? this.state.dealLongDescription : "" } maxLength={250}  onChange={this.handleChange} />
                                <div>{ this.state.dealLongDescription != null ? 250 - this.state.dealLongDescription.length : 250 } characters remaining</div>

                            </div>
                        </div>

                        <div className={"row form-block"}>
                            <div className={"col-12"}>
                                <label className={"no-margin"}>Deal Image</label>
                                <div className={"image-selection recess-area form-block"}>
                                    <div className={"row"}>
                                        <div className={"col-12 col-md-4 image-container"}>
                                            <div className={"image-preview"} ref={this.imagePreview} style={{ backgroundImage : (this.state.imagePreviewUri != null) ? "url(" + this.state.imagePreviewUri + ")" : "" }} />
                                        </div>
                                        <div className={"col-12 col-md-8 text-center"}>
                                            <div className={"image-filename"}>{ (this.state.uploadImage != null) ? this.state.uploadImage.name : (this.state.selectedImage != null) ? this.state.selectedImage.fileName :  "No image selected" }</div>
                                            <div className={"form-block"}>
                                                <div className={"input-hide"}>
                                                    <input type={"file"} ref={this.dealImageInput} onChange={ this.imageSelectionDidChange } />
                                                </div>
                                                <span className={"btn btn-primary"} onClick={() => this.dealImageInput.current.click()}>Upload Image</span>&nbsp;
                                                <span className={"btn btn-primary"} onClick={() => this.showImageLib()}>Choose From Library</span>
                                            </div>
                                            <div className={"form-block text-small"}>
                                                <p>Please note the following when selecting your deal image:</p>
                                                <ul className={"text-left"}>
                                                    <li>Maximum allowed file size is 2 megabytes.</li>
                                                    <li>Images will be displayed in a 16:9 aspect ratio in the deal screen regardless of the aspect ratio of the uploaded image.</li>
                                                    <li>In deal lists images will be displayed in a 1:1 aspect ratio.</li>
                                                    <li>Please avoid including text in your image, it may not be legible in certain situations</li>
                                                    <li>Please consider using using a lower quality setting when mastering images to keep file sizes lower.</li>
                                                    <li>Consider uploading images of sizes (pixels): 1280 x 720, 1920 x 1080 or multiples of.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={"row form-block"}>

                            <div className={"col-12"}>
                                <div className={"map-selection-container"}>
                                    <div className={"row"} style={{display: "none"}}>
                                        <div className={"col-12"}>
                                            <div className={"map"}>
                                                <GoogleMapReact
                                                    bootstrapURLKeys={{ key : "AIzaSyASR6vqBuN-lvHwVF2oy3o8JutLDGk0hfI" }}
                                                    defaultCenter={defaultCamera}
                                                    center={cameraCentre}
                                                    defaultZoom={(this.state.dealLocationLatitude != null) ? 12 : 5 }
                                                    onClick={this.googleMapWasClicked}
                                                    onGoogleApiLoaded={({map, maps}) => this.googleMapDidLoad(map, maps)}
                                                    yesIWantToUseGoogleMapApiInternals>

                                                    { this.getMapMarkers() }

                                                </GoogleMapReact>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={"map-controls"}>
                                        <div className={"row"} style={{display: "none"}}>
                                            <div className={"hidden-xs col-md-2"} />
                                            <div className={"col-12 col-md-8"}>
                                                <label className={"no-margin"}>Address Lookup</label>
                                                <div className={"input-group"}>
                                                    <input type={"text"} className={"form-control"} name={"dealLookupAddress"} value={this.state.dealLookupAddress} onChange={this.handleChange} hint={"Type a location. Only places within the UK."} />
                                                    <div className={"input-group-append"}>
                                                        <span className={"input-group-text lookup-append-button"} onClick={this.lookupAddressOverNetwork}>Lookup</span>
                                                    </div>
                                                </div>
                                                {addressLookupPending}
                                                {addressLookupInlineError}
                                            </div>
                                        </div>


                                        <div className={"row"} style={(this.state.dealLocationShowAdvanced) ? { display : "flex" } : { display : "none" }}>
                                            <div className={"col-12 col-md-6"}>
                                                <label>Latitude</label>
                                                <input type={"text"} name={"dealLocationLatitude"} className={"form-control"} value={this.state.dealLocationLatitude} placeholder={"Latitude"} onChange={this.handleChange} />
                                            </div>
                                            <div className={"col-12 col-md-6"}>
                                                <label>Longitude</label>
                                                <input type={"text"} name={"dealLocationLongitude"} className={"form-control"} value={this.state.dealLocationLongitude} placeholder={"Longitude"} onChange={this.handleChange} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {assignLocationsElement}

                        <div className={"row form-block"}>
                            <div className={"col-12 col-md-6"}>
                                <label className={"no-margin"}>Price</label>
                                <div className={"small-label"}>Should be a price or percentage. Please include currency symbol if price provided (e.g. &pound; or &euro;). Very limited space.  You can also add text like "Call for a price" or "POA".</div>
                                <input type={"text"} name={"dealPriceHeadline"} className={"form-control"} value={ this.state.dealPriceHeadline } onChange={this.handleChange} />
                            </div>

                            <div className={"col-12 col-md-6"} style={{display: "none"}}>
                                <label className={"no-margin"}>Price Info</label>
                                <div className={"small-label"}>Short price description that appears smaller below the price. (e.g. "10% off!", "Earn double points", etc)</div>
                                <input type={"text"} name={"dealPriceSubtitle"} className={"form-control"} value={ this.state.dealPriceSubtitle } onChange={this.handleChange} />
                            </div>
                        </div>

                        <div className={"row form-block"}>
                            <div className={"col-12 col-md-6"}>
                                <label className={"no-margin"}>Start Date</label>
                                <span style={{float:"right"}} className={"btn btn-primary"} onClick={this.sevenDayShortcut}>Set to next 7 days</span>

                                <div className={"small-label"}>Please provide a time and date for when the deal will start. Deal will not be visible until the deal start time has passed.</div>
                                <DatePicker
                                    className={"form-control text-center"}
                                    name={"dealStartDate"}
                                    selected={this.state.dealStartDate}
                                    showTimeSelect
                                    dateFormat="dd/MM/yyyy HH:mm"
                                    timeFormat="HH:mm"
                                    timeIntervals={15}
                                    onChange={(date) => this.handleDateChange("dealStartDate", date)}
                                    minDate={startOfMonth}
                                    maxDate={this.endOfMonth} />
                            </div>

                            <div className={"col-12 col-md-6"}>
                                <label className={"no-margin"}>End Date</label>
                                <div className={"small-label"}>Please provide a time and date when the deal will end. Once end date has elapsed, the deal will be considered "finished" and no longer visible.</div>
                                <DatePicker
                                    className={"form-control text-center"}
                                    name={"dealEndDate"}
                                    selected={this.state.dealEndDate}
                                    showTimeSelect
                                    dateFormat="dd/MM/yyyy HH:mm"
                                    timeFormat="HH:mm"
                                    timeIntervals={15}
                                    onChange={(date) => this.handleDateChange("dealEndDate", date)}
                                    minDate={startOfMonth}
                                    maxDate={this.endOfMonth} />
                            </div>
                        </div>

                        {this.state.disclaimer &&
                            <div className={"row form-block"}>
                                <div className={"col-12"}>
                                    <div dangerouslySetInnerHTML={{__html: this.state.disclaimer}}></div>

                                </div>
                            </div>
                        }


                        <div className={"row form-block"}>
                            <div className={"col-12 text-center"}>
                                <div className={"submission-buttons"} style={{ display : (!this.state.isUploading) ? "block" : "none" }}>
                                    { submitButton }
                                    { saveInstructions }
                                </div>
                                <div className={"upload-progress-area"} style={{ display : (this.state.isUploading) ? "block" : "none" }}>
                                    <span className={"upload-notice-label"}>Saving, please wait...</span>
                                    <div className={"progress"}>
                                        <div className={"progress-bar progress-bar-striped progress-bar-animated"} style={{ width : this.state.uploadProgressPercent + "%" }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {this.state.modalSiteList && <div className="modalz">

                    <div className="modal-contentz">

                        <div className={"col-12 text-center"}>
                            <span className={"close"} onClick={() => this.dismissClicked() }>X</span>

                            <h3>Selected branches:</h3>
                    </div>
                        <div className={"col-12 text-center"}>
                        </div>
                        <div className={"col-12 text-center"}>
                            {this.state.modalSiteList}
                        </div>

                    </div></div>}
                <AlertModal
                    open={ (this.state.modal != null) ? this.state.modal.open : false }
                    title={ (this.state.modal != null) ? this.state.modal.title : "" }
                    content={ (this.state.modal != null) ? this.state.modal.content : [] }
                    buttons={ (this.state.modal != null) ? this.state.modal.buttons : [] }
                    dismissHandler={ () => this.modalWasDismissed() }
                />
            </div>
        );
    }

}

export default DealEditorScreenComponent;
