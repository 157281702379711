import StatefulComponent from "../StatefulComponent";
import HeaderComponent from "../common/HeaderComponent";
import React from "react";
import AlertModal from "../common/AlertModal";
import TableComponent, {Column, TablePaginator} from "../common/TableComponent";
import Axios from "axios";
import {API, ENDPOINTS} from "../../networking/API";


class ImageLibScreenComponent extends StatefulComponent {


    constructor(props, context) {
        super(props, context);

        this.initState({

            tablePage : 1,
            tableTotal : 0,
            tableData : [],
            tableLimit : 25, // 25 images per page,
            sortedColumn : {name : "uploadDate", direction: "desc"},
            imageFilter : ""
        });

        this.moveToImageEditor = this.moveToImageEditor.bind(this);
        this.getTableDataFromNetwork = this.getTableDataFromNetwork.bind(this);
    }

    tablePageDidChange(page) {
        this.setState({
            tablePage : page
        });

        this.getTableDataFromNetwork(page);
    }

    tableRowWasClicked(row) {
        this.moveToImageEditor(row.id);
    }

    moveToImageEditor(id) {
        window.location.href = "/images/" + id;
    }



    componentDidMount()
    {
        super.componentDidMount()
        this.getTableDataFromNetwork();

    }

    getTableDataFromNetwork(page, sort) {
        if (this.state.fetchingTableData) return;
        this.setState({
            fetchingTableData : true
        });

        if (page === undefined) {
            page = this.state.tablePage;
        }

        if (sort === undefined) {
            sort = this.state.sortedColumn;
        }

        let formData = new FormData();
        formData.append("page", page);
        formData.append("limit", this.state.tableLimit);

        if (sort !== undefined) {
            formData.append("sort", JSON.stringify(sort));
        }

        if (this.state.imageFilter !== undefined && this.state.imageFilter !== "") {
            formData.append("keyword", this.state.imageFilter);
        }

        var context = this;

        Axios.post(ENDPOINTS.images.getList, formData)
            .then(function(response) {
                let resp = API.parseResponse(response);
                if (resp.success) {

                    let mappedTableData = resp.data.data.map((row, c) => {


                        let img =   <div className={"image-preview"} style={{ backgroundImage : (row.publicUrl != null) ? "url(" + row.publicUrl + ")" : "" , border : "none", paddingTop : "51%"}} />
                        row.img = img
                        return row

                    })

                    context.setState({
                        fetchingTableData : false,
                        tableData : mappedTableData,
                        tableTotal : resp.data.total
                    });
                } else {
                    context.setState({
                        fetchingTableData : false
                    });
                    context.showModal("Error", resp.error);
                }
            })
            .catch(function(err) {
                console.log(err);
                context.setState({
                    fetchingTableData : false
                });
                context.showModal("Error", "An unknown error occurred. [1701]");
            });
    }

    tableWasSorted = (sort) => {
        this.getTableDataFromNetwork(this.state.tablePage, sort);

        this.setState({
            sortedColumn : sort
        });
    }

    filterDidChange = (event) => {
        clearTimeout(this.filterKeywordTimeout);
        this.filterKeywordTimeout = setTimeout(() => {
            this.getTableDataFromNetwork(1);
        }, 800);

        this.setState({
            tablePage : 1
        });

        this.handleChange(event);
    }

    render() {

        return (
            <div className={"container"}>
                <HeaderComponent />

                <div className={"page-header"}>
                    <div className={"page-title"}>Image Library</div>
                    <div className={"page-actions"}>
                        <button className={"btn btn-success"} onClick={() => this.moveToImageEditor("new")}>Add a new Image</button>
                    </div>
                </div>


                <div className={"row form-block filter-area"}>
                    <div className={"col-12"}>
                        <label htmlFor={"imageFilter"}>Search</label>
                        <input type={"text"} className={"form-control"} name={"imageFilter"} value={this.state.imageFilter} onChange={this.filterDidChange} placeholder={"Search for Images"} />
                    </div>
                </div>

                <div className={"row form-block"}>
                    <div className={"col-12"}>
                        <TableComponent
                            className={"alternating clickable"}
                            data={this.state.tableData}
                            onRowClick={(row) => { console.log(row); this.tableRowWasClicked(row);}}
                            sortedColumn={this.state.sortedColumn}
                            onSort={(sort) => this.tableWasSorted(sort)}>
                            <Column name={"img"} title={"Image Thumbnail"} sortable={false} />
                            <Column name={"uploadDate"} title={"Date Added"} sortable={true} />
                            <Column name={"fileName"} title={"Filename"} sortable={true} />
                            <Column name={"width"} title={"Width"} sortable={true} />
                            <Column name={"height"} title={"Height"} sortable={true} />

                        </TableComponent>

                        <div className={"form-block ep-table-paginate-container"}>
                            <div className={"push"} />
                            <TablePaginator
                                page={this.state.tablePage}
                                totalCount={this.state.tableTotal}
                                pageSize={this.state.tableLimit}
                                onClick={(page) => this.tablePageDidChange(page)} />
                        </div>

                    </div>
                </div>

                <AlertModal
                    open={ (this.state.modal != null) ? this.state.modal.open : false }
                    title={ (this.state.modal != null) ? this.state.modal.title : "" }
                    content={ (this.state.modal != null) ? this.state.modal.content : [] }
                    buttons={ (this.state.modal != null) ? this.state.modal.buttons : [] }
                    dismissHandler={ () => this.modalWasDismissed() }
                />
            </div>

        );


    }

}

export default ImageLibScreenComponent;
