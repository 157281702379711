import React from 'react';
import ReactDOM from 'react-dom';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faUpload, faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import './App.css';
import './assets/css/bootstrap.min.css';
import './assets/css/default.css';
import MainComponent from "./components/MainComponent";

function App() {
  // Font Awesome library
  library.add(faUpload, faChevronLeft);

  return (
    <MainComponent />
  );
}

export default App;
