import StatefulComponent from "../StatefulComponent";
import HeaderComponent from "../common/HeaderComponent";
import RemainingCountComponent from "../common/RemainingCountComponent";
import React from "react";
import Axios from "axios";
import {API, ENDPOINTS} from "../../networking/API";

import DatePicker from "react-datepicker/es";
import AlertModal from "../common/AlertModal";
import TableComponent, {Column, TablePaginator} from "../common/TableComponent";
import moment from "moment";
import LoadingSpinner from "../common/LoadingSpinner";
import SegmentedButtonComponent from "../common/SegmentedButtonComponent";

export default class LocationDealReportScreenComponent extends StatefulComponent {

    WIDGET_OVERFLOW_FLOOR = 12;

    REPORT_VIEW_DEALS = 1;
    REPORT_VIEW_NO_DEALS = 2;

    constructor(props, context) {
        super(props, context);

        this.handleScreenRestriction([API.USER_TYPES.ADMINISTRATOR]);

        var user = localStorage.getItem("user");
        if (user != null) {
            user = JSON.parse(user);
        }

        let segmentedButtons = [
            { id : this.REPORT_VIEW_DEALS, label : "Location Deals" },
            { id : this.REPORT_VIEW_NO_DEALS, label : "Locations Without Deals" }
        ];

        this.initState({
            user,
            triggeredGenerationAtLeastOnce : false,
            locationNetworkInFlight : false,
            reportNetworkInFlight : false,
            locations : [],
            selectedLocationIds : [],
            reportLocationIds : [], // selectedLocationIds will be copied into here upon report generation
            startDate : moment().add(-6, "months").toDate(),
            endDate : moment().toDate(),
            reportData : null,
            tableData : null,
            totalCount : 0,
            pageSize : 50,
            currentPage : 1,
            exportDownloadUrl : "",
            exportDownloadFilename : "",
            widgetsCollapsed : true,
            reportKeyword : "",
            locationKeyword : "",
            segmentedButtons,
            selectedSegmentId : this.REPORT_VIEW_DEALS,
            noDealReportData : null,
            noDealTableData : null,
            noDealTotalCount : 0,
            noDealPageSize : 50,
            noDealCurrentPage : 1,
            noDealKeyword : ""
        });

        this.exportDownloadElem = React.createRef();
    }

    componentDidMount() {
        this.fetchBranchLocationsFromNetwork();
    }

    checkLocationId = (locationId) => {
        let selectedLocationIds = this.state.selectedLocationIds;

        if (selectedLocationIds.includes(locationId)) {
            for (let i = 0; i < selectedLocationIds.length; i++) {
                if (selectedLocationIds[i] === locationId) {
                    selectedLocationIds.splice(i, 1);
                    break;
                }
            }
        } else {
            selectedLocationIds.push(locationId);
        }

        this.setState({
            selectedLocationIds
        });
    }

    keywordDidChange = (e) => {
        this.handleChange(e, () => {
            this.pageDidChange(1);
        });
    }

    handleDateChange = (key, date) => {
        let state = this.state;

        state[key] = date;

        this.setState(state);
    }

    selectAllLocations = () => {
        let selectedLocationIds = [];

        this.state.locations.forEach((location) => {
            selectedLocationIds.push(location.id);
        });

        this.setState({
            selectedLocationIds
        });
    }

    deselectAllLocations = () => {
        this.setState({
            selectedLocationIds : []
        });
    }

    toggleWidgetCollapse = (collapsed) => {
        this.setState({
            widgetsCollapsed : collapsed
        });
    }

    segmentDidChange = (id) => {
        this.setState({
            selectedSegmentId : id
        }, () => {
            this.pageDidChange();
        });
    }

    fetchBranchLocationsFromNetwork = () => {
        if (this.state.locationNetworkInFlight) return;
        this.setState({
            locationNetworkInFlight : true
        });

        let formData = new FormData();
        formData.append("page", 1);
        formData.append("limit", 99999);

        Axios.post(ENDPOINTS.location.getLocations, formData)
            .then((r) => {
                let locations = this.state.locations;

                let resp = API.parseResponse(r);
                if (resp.success) {
                    locations = resp.data.data;
                } else {
                    console.log(resp.error);
                }

                this.setState({
                    locationNetworkInFlight : false,
                    locations
                });
            })
            .catch((e) => {
                console.log(e);

                this.setState({
                    locationNetworkInFlight : false,
                });
            });
    }

    pageDidChange = (page) => {
        let tableData = [];

        let reportData = null;
        let totalCount = 0;
        let keyword = null;
        let pageSize = 50;
        let keywordPropertyName = null;
        let tableDataPropName = null;
        let tableCountPropName = null;
        let currentPagePropName = null;

        if (this.state.selectedSegmentId === this.REPORT_VIEW_DEALS) {
            reportData = this.state.reportData;
            totalCount = reportData.length;
            keyword = this.state.reportKeyword;
            keywordPropertyName = "locationName";
            pageSize = this.state.pageSize;
            tableDataPropName = "tableData";
            tableCountPropName = "tableCount";
            currentPagePropName = "currentPage";

            if (page === undefined) {
                page = this.state.currentPage;
            }
        } else {
            reportData = this.state.noDealReportData;
            totalCount = this.state.noDealTotalCount;
            keyword = this.state.noDealKeyword;
            keywordPropertyName = "name";
            pageSize = this.state.noDealPageSize;
            tableDataPropName = "noDealTableData";
            tableCountPropName = "noDealTableCount";
            currentPagePropName = "noDealCurrentPage";

            if (page === undefined) {
                page = this.state.noDealCurrentPage;
            }
        }

        if (keyword !== null && keyword !== "") {
            let filteredData = [];

            for (let i = 0; i < reportData.length; i++) {
                if (reportData[i][keywordPropertyName].toLowerCase().includes(this.state.reportKeyword.toLowerCase())) {
                    filteredData.push(reportData[i]);
                }
            }

            reportData = filteredData;
            totalCount = filteredData.length;
        }

        if (reportData != null && reportData.length > 0) {
            let start = pageSize * (page - 1);

            for (let i = start; i < reportData.length; i++) {
                tableData.push(reportData[i]);

                if (tableData.length >= pageSize) {
                    break;
                }
            }
        }

        let state = {};
        state[tableDataPropName] = tableData;
        state[tableCountPropName] = totalCount;
        state[currentPagePropName] = page;

        this.setState(state);
    }

    fetchReportFromNetwork = (exporting) => {
        if (exporting !== true && exporting !== false) {
            exporting = false;
        }

        if (this.state.reportNetworkInFlight) return;

        if (this.state.selectedLocationIds.length === 0) {
            this.showModal("Error", "Please select at least one Location.");
            return;
        }

        if (this.state.startDate === null || this.state.endDate === null) {
            this.showModal("Error", "Please provide a Start and End Date.");
            return;
        }

        if (moment(this.state.endDate).isBefore(moment(this.state.startDate))) {
            this.showModal("Error", "The Start Date cannot be before the End Date. Please adjust the date range and try agan.");
            return;
        }

        this.setState({
            reportNetworkInFlight : true,
            widgetsCollapsed : true
        });

        let formData = new FormData();
        formData.append("startDate", (this.state.startDate.getTime() / 1000).toFixed(0));
        formData.append("endDate", (this.state.endDate.getTime() / 1000).toFixed(0));
        formData.append("branchIds", JSON.stringify(this.state.selectedLocationIds));

        if (exporting === true) {
            formData.append("export", "1");
        }

        Axios.post(ENDPOINTS.deal.getBranchDealsReport, formData)
            .then((r) => {
                if (!exporting) {
                    let reportData = null;
                    let totalCount = 0;
                    let reportLocationIds = []; // Clone selectedLocationIds

                    let noDealReportData = null;
                    let noDealTotalCount = 0;

                    let resp = API.parseResponse(r);
                    if (resp.success) {
                        reportData = resp.data.reportData;
                        totalCount = reportData.length;

                        noDealReportData = resp.data.noDealLocations;
                        noDealTotalCount = noDealReportData.length;

                        this.state.selectedLocationIds.forEach((locationId) => {
                            reportLocationIds.push(locationId);
                        });
                    } else {
                        console.log(resp.error);
                    }

                    this.setState({
                        reportNetworkInFlight : false,
                        reportData,
                        totalCount,
                        reportLocationIds,
                        noDealReportData,
                        noDealTotalCount,
                        triggeredGenerationAtLeastOnce : true
                    }, () => {
                        this.pageDidChange(1);
                    });
                } else {
                    if (r.status === 200) {
                        // Trigger download
                        this.setState({
                            exportDownloadUrl: window.URL.createObjectURL(new Blob([r.data]), {type:"text/csv"}),
                            exportDownloadFilename: "export_" + moment().format("YYYY_MM_DD_HH_mm_ss") + ".csv"
                        }, () => {
                            this.exportDownloadElem.current.click();

                            window.URL.revokeObjectURL(this.state.exportDownloadUrl);

                            this.setState({
                                reportNetworkInFlight : false,
                                exportDownloadUrl: "",
                                exportDownloadFilename: ""
                            });
                        });
                    } else {
                        this.showModal("Error", "There was an error when exporting the requested data. Please try again later. [D19001]");
                    }
                }
            })
            .catch((e) => {
                this.setState({
                    reportNetworkInFlight : false
                });

                console.log(e);
            });
    }

    render() {
        let locationElements = [];
        if (this.state.locationNetworkInFlight) {
            locationElements.push(
                <li className={"loading-item text-center"}>
                    <LoadingSpinner shown={true} />
                </li>
            )
        } else {
            this.state.locations.forEach((location) => {
                let shouldAdd = true;

                if (this.state.locationKeyword !== "") {
                    if (!location.name.toLowerCase().includes(this.state.locationKeyword.toLowerCase())) {
                        shouldAdd = false;
                    }
                }

                if (shouldAdd) {
                    locationElements.push(
                        <li className={"list-item"}>
                            <label>
                                <span className={"check"}>
                                    <input type={"checkbox"} checked={this.state.selectedLocationIds.includes(location.id)} onChange={() => this.checkLocationId(location.id)}/>
                                </span>
                                <span className={"label"}>
                                    {location.name}, {location.addressName}, {location.addressPostcode}
                                </span>
                            </label>
                        </li>
                    );
                }
            });
        }

        let reportArea = [];

        if (this.state.reportNetworkInFlight) {
            reportArea.push(
                <div className={"row"}>
                    <div className={"col-12 text-center"}>
                        <LoadingSpinner shown={true} />
                    </div>
                </div>
            )
        } else if (this.state.triggeredGenerationAtLeastOnce) {
            reportArea.push(
                <div className={"row justify-content-center mb-4"}>
                    <div className={"col-12 col-md-8 col-lg-6"}>
                        <SegmentedButtonComponent buttons={this.state.segmentedButtons} selectedId={this.state.selectedSegmentId} changeHandler={this.segmentDidChange} />
                    </div>
                </div>
            )

            if (this.state.selectedSegmentId === this.REPORT_VIEW_DEALS) {
                if (this.state.reportData !== null && this.state.reportData.length > 0) {
                    let widgetData = {};

                    for (let i = 0; i < this.state.reportData.length; i++) {
                        let row = this.state.reportData[i];

                        if (widgetData[row.locationId] !== undefined) {
                            widgetData[row.locationId].count++;
                        } else {
                            widgetData[row.locationId] = {
                                id: row.locationId,
                                name: row.locationName,
                                count: 1
                            }
                        }
                    }

                    let widgetKeys = Object.keys(widgetData);

                    // Work out if selected sites do not appear in the data
                    if (widgetKeys.length !== this.state.reportLocationIds.length) {
                        // There are missing sites. Find them now.
                        this.state.reportLocationIds.forEach((selectedLocationId) => {
                            if (!widgetKeys.includes(selectedLocationId + "")) {
                                selectedLocationId = parseInt(selectedLocationId);

                                let location = null;

                                for (let i = 0; i < this.state.locations.length; i++) {
                                    if (parseInt(this.state.locations[i].id) === selectedLocationId) {
                                        location = this.state.locations[i];
                                        break;
                                    }
                                }

                                if (location !== null) {
                                    widgetData[location.id] = {
                                        id: location.id,
                                        name: location.name,
                                        count: 0
                                    }
                                }
                            }
                        });

                        // Re-generate Widget Keys as they will have changed
                        widgetKeys = Object.keys(widgetData);
                    }

                    // TODO Can we sort this data before its rendered to page?
                    if (widgetKeys.length > 0) {
                        let widgetElements = [];

                        widgetKeys.forEach((data, index) => {
                            let widget = widgetData[data];

                            widgetElements.push(
                                <div className={"col-6 col-md-4 col-lg-2 mb-2"}>
                                    <div className={"summary-widget"}>
                                        <div className={"title-container"}>
                                            <div className={"title"}>{widget.name}</div>
                                        </div>
                                        <div className={"count"}>
                                            <span>{widget.count}</span>
                                        </div>
                                    </div>
                                </div>
                            )
                        })

                        let collapsedState = "";
                        if (this.state.widgetsCollapsed && widgetElements.length > this.WIDGET_OVERFLOW_FLOOR) {
                            collapsedState = " collapsed";
                        }

                        reportArea.push(
                            <div className={"summary-widgets" + collapsedState}>
                                <div className={"summary-title"}>Deals per Location for this period</div>
                                <div className={"row justify-content-center"}>
                                    {widgetElements}
                                </div>

                                <div className={"scrim"} onClick={() => this.toggleWidgetCollapse(false)}>
                                    <span className={"label"}>Show All</span>
                                </div>
                            </div>
                        )
                    }

                    reportArea.push(
                        <div className={"row mt-2 mb-2"}>
                            <div className={"col-6 col-lg-3"}>
                                <input type={"text"} className={"form-control"} name={"reportKeyword"}
                                       value={this.state.reportKeyword} onChange={this.keywordDidChange}
                                       placeholder={"Search Locations"}/>
                            </div>
                            <div className={"d-none d-lg-block col-lg-3"}/>
                            <div className={"col-6 text-right"}>
                                <button className={"btn btn-default"}
                                        onClick={() => this.fetchReportFromNetwork(true)}>Export Data
                                </button>
                            </div>
                        </div>
                    )

                    reportArea.push(
                        <TableComponent
                            className={"alternating clickable"}
                            data={this.state.tableData}
                            suppressNulls={true}
                            onRowClick={(row) => {
                                console.log(row);
                            }}>

                            <Column name={"locationName"} title={"Location"} className={"text-center"}
                                    sortable={false}/>
                            <Column name={"title"} title={"Deal Title"} sortable={false}/>
                            <Column name={"longDescription"} title={"Product Description"} sortable={false}/>
                            <Column name={"priceHeadline"} title={"Price"} className={"text-center"} sortable={false}/>
                            <Column name={"priceSubtitle"} title={"Price 2"} className={"text-center"}
                                    sortable={false}/>
                            <Column name={"karmaCache"} title={"Likes"} className={"text-center"} sortable={false}/>
                            <Column name={"app"} title={"App"} className={"text-center"} sortable={false}
                                    render={(data, row) => {
                                        let label = "All";
                                        let className = "";

                                        if (data === "gas") {
                                            label = "GasApp";
                                            className = "badge-gasapp";
                                        } else if (data === "plumb") {
                                            label = "PlumbApp";
                                            className = "badge-plumbapp";
                                        }

                                        return (<span className={"badge " + className}>{label}</span>);
                                    }}/>
                            <Column name={"startDate"} title={"Start Date"} className={"text-center"} sortable={false}
                                    render={(data, row) => {
                                        return moment(data).format("DD/MM/YYYY HH:mm");
                                    }}/>
                            <Column name={"endDate"} title={"End Date"} className={"text-center"} sortable={false}
                                    render={(data, row) => {
                                        return moment(data).format("DD/MM/YYYY HH:mm");
                                    }}/>

                        </TableComponent>
                    )

                    reportArea.push(
                        <div className={"form-block ep-table-paginate-container"}>
                            <div className={"push"}/>
                            <TablePaginator
                                page={this.state.currentPage}
                                totalCount={this.state.totalCount}
                                pageSize={this.state.pageSize}
                                onClick={(page) => this.pageDidChange(page)}/>
                        </div>
                    )
                } else {
                    reportArea.push(
                        <div className={"row"}>
                            <div className={"col-12 report-empty-message"}>
                                <div className={"title"}>No Results</div>
                                <div className={"message"}>No matches for the currently selected criteria</div>
                            </div>
                        </div>
                    )
                }
            } else if (this.state.selectedSegmentId === this.REPORT_VIEW_NO_DEALS) {
                reportArea.push(
                    <TableComponent
                        className={"alternating clickable"}
                        data={this.state.noDealTableData}
                        suppressNulls={true}
                        onRowClick={(row) => { console.log(row); }}>

                        <Column name={"name"} title={"Location"} className={"text-center"} sortable={false} />
                        <Column name={"addressName"} title={"Address Name"} sortable={false} />
                        <Column name={"addressLine1"} title={"Address Line 1"} sortable={false} />
                        <Column name={"addressLine2"} title={"Address Line 2"} className={"text-center"} sortable={false} />
                        <Column name={"addressCity"} title={"City"} className={"text-center"} sortable={false} />
                        <Column name={"addressCounty"} title={"County"} className={"text-center"} sortable={false} />
                        <Column name={"addressPostcode"} title={"Postcode"} className={"text-center"} sortable={false} />

                    </TableComponent>
                )

                reportArea.push(
                    <div className={"form-block ep-table-paginate-container"}>
                        <div className={"push"} />
                        <TablePaginator
                            page={this.state.noDealCurrentPage}
                            totalCount={this.state.noDealTotalCount}
                            pageSize={this.state.noDealPageSize}
                            onClick={(page) => this.pageDidChange(page)} />
                    </div>
                )
            }
        }

        return (
            <div>
                <div className={"row form-block filter-area"}>
                    <div className={"col-12 col-md-6"}>
                        <label className={"mb-1"}>Select Locations <span className={"badge badge-success"} onClick={this.selectAllLocations}>Select All</span> <span className={"badge badge-danger"} onClick={this.deselectAllLocations}>Select None</span> </label>
                        <input className={"form-control mb-1"} name={"locationKeyword"} onChange={this.handleChange} placeholder={"Filter Locations"} />
                        <ul className={"location-selector-list"}>
                            {locationElements}
                        </ul>
                    </div>

                    <div className={"col-12 col-md-6 date-fields"}>
                        <div className={"date-row"}>
                            <label>Start Date</label>
                            <DatePicker
                                className={"form-control text-center"}
                                name={"fromDate"}
                                selected={this.state.startDate}
                                dateFormat="dd/MM/yyyy"
                                onChange={(date) => this.handleDateChange("startDate", date)} />
                        </div>

                        <div className={"date-row"}>
                            <label>End Date</label>
                            <DatePicker
                                className={"form-control text-center"}
                                name={"fromDate"}
                                selected={this.state.endDate}
                                dateFormat="dd/MM/yyyy"
                                onChange={(date) => this.handleDateChange("endDate", date)} />
                        </div>
                    </div>

                    <div className={"col-12 text-right"}>
                        <span className={"btn btn-primary"} onClick={this.fetchReportFromNetwork}>Generate Report</span>
                    </div>
                </div>

                <div className={"row form-block"}>
                    <div className={"col-12"}>
                        {reportArea}
                    </div>
                </div>

                <AlertModal
                    open={ (this.state.modal != null) ? this.state.modal.open : false }
                    title={ (this.state.modal != null) ? this.state.modal.title : "" }
                    content={ (this.state.modal != null) ? this.state.modal.content : [] }
                    buttons={ (this.state.modal != null) ? this.state.modal.buttons : [] }
                    dismissHandler={ () => this.modalWasDismissed() } />

                <div className={"file-hide"}>
                    <a ref={this.exportDownloadElem} href={this.state.exportDownloadUrl} download={this.state.exportDownloadFilename}> </a>
                </div>
            </div>
        )
    }

}