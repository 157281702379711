import React from 'react';
import StatefulComponent from "../StatefulComponent";
import HeaderComponent from "../common/HeaderComponent";
import "../../assets/css/merchant-editor.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Axios from "axios";
import {API, ENDPOINTS} from "../../networking/API";
import AlertModal from "../common/AlertModal";
import {Link} from "react-router-dom";
import moment from "moment";

class HomeScreen extends StatefulComponent {

    constructor(props, context) {
        super(props, context);

        var user = localStorage.getItem("user")
        if (user != null) {
            user = JSON.parse(user);
        }

        this.initState({
            user : user,
            locations : [],
            selectedLocationIds : [],
            heroImageFile : null,
            logoImageFile : null,
            updatingMerchant : false,
            defaultLocationImageFile : null,
            textualLocation : ""
        });

        this.handleChange = this.handleChange.bind(this);

        this.getLocationsFromNetwork = this.getLocationsFromNetwork.bind(this);
        this.getLocationsFromNetworkForLAM = this.getLocationsFromNetworkForLAM.bind(this);
        this.handleLocationSelection = this.handleLocationSelection.bind(this);
        this.filterLocations = this.filterLocations.bind(this);
        this.clearLocationFilter = this.clearLocationFilter.bind(this);
        this.submitBranches = this.submitBranches.bind(this);
        this.fetchBranches = this.fetchBranches.bind(this);

        this.merchantHeroImageFile = React.createRef();
        this.merchantLogoImageFile = React.createRef();
        this.imageProgress = React.createRef();
        this.imageProgressContainer = React.createRef();
        this.defaultLocationImageFile = React.createRef();
    }



    componentDidMount() {
        super.componentDidMount();

        this.getMerchantFromNetwork();
        this.getPricePlanFromNetwork();
        this.getLocationsFromNetwork()

        if (this.state.user.merchantUserTypeId == API.USER_TYPES.ADMINISTRATOR) {
            this.getLocationsFromNetworkForLAM();
            this.fetchBranches();
        }
    }

    dismissClicked() {
        var context = this;
        this.setState({"modalSiteList":undefined})
    }

    selectedListWasClicked(sitelist) {
        var context = this;
        this.setState({"modalSiteList":sitelist})
    }


    fetchBranches() {
        var context = this;

            var formData = new FormData();

            Axios.post(ENDPOINTS.user.getBranches, formData)
                .then(function(response) {
                    var resp = API.parseResponse(response);
                    if (resp.success) {
                        context.setState({
                            selectedLocationIds : resp.data
                        });
                    } else {
                        console.log(resp.error);
                    }
                })
                .catch(function(err) {
                    console.log(err);
                });

    }

    submitBranches() {

        if (this.state.isUploading) return;

        var validationResult = this.validateCreateFormData(this.state, []);


        if (validationResult.success) {
            this.setState({
                isUploading : true
            });

            var formData = validationResult.formData;

            formData.append("locationIds", JSON.stringify(this.state.selectedLocationIds));

            var context = this;

            Axios.post(ENDPOINTS.user.saveBranches, formData)
                .then(function (response) {
                    var resp = API.parseResponse(response);

                    if (resp.success) {
                        alert("Branches saved successfully")
                        context.setState({
                            isUploading : false
                        });
                    } else {
                        console.log(resp.error);
                        context.showModal("Error", resp.error);

                        context.setState({
                            isUploading : false
                        });
                    }
                })
                .catch(function (err) {
                    console.log(err);
                    context.showModal("Error", "An unknown error has occurred. Please try again later. [2600]");

                    context.setState({
                        isUploading : false
                    });
                });
        } else {
            console.log("VALIDATION ERROR: " + validationResult.error);
            this.showModal("Error", validationResult.error);

            this.setState({
                isUploading : false
            });
        }
    }

    filterLocations(e) {
        this.handleChange(e);

        let target = e.target;
        let keyword = target.value;

        clearTimeout(this.locationFilterTimeout);
        this.locationFilterTimeout = setTimeout(() => {
            this.getLocationsFromNetworkForLAM(keyword);
        }, 700);
    }

    clearLocationFilter() {
        this.setState({
            "locationsFilter" : ""
        });
        this.getLocationsFromNetworkForLAM();
    }

    handleLocationSelection(event) {
        let locationId = event.target.value;

        let selectedLocationIds = this.state.selectedLocationIds;

        console.log("LocationID: " + locationId + " :: Checked? " + (event.target.checked ? "YES" : "NO"));

       if (event.target.checked) {

            // Add if it doesn't exist
            let shouldAdd = true;
            for (let i = 0; i < selectedLocationIds.length; i++) {
                if (selectedLocationIds[i] == locationId) {
                    shouldAdd = false;
                    break;
                }
            }

            if (shouldAdd) {
                selectedLocationIds.push(locationId);
            }
        } else {
            let idIndex = -1;

            for (let i = 0; i < selectedLocationIds.length; i++) {
                if (selectedLocationIds[i] == locationId) {
                    idIndex = i;
                    break;
                }
            }

            if (idIndex >= 0) {
                selectedLocationIds.splice(idIndex, 1);
            }
        }


       this.setState({
           selectedLocationIds : selectedLocationIds
       });

    }


    getLocationsFromNetworkForLAM(keyword) {
        if (this.state.fetchingLocations) return;

        this.setState({
            fetchingLocations : true
        });

        let formData = new FormData();
        formData.append("page", 1);
        formData.append("limit", 1000);

        if (keyword !== undefined) {
            if (keyword != null && keyword !== "") {
                formData.append("keyword", keyword);
            }
        }

        Axios.post(ENDPOINTS.location.getLocations, formData)
            .then((r) => {
                let newState = {
                    fetchingLocations : false
                };

                let resp = API.parseResponse(r);
                if (resp.success) {
                    console.log("LOCATIONS:");
                    console.log(resp.data);
                    newState.locations = resp.data.data;
                } else {
                    this.setState({
                        fetchingLocations : false
                    });
                    console.log(resp.error);
                }

                this.setState(newState);
            })
            .catch((e) => {
                console.log(e);

                this.setState({
                    fetchingLocations : false
                });
            });
    }

    getFormattedAddress() {
        var address = [];

        var addressParts = [
            "merchantAddressName",
            "merchantAddress1",
            "merchantAddress2",
            "merchantAddressCity",
            "merchantAddressCounty",
            "merchantAddressPostcode"
        ];

        var state = this.state;
        addressParts.forEach(function(key) {
           if (state[key] != null) {
               address.push(<div key={key}>{state[key]}</div>);
           }
        });

        return (address);
    }

    getMerchantFromNetwork() {
        let context = this;
        Axios.post(ENDPOINTS.merchant.get)
            .then(function(resp) {
                if (resp !== null) {
                    if (resp.data.success) {
                        if (resp.data.data !== null) {
                            context.merchantDidUpdate(resp.data.data);
                        }
                    }
                }
            })
            .catch(function(err) {
                console.log(err);
            });
    }

    updateMerchantOverNetwork() {
        let context = this;

        if (this.state.updatingMerchant) return;
        context.setState({
            updatingMerchant : true,
            merchantUpdatedSuccessfully : false
        });

        var requiredFields = [
            { key : "merchantName", label : "Merchant Name" },
            { key : "merchantAddressName", label : "Address Name" },
            { key : "merchantAddressPostcode", label : "Postcode" }
        ];

        var validated = true;

        var offendingElement = null;

        requiredFields.forEach(function(element) {
            if (context.state[element.key] != null) {
                if (context.state[element.key] === "") {
                    offendingElement = element.label;
                    validated = false;
                    return false;
                }
            } else {
                offendingElement = element.label;
                validated = false;
                return false;
            }
        });

        if (!validated) {
            context.showModal("Error", "Please provide a value for " + offendingElement, [ { label : "OK", style : "btn-default", click : context.hideModal } ]);

            context.setState({
                updatingMerchant : false
            });
            return;
        }

        var formData = new FormData();
        formData.append("name", this.state.merchantName);
        formData.append("addressName", this.state.merchantAddressName);
        formData.append("addressLine1", this.state.merchantAddress1);
        formData.append("addressLine2", this.state.merchantAddress2);
        formData.append("addressCity", this.state.merchantAddressCity);
        formData.append("addressCounty", this.state.merchantAddressCounty);
        formData.append("addressPostcode", this.state.merchantAddressPostcode);
        formData.append("email", this.state.merchantEmail);
        formData.append("phoneNumber", this.state.merchantNumber);
        formData.append("website", this.state.merchantWebsite);

        Axios.post(ENDPOINTS.merchant.update, formData)
            .then((response) => {
                context.setState({
                    updatingMerchant : false
                });

                var resp = API.parseResponse(response);
                if (resp.success) {
                    context.merchantResponse = resp.data;
                    context.handleImageUpload();
                } else {
                    context.showModal("Error", resp.error, [ { label : "OK", style : "btn-default", click : context.hideModal } ]);
                }
            })
            .catch ((err) => {
                context.setState({
                    updatingMerchant : false
                });

                console.log(err);
                this.showModal("Error", "An unknown error has occurred. [108]");
            });
    }

    getPricePlanFromNetwork() {
        Axios.get(ENDPOINTS.user.getPlanDetails)
            .then((r) => {
                let resp = API.parseResponse(r);
                if (resp.success) {
                    this.setState(resp.data);
                } else {
                    console.log(resp.error);
                }
            })
            .catch((e) => {
                console.log(e);
            });
    }

    handleImageUpload(index) {
        if (index === undefined) {
            this.imagesToUpload = [];

            if (this.state.heroImageFile != null) {
                this.imagesToUpload.push({
                    type : "hero",
                    file : this.state.heroImageFile
                });
            }

            if (this.state.logoImageFile != null) {
                this.imagesToUpload.push({
                    type : "logo",
                    file : this.state.logoImageFile
                });
            }

            if (this.state.defaultLocationImageFile != null) {
                this.imagesToUpload.push({
                    type : "location",
                    file : this.state.defaultLocationImageFile
                });
            }

            this.handleImageUpload(0);
        } else {
            if (index >= this.imagesToUpload.length) {
                this.imageProgressContainer.current.style.opacity = 0;

                console.log(this.merchantResponse);
                this.merchantDidUpdate(this.merchantResponse);

                this.setState({
                    merchantUpdatedSuccessfully : true
                });

                this.merchantResponse = null;
            } else {
                this.imageProgressContainer.current.style.opacity = 1;

                var imageData = this.imagesToUpload[index];
                this.uploadMerchantImage(imageData.type, imageData.file, index, this.imagesToUpload.length);
            }
        }
    }

    uploadMerchantImage(type, file, index, total) {
        let context = this;

        var formData = new FormData();
        formData.append("type", type);
        formData.append("image", file);

        Axios.post(ENDPOINTS.merchant.uploadImage, formData, {
                onUploadProgress : function(data) {
                    if (data.lengthComputable) {
                        var existingPercent = (index / total) * 100;
                        var percent = existingPercent + (data.loaded / data.total * 100) / total;
                        context.imageProgress.current.style.width = percent + "%";
                    }
                }
            })
            .then(function(response) {
                var resp = API.parseResponse(response);
                if (resp.success) {
                    context.merchantResponse = resp.data;
                    context.handleImageUpload(index + 1);
                } else {
                    context.showModal("Error", resp.error, [ { label : "OK", style : "btn-default", click : context.hideModal } ]);
                    console.log("IMAGE UPLOAD ERROR!");
                }
            })
            .catch(function(err) {
                context.showModal("Error", "An unknown error has occurred.", [ { label : "OK", style : "btn-default", click : context.hideModal } ]);
                console.log(err);
            });
    }

    merchantDidUpdate(merchant) {
        this.setState({
            merchantName : merchant.name,
            merchantAddressName : merchant.addressName,
            merchantAddress1 : merchant.addressLine1,
            merchantAddress2 : merchant.addressLine2,
            merchantAddressCity : merchant.addressCity,
            merchantAddressCounty : merchant.addressCounty,
            merchantAddressPostcode : merchant.addressPostcode,
            merchantEmail : merchant.email,
            merchantNumber : merchant.phoneNumber,
            merchantTypeId : merchant.merchantTypeId,
            merchantType : merchant.merchantType,
            merchantWebsite : merchant.website,
            merchantSlots : merchant.dealSlots,
            merchantLocations : merchant.locationSlots,
            merchantBillingAmount : merchant.billingAmount,
            merchantHeroImagePath : merchant.heroImagePath,
            merchantLogoImagePath : merchant.brandImagePath,
            merchantDefaultLocationImagePath : merchant.defaultLocImagePath
        });
    }

    uploadItemWasClicked(type) {
        if (type == "loc") {
            this.defaultLocationImageFile.current.click();
        } else if (type == "hero") {
            this.merchantHeroImageFile.current.click();
        } else if (type == "logo") {
            this.merchantLogoImageFile.current.click();
        }
    }

    getLocationsFromNetwork() {
        var context = this;

        Axios.get(ENDPOINTS.user.getLocations)
            .then(function(response) {
                let resp = API.parseResponse(response);
                if (resp.success) {
                        for (var c=0; c < resp.data.length; c++) {
                            if (resp.data[c].id == context.state.user.merchantLocationId) {
                                context.setState({"textualLocation" : resp.data[c].name })
                            }
                        }

                } else {
                    context.showModal("Error", resp.error);
                }
            })
            .catch(function(err) {
                console.log(err);
                context.showModal("Error", "An unknown error occurred. [103]");
            })
    }


    brandImageDidChange(e) {
        var target = e.target;

        if (target.files.length > 0) {
            var file = target.files[0];

            var context = this;

            var reader = new FileReader();
            reader.onload = function() {
                var state = {};

                if (target.id === "hero-image-file") {
                    context.state.heroImageFile = file;
                    state.merchantHeroImagePath = reader.result;
                } else if (target.id === "loc-image-file") {
                    context.state.defaultLocationImageFile = file;
                    state.merchantDefaultLocationImagePath = reader.result;
                } else {
                    context.state.logoImageFile = file;
                    state.merchantLogoImagePath = reader.result;
                }
                context.setState(state);
            };
            reader.readAsDataURL(file);
        }
    }

    passwordWasSet() {
        if (this.state.passwordIsChanging) return;

        var validationResult = this.validateCreateFormData(this.state, [
            {
                key : "currentPassword",
                label : "Current Password",
                postName : "currentPassword"
            },
            {
                key : "newPassword",
                label : "New Password",
                postName: "password"
            },
            {
                key : "newPasswordRetype",
                label : "New Password Confirmation",
                postName : "passwordRetype"
            }
        ]);

        if (!validationResult.success) {
            this.showModal("Error", validationResult.error);
            return;
        }

        if (this.state.newPassword != this.state.newPasswordRetype) {
            this.showModal("Error", "Your new password and the new password confirmation do not match. Please check and try again.");
            return;
        }

        this.setState({
            passwordIsChanging : true,
            passwordChangedSuccessfully : false
        });

        Axios.post(ENDPOINTS.user.changePassword, validationResult.formData)
            .then((r) => {
                let newState = {
                    passwordIsChanging : false
                };

                let resp = API.parseResponse(r);
                if (resp.success) {
                    newState.passwordChangedSuccessfully = true;
                    newState.currentPassword = "";
                    newState.newPassword = "";
                    newState.newPasswordRetype = "";
                } else {
                    this.showModal("Error", resp.error);
                }

                this.setState(newState);
            })
            .catch((e) => {
                this.showModal("Error", "An unknown error has occurred. [109]")

                this.setState({
                    passwordIsChanging : false,
                    passwordChangedSuccessfully : false
                });
            });
    }

    render() {
        var pricePlanElements = [];

        var pricingPlanDetails = [];

        if (this.state.pricePlan != null) {
            pricingPlanDetails.push(<div>
                <div className={"merchant-plan-summary"}>
                    <div className={"text"}>Your current Price Plan: <span className={"plan-name"}>{this.state.pricePlan.title}</span> <Link to={"/payment"} className={"button"}>Change</Link></div>
                </div>
            </div>);
        }

        if (this.state.dealSlots != null) {
            pricePlanElements.push(<div>
                <div className={"merchant-user-type"}>
                    <div className={"title"}>Merchant Type</div>
                    <div className={"value"}>{this.state.merchantType}</div>
                </div>

                {pricingPlanDetails}

                <div className={"merchant-billing-summary"}>
                    <div className={"summary-item"}>
                        <div className={"title"}>Deal Slots</div>
                        <div className={"value"}>{this.state.dealSlots}</div>
                    </div>

                    <div className={"summary-item"}>
                        <div className={"title"}>Location Slots</div>
                        <div className={"value"}>{this.state.locationSlots}</div>
                    </div>

                    <div className={"summary-item"}>
                        <div className={"title"}>Monthly Cost</div>
                        <div className={"value"}>{"£" + (this.state.billingAmount / 100).toFixed(2)}</div>
                    </div>
                </div>
            </div>);
        }

        var passwordChangeButton = [];
        if (!this.state.passwordIsChanging) {
            passwordChangeButton.push(<button className={"btn btn-primary"} onClick={() => this.passwordWasSet()}>Set Password</button>);
        } else {
            passwordChangeButton.push(<button className={"btn btn-primary"} disabled={true}>Changing Password...</button>)
        }

        var passwordChangeMessage = [];
        if (this.state.passwordChangedSuccessfully) {
            passwordChangeMessage.push(
                <span className={"update-success-message"}>Password Changed Successfully</span>
            );
        }

        var merchantSuccessMessage = [];
        if (this.state.merchantUpdatedSuccessfully) {
            merchantSuccessMessage.push(<span className={"update-success-message"}>Changes saved</span>);
        }

        let subscriptionElements = [];
        if (this.state.merchantTypeId != API.MERCHANT_TYPES.CORPORATE) {
            subscriptionElements = (
                <div className={"section-container"}>
                    <div className={"section-header"}>
                        <div className={"section-title"}>Subscription & Billing Details</div>
                    </div>

                    <div className={"section-content with-margin"}>
                        {pricePlanElements}
                    </div>
                </div>
            );
        }

        let merchantDetails = [];
        if (this.state.user.merchantUserTypeId == API.USER_TYPES.ADMINISTRATOR) {
            let websiteUrl = (this.state.merchantWebsite != null ? this.state.merchantWebsite : "");
            if (websiteUrl !== "") {
                websiteUrl = websiteUrl.replace("http://", "");
                websiteUrl = websiteUrl.replace("https://", "");
            }

            merchantDetails = (
                <div className={"section-container"}>
                    <div className={"section-header"}>
                        <div className={"section-title"}>Merchant details</div>
                    </div>

                    <div className={"section-content with-margin"}>
                        <div className={"row"}>
                            <div className={"col-xs-12 col-md-6"}>
                                <div className={"form-block"}>
                                    <label>Merchant Name</label>
                                    <input type={"text"} className={"form-control"} name={"merchantName"} placeholder={"Business Name"} value={(this.state.merchantName != null) ? this.state.merchantName : "" } onChange={this.handleChange} />
                                </div>

                                <div className={"form-block"}>
                                    <label>Address</label>
                                    <input type={"text"} className={"form-control"} name={"merchantAddressName"} placeholder={"Address Name"} onChange={this.handleChange} value={(this.state.merchantAddressName != null) ? this.state.merchantAddressName : "" } onChange={this.handleChange} readOnly={(this.state.user.merchantUserTypeId == API.USER_TYPES.ADMINISTRATOR) ? false : true } />
                                    <input type={"text"} className={"form-control form-block"} name={"merchantAddress1"} placeholder={"Address Line 1"} onChange={this.handleChange} value={(this.state.merchantAddress1 != null) ? this.state.merchantAddress1 : "" } onChange={this.handleChange} readOnly={(this.state.user.merchantUserTypeId == API.USER_TYPES.ADMINISTRATOR) ? false : true } />
                                    <input type={"text"} className={"form-control form-block"} name={"merchantAddress2"} placeholder={"Address Line 2"} onChange={this.handleChange} value={(this.state.merchantAddress2 != null) ? this.state.merchantAddress2 : "" } onChange={this.handleChange} readOnly={(this.state.user.merchantUserTypeId == API.USER_TYPES.ADMINISTRATOR) ? false : true } />
                                    <input type={"text"} className={"form-control form-block"} name={"merchantAddressCity"} placeholder={"Address City"} onChange={this.handleChange} value={(this.state.merchantAddressCity != null) ? this.state.merchantAddressCity : "" } onChange={this.handleChange} readOnly={(this.state.user.merchantUserTypeId == API.USER_TYPES.ADMINISTRATOR) ? false : true } />
                                    <input type={"text"} className={"form-control form-block"} name={"merchantAddressCounty"} placeholder={"Address County"} onChange={this.handleChange} value={(this.state.merchantAddressCounty != null) ? this.state.merchantAddressCounty : "" } onChange={this.handleChange} readOnly={(this.state.user.merchantUserTypeId == API.USER_TYPES.ADMINISTRATOR) ? false : true } />
                                    <input type={"text"} className={"form-control form-block"} name={"merchantAddressPostcode"} placeholder={"Postcode"} onChange={this.handleChange} value={(this.state.merchantAddressPostcode != null) ? this.state.merchantAddressPostcode : "" } onChange={this.handleChange} readOnly={(this.state.user.merchantUserTypeId == API.USER_TYPES.ADMINISTRATOR) ? false : true } />
                                </div>

                                <div className={"form-block"}>
                                    <label>Email</label>
                                    <input type={"text"} className={"form-control"} name={"merchantEmail"} placeholder={"Contact Email (publicly visible)"} onChange={this.handleChange} value={(this.state.merchantEmail != null) ? this.state.merchantEmail : "" } onChange={this.handleChange} readOnly={(this.state.user.merchantUserTypeId == API.USER_TYPES.ADMINISTRATOR) ? false : true }  />
                                </div>

                                <div className={"form-block"}>
                                    <label>Phone Number</label>
                                    <input type={"text"} className={"form-control"} name={"merchantNumber"} placeholder={"Phone Number (publicly visible)"} onChange={this.handleChange} value={(this.state.merchantNumber != null) ? this.state.merchantNumber : "" } onChange={this.handleChange} readOnly={(this.state.user.merchantUserTypeId == API.USER_TYPES.ADMINISTRATOR) ? false : true }  />
                                </div>

                                <div className={"form-block"}>
                                    <label>Website</label>
                                    <input type={"text"} className={"form-control"} name={"merchantWebsite"} placeholder={"Website"} onChange={this.handleChange} value={(this.state.merchantWebsite != null) ? this.state.merchantWebsite : "" } onChange={this.handleChange} readOnly={(this.state.user.merchantUserTypeId == API.USER_TYPES.ADMINISTRATOR) ? false : true }  />
                                </div>
                                <div className={"row form-block"}>
                                    <div className={"col-12"}>
                                        <label className={"no-margin"}>Default Location Header Image</label>
                                        <div className={"image-selection recess-area form-block"}>
                                            <div className={"row"}>
                                                <div className={"col-12 col-md-6 image-container"}>
                                                    <div className={"image-preview"} ref={this.imagePreview} style={{ backgroundImage : (this.state.merchantDefaultLocationImagePath != null) ? "url(" + this.state.merchantDefaultLocationImagePath + ")" : "" }} />
                                                </div>
                                                {(this.state.user.merchantUserTypeId == API.USER_TYPES.ADMINISTRATOR) &&
                                                    <div className={"col-12 col-md-6 text-center"}>
                                                    <div className={"image-filename"}>{ (this.state.merchantDefaultLocationImagePath != null) ? "Click 'Select Image' to change default image"  : "No default image" }</div>
                                                    <div className={"form-block"}>
                                                        <div className={"input-hide"}>
                                                            <input type={"file"} id={"loc-image-file"} ref={this.defaultLocationImageFile} onChange={(e) => this.brandImageDidChange(e) } />
                                                        </div>
                                                        <span className={"btn btn-primary"} onClick={() => this.uploadItemWasClicked("loc")}>Select Image</span>
                                                    </div>


                                                </div>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className={"col-xs-12 col-md-6"}>
                                <div className={"text-center"}>
                                    <label>In-App Preview</label>
                                </div>

                                <div className={"image-hint"}>
                                    <div><strong>Important</strong></div>
                                    <div className={"line"}>Banner Images (large image) should be uploaded as a 16:9 image. You should not include text in this image. Images that are too tall or too wide will be cropped to fit inside the area. Common 16:9 resolutions: 1920x1080px, 1240x720px</div>
                                    <div className={"line"}>Logo Images (small, round image) should be uploaded as a square image. This image will be displayed within a circle, include padding to push important content towards the centre and away from edges. Wide or tall images will be cropped.</div>
                                </div>

                                <div className={"merchant-user-panel"}>
                                    <div className={"merchant-hero-image"} style={{ backgroundImage : "url('" + (this.state.merchantHeroImagePath != null ? this.state.merchantHeroImagePath : "") + "')" }} onClick={() => this.uploadItemWasClicked("hero")}>
                                        <div className={"input-hide"}>
                                            <input type={"file"} id={"hero-image-file"} ref={this.merchantHeroImageFile} onChange={(e) => this.brandImageDidChange(e) } />
                                        </div>
                                        <div className={"overlay"}>
                                            <div className={"vertical-space"} />
                                            <div className={"icon"}>
                                                <FontAwesomeIcon icon="upload" />
                                            </div>
                                            <div className={"text"}>Upload image</div>
                                            <div className={"vertical-space"} />
                                        </div>
                                    </div>
                                    <div className={"merchant-info-area"}>
                                        <div className={"merchant-logo"} style={{ backgroundImage : "url('" + (this.state.merchantLogoImagePath != null ? this.state.merchantLogoImagePath : "") + "')" }} onClick={() => this.uploadItemWasClicked("logo")}>
                                            <div className={"input-hide"}>
                                                <input type={"file"} id={"logo-image-file"} ref={this.merchantLogoImageFile} onChange={(e) => this.brandImageDidChange(e) } />
                                            </div>
                                            <div className={"overlay"}>
                                                <div className={"vertical-space"} />
                                                <div className={"icon"}>
                                                    <FontAwesomeIcon icon={"upload"} />
                                                </div>
                                                <div className={"text"}>Change</div>
                                                <div className={"vertical-space"} />
                                            </div>

                                        </div>
                                        <div className={"merchant-name"}>{ (this.state.merchantName != null ? this.state.merchantName : "") }</div>
                                        <div className={"merchant-section-title"}>Contact Details</div>
                                        <div className={"merchant-section-container"}>
                                            <div className={"merchant-section-half"}>
                                                <div className={"item-row"}>
                                                    <strong>Address</strong>
                                                    <div className={"item-content"}>
                                                        { this.getFormattedAddress() }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={"merchant-section-half"}>
                                                <div className={"item-row"}>
                                                    <strong>Email</strong>
                                                    <div className={"item-content"}>{ (this.state.merchantEmail != null ? this.state.merchantEmail : "") }</div>
                                                </div>
                                                <div className={"item-row"}>
                                                    <strong>Phone Number</strong>
                                                    <div className={"item-content"}>{ (this.state.merchantNumber != null ? this.state.merchantNumber : "") }</div>
                                                </div>
                                                <div className={"item-row"}>
                                                    <strong>Website</strong>
                                                    <div className={"item-content"}>{ websiteUrl }</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div className={"row form-block"}>
                            <div className={"col-6 col-md-3"}>
                                <div className={"progress section-progress"} ref={this.imageProgressContainer} style={{opacity:0}}>
                                    <div className={"progress-bar progress-bar-striped progress-bar-animated"} ref={this.imageProgress} />
                                </div>
                            </div>
                            <div className={"col-6 col-md-3 text-right"}>
                                {merchantSuccessMessage}
                                {(this.state.user.merchantUserTypeId == API.USER_TYPES.ADMINISTRATOR) &&
                                <span className={"btn btn-success"}
                                      onClick={() => this.updateMerchantOverNetwork()}>{(!this.state.updatingMerchant) ? "Save" : "Saving..."}</span>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        if (this.state.user.merchantUserTypeId == API.USER_TYPES.ADMINISTRATOR) {

            var locationElems = [];
            var selectedSites = "No branches selected"
            var allSelectedSites = "No branches selected"
            var displayElems = [];
            var activeSites = [];

            if (this.state.locations != null) {
                for (var i = 0; i < this.state.locations.length; i++) {
                    let location = this.state.locations[i];

                    let isChecked = false;
                    if (this.state.selectedLocationIds != null) {
                        for (var x = 0; x < this.state.selectedLocationIds.length; x++) {
                            if (this.state.selectedLocationIds[x] == location.id) {
                                isChecked = true;
                                break;
                            }
                        }
                    }

                    locationElems.push(<div className={"col-xs-12 col-md-6"}>
                        <label className={"location-selection"}>
                        <span className={"check"}>
                            <input type={"checkbox"} value={location.id} onChange={this.handleLocationSelection}
                                   checked={isChecked}/>
                        </span>
                            <span className={"content"}>
                            <span className={"title"}>{location.name}</span>
                            <span
                                className={"subtitle"}>{location.addressName} ({location.latitude}, {location.longitude})</span>
                        </span>
                        </label>
                    </div>);
                    if (isChecked) {
                        activeSites.push(location.name)
                    }
                }
                if (activeSites.length == 0) {
                    selectedSites = <span>No branches selected</span>
                    allSelectedSites = <span>No branches selected</span>
                } else {


                    allSelectedSites = activeSites.map((row, c) => {

                        if (row === "Click for more..."){
                            return <span>{row} </span>
                        } else {
                            return <span className={"site-in-list"}>{row.replace(/\s/g, '\u00A0')} </span>
                        }

                    })

                    if (activeSites.length > 8) {
                        activeSites = activeSites.slice(0, 8)
                        activeSites.push("Click for more...")
                    }

                    selectedSites = activeSites.map((row, c) => {

                        if (row === "Click for more..."){
                            return <span>{row} </span>
                        } else {
                            return <span className={"site-in-list"}>{row.replace(/\s/g, '\u00A0')} </span>
                        }

                    })
                }
            }

            if (locationElems.length > 0) {
                displayElems = locationElems;
            } else {
                displayElems.push(<div className={"col-xs-12"} style={{margin: "20px"}}>
                    No matches found.
                </div>)
            }
        }

        return (
            <div className={"container"}>
                <HeaderComponent />
                <div className={"page-header"}>
                    <div className={"page-title"}>Account Details</div>
                    <div className={"page-actions"}>
                        <span className={"btn btn-danger"} onClick={this.props.logoutCallback}>Sign Out</span>
                    </div>
                </div>

                { /* Merchant Details */ }
                {merchantDetails}

                { /* Subscription & Billing Details */ }
                {subscriptionElements}

                { /* User Details */ }
                <div className={"section-container"}>
                    <div className={"section-header"}>
                        <div className={"section-title"}>User Details</div>
                    </div>

                    <div className={"section-content with-margin"}>
                        <div className={"row"}>
                            <div className={"col-xs-12 col-md-6"}>
                                <label>First Name</label>
                                <input type={"text"} className={"form-control"} value={this.state.user.name} readOnly={true} />
                            </div>

                            <div className={"col-xs-12 col-md-6"}>
                                <label>Email</label>
                                <input type={"text"} className={"form-control"} value={this.state.user.email} readOnly={true} />
                            </div>
                        </div>
                        <div className={"row form-block"}>
                            <div className={"col-xs-12 col-md-6"}>
                                <label>User Type</label>
                                <input type={"text"} className={"form-control"} value={this.state.user.merchantUserType} readOnly={true} />
                            </div>
                                <div className={"col-xs-12 col-md-6"}>
                                    <label>Location</label>
                                    <input type={"text"} className={"form-control"} value={this.state.textualLocation} readOnly={true} />
                                </div>
                        </div>

                        <div className={"row form-block"}>
                            <div className={"col-12"}>
                                <div className={"recess-area"}>
                                    <div className={"row"}>
                                        <div className={"col-12"}>
                                            <div><strong>Change Password</strong></div>
                                            <p>Provide a new password for your account below.</p>
                                        </div>
                                    </div>

                                    <div className={"row"}>
                                        <div className={"col-12 col-md-6"}>
                                            <label>Current Password</label>
                                            <input type={"password"} className={"form-control"} name={"currentPassword"} placeholder={"Your current password"} onChange={this.handleChange} />
                                        </div>
                                    </div>

                                    <div className={"row form-block"}>
                                        <div className={"col-12 col-md-6"}>
                                            <label>New Password</label>
                                            <input type={"password"} className={"form-control"} name={"newPassword"} placeholder={"Your new password"} onChange={(e) => this.handleChange(e)}/>
                                        </div>
                                        <div className={"col-12 col-md-6"}>
                                            <label>Retype New Password</label>
                                            <input type={"password"} className={"form-control"} name={"newPasswordRetype"} placeholder={"Repeat new password"} onChange={(e) => this.handleChange(e)}/>
                                        </div>
                                        <div className={"col-12 text-right form-block"}>
                                            {passwordChangeMessage} {passwordChangeButton}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {(this.state.user.merchantUserTypeId == API.USER_TYPES.ADMINISTRATOR) &&

                        <div className={"row form-block"}>
                            <div className={"col-12"}>
                                <label className={"no-margin"}>My Branch Locations</label>
                                <div className={"small-label"}>Locations selected here will automatically be applied when you create a new deal</div>
                            </div>

                            <div className={"col-12"}>
                                <div className={"location-selection-container"}>
                                    <div className={"location-selection-filter"}>

                                        <div className={"title"} style={{marginLeft:"10px"}}>Selected:</div>
                                        <div className={"text_elips"} onClick={() => this.selectedListWasClicked(allSelectedSites)}>
                                            {selectedSites}
                                        </div>
                                        <div className="spacer"/>

                                        <div className={"input-area"}>
                                            <div className={"input-group"} >
                                                <input type={"text"} className={"form-control"}
                                                       placeholder={"Filter Locations..."} name={"locationsFilter"}
                                                       onChange={this.filterLocations} value={this.state.locationsFilter}/>
                                                <div className="input-group-append">
                                                <span className="input-group-text"
                                                      onClick={this.clearLocationFilter}>Clear</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className={"location-selection-scroller"}>
                                        <div className={"row"}>
                                            {displayElems}
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                        }
                        {(this.state.user.merchantUserTypeId == API.USER_TYPES.ADMINISTRATOR) &&

                        <div className={"row form-block"}>
                            <div className={"col-12 text-center"}>
                                <div className={"submission-buttons"}
                                     style={{display: (!this.state.isUploading) ? "block" : "none"}}>
                                    <div><span className={"btn btn-success"}
                                               onClick={this.submitBranches}>{"Save Branches"}</span></div>
                                </div>
                                <div className={"upload-progress-area"}
                                     style={{display: (this.state.isUploading) ? "block" : "none"}}>
                                    <span className={"upload-notice-label"}>Saving, please wait...</span>
                                    <div className={"progress"}>
                                        <div className={"progress-bar progress-bar-striped progress-bar-animated"}
                                             style={{width: this.state.uploadProgressPercent + "%"}}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        }
                    </div>
                </div>

                {this.state.modalSiteList && <div className="modalz">

                    <div className="modal-contentz">

                        <div className={"col-12 text-center"}>
                            <span className={"close"} onClick={() => this.dismissClicked() }>X</span>

                            <h3>Selected branches:</h3>
                        </div>
                        <div className={"col-12 text-center"}>
                        </div>
                        <div className={"col-12 text-center"}>
                            {this.state.modalSiteList}
                        </div>

                    </div></div>}

                <AlertModal
                    open={ (this.state.modal != null) ? this.state.modal.open : false }
                    title={ (this.state.modal != null) ? this.state.modal.title : "" }
                    content={ (this.state.modal != null) ? this.state.modal.content : [] }
                    buttons={ (this.state.modal != null) ? this.state.modal.buttons : [] }
                    dismissHandler={ () => this.modalWasDismissed() }
                />


            </div>
        )
    }

}

export default HomeScreen;