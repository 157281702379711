import React from "react";
import StatefulComponent from "../StatefulComponent";
import {API, ENDPOINTS} from "../../networking/API";
import Axios from "axios";
import '../../assets/css/login.css';
import AlertModal from "../common/AlertModal";
import {Link} from "react-router-dom";

class PasswordResetRequestScreen extends StatefulComponent {

    constructor(props, context) {
        super(props, context);

        this.initState({
            email : ""
        });

        this.loginBox = React.createRef();

        this.requestWasSubmitted = this.requestWasSubmitted.bind(this);
    }

    componentDidMount() {
        super.componentDidMount();

        this.alignLoginBox();
    }

    updateWindowDimensions() {
        super.updateWindowDimensions();

        this.alignLoginBox();
    }

    alignLoginBox() {
        var loginBoxStyle = window.getComputedStyle(this.loginBox.current, null);

        var marginTop = (window.innerHeight - parseInt(loginBoxStyle.getPropertyValue("height"))) / 2;
        marginTop = Math.max(0, marginTop);

        this.loginBox.current.style.marginTop = marginTop + "px";
    }

    requestWasSubmitted() {
        if (this.state.networkInFlight) return;

        var validationResult = this.validateCreateFormData(this.state, [
            {
                key : "email",
                label : "Email Address",
                postName : "email"
            }
        ]);

        if (validationResult.success) {
            this.setState({
                networkInFlight : true
            });

            Axios.post(ENDPOINTS.login.requestPasswordReset, validationResult.formData)
                .then((response) => {
                    let resp = API.parseResponse(response);
                    if (resp.success) {
                        this.setState({
                            requestSuccessful : true,
                            networkInFlight : false

                        });
                    } else {
                        this.setState({
                            requestSuccessful : false,
                            networkInFlight : false
                        });

                        this.showModal("Error", resp.error);
                    }
                })
                .catch((err) => {
                    this.setState({
                        requestSuccessful : false,
                        networkInFlight : false
                    });

                    this.showModal("Error", "An unknown error occurred. [900]");
                });
        } else {
            this.showModal("Error", validationResult.error);
        }
    }

    render() {
        var message = [];

        if (this.state.requestSuccessful) {
            message.push(
                <div className={"form-block"}>
                    <div className="alert alert-success">Successfully submitted request. If the email exists in our system, you will soon receive an email at the specified email address with more information. If you do not receive an email, please check your Trash, Junk or Spam directories.</div>
                </div>
            );
        }

        return (
            <div className="container">
                <div className="row">
                    <div className="hidden-xs col-md-2 col-lg-3 col-xl-4" />
                    <div className="col-xs-12 col-md-8 col-lg-9 col-xl-4">
                        <div className="login-box" ref={this.loginBox}>
                            <div className="form-block text-center">
                                <img src={require("../../assets/img/gas-app-logo-blue.png")} alt="Gas App Uk&reg;" className="logo" />
                            </div>

                            <div className="form-block text-center">
                                <strong>Password Reset</strong>
                            </div>

                            { message }

                            <div className="form-block">
                                <label>Email Address</label><br />
                                <input type="text" name="email" placeholder="Email" className="form-control" value={this.state.email} onChange={this.handleChange} />
                            </div>

                            <div className="form-block text-centre">
                                <button className="btn btn-success" onClick={ () => this.requestWasSubmitted() }>Request Password Reset</button>
                            </div>

                            <div className="form-block text-centre">
                                <Link to={"/"}>Return to Login</Link>
                            </div>
                        </div>
                    </div>
                </div>

                <AlertModal
                    open={ (this.state.modal != null) ? this.state.modal.open : false }
                    title={ (this.state.modal != null) ? this.state.modal.title : "" }
                    content={ (this.state.modal != null) ? this.state.modal.content : [] }
                    buttons={ (this.state.modal != null) ? this.state.modal.buttons : [] }
                    dismissHandler={ () => this.modalWasDismissed() }
                />
            </div>
        );
    }

}

export default PasswordResetRequestScreen;