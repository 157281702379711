import React from "react";
import HeaderComponent from "../common/HeaderComponent";
import SegmentedButtonComponent from "../common/SegmentedButtonComponent";
import StatefulComponent from "../StatefulComponent";
import '../../assets/css/deal-list-screen.css';
import Axios from "axios";
import {API, ENDPOINTS} from "../../networking/API";
import DealListComponent from "./DealListComponent";
import RemainingCountComponent from "../common/RemainingCountComponent";
import TableComponent, {Column, TablePaginator} from "../common/TableComponent";
import moment from "moment";
import AlertModal from "../common/AlertModal";
import SelectSearch from "react-select-search"

class DealListScreenComponent extends StatefulComponent {

    DEAL_VIEW_GRID = 1;
    DEAL_VIEW_LIST = 2;

    DEAL_TYPE_ACTIVE = 1;
    DEAL_TYPE_INACTIVE = 2;

    filterKeywordTimeout = undefined;
    locTimeout = undefined;


    constructor(props, context) {
        super(props, context);


        var user = localStorage.getItem("user")
        if (user != null) {
            user = JSON.parse(user);
        }

        this.initState({
            segmentedSelectedId : this.DEAL_TYPE_ACTIVE,
            dealData : null,
            merchant : null,
            dealTotal : 0,
            dealsUsedCount : 0,
            dealsTotalCount : 0,
            dealsAvailableCount: 0,
            networkInFlight : false,
            user : user,
            viewMode : this.DEAL_VIEW_LIST,
            tableLimit : 25, // 25 deals per page,
            sortedColumn : {name : "endDate", direction : "asc"},
            modal : null,
            locations: [],
            selectedLoc : undefined
        });

        // Current deals page
        this.currentPage = 1;

        // Current deals retrieve mode
        this.currentMode = this.DEAL_TYPE_ACTIVE;

        // Create Segmented Control
        this.segmentedButtons = [
            { id : this.DEAL_TYPE_ACTIVE, label : "Active Deals" },
            { id : this.DEAL_TYPE_INACTIVE, label : "Finished Deals" }
        ];
        this.segmentedButtonsDidChange = this.segmentedButtonsDidChange.bind(this);
        this.locDidChange = this.locDidChange.bind(this);
        this.networkDataDidChange = this.networkDataDidChange.bind(this);
        this.changePage = this.changePage.bind(this);

    }

    componentDidMount() {
        super.componentDidMount()

        this.fetchDealsFromNetwork(this.state.segmentedSelectedId, this.state.selectedLoc)
        this.fetchDealCountsFromNetwork()
        this.getLocationsFromNetwork()
    }

    segmentedButtonsDidChange(id) {
        if (id !== this.state.segmentedSelectedId) {
            this.currentPage = 1;
            console.log("Current Page: " + this.currentPage);

            this.setState({
                segmentedSelectedId: id,
                dealData : [],
                hasMore : true
            });

            this.fetchDealsFromNetwork(id, this.state.selectedLoc);
        }
    }

    changePage(newPage) {
        if (this.state.networkInFlight) return;

        this.currentPage++;
        console.log("NEXT PAGE: " + this.currentPage);
        this.fetchDealsFromNetwork(this.state.segmentedSelectedId, this.state.selectedLoc);
    }

    getLocationsFromNetwork() {
        if (this.state.fetchingLocations) return;

        this.setState({
            fetchingLocations : true
        });

        let formData = new FormData();
        formData.append("page", 1);
        formData.append("limit", 1000);

        Axios.post(ENDPOINTS.location.getLocations, formData)
            .then((r) => {
                let newState = {
                    fetchingLocations : false
                };

                let resp = API.parseResponse(r);
                if (resp.success) {
                    console.log("LOCATIONS:");
                    console.log(resp.data);
                    newState.locations =  resp.data.data.map((row, c) => {

                        return {"name": row.name, "value": row.id}

                    })
                    newState.locations = [{"name":"All Branches", "value" : "all" }].concat(newState.locations);

                } else {
                    this.setState({
                        fetchingLocations : false
                    });
                    console.log(resp.error);
                }

                this.setState(newState);
            })
            .catch((e) => {
                console.log(e);

                this.setState({
                    fetchingLocations : false
                });
            });
    }


    fetchDealsFromNetwork(type, branch, sort) {
        if (this.state.networkInFlight) return;
        this.setState({
            networkInFlight : true
        });

        if (sort === undefined) {
            sort = this.state.sortedColumn;
        }

        var formData = new FormData();
        formData.append("page", this.currentPage)
        formData.append("type", type);
        if (branch !== undefined    ){
            formData.append("branch", branch);
        }
        formData.append("sort", JSON.stringify(sort));

        if (this.state.keyword != null && this.state.keyword !== "") {
            formData.append("keyword", this.state.keyword);
        }

        var context = this;

        Axios.post(ENDPOINTS.deal.getList, formData)
            .then(function(response) {
                context.setState({
                    networkInFlight : false
                });

                var resp = API.parseResponse(response);
                if (resp.success) {
                    if (resp.data != null) {
                        context.networkDataDidChange(resp.data);
                    }
                } else {
                    console.log(resp.error);
                }
            })
            .catch(function(err) {
                // TODO Error
                console.log(err);
                context.setState({
                    networkInFlight : false
                });
            });
    }

    fetchDealCountsFromNetwork() {
        var context = this;

        Axios.get(ENDPOINTS.deal.getCounts)
            .then(function(response) {
                var resp = API.parseResponse(response);
                if (resp.success) {
                    context.setState({
                        dealsAvailableCount : resp.data.remainingDealSlots,
                        dealsUsedCount : resp.data.activeDealCount,
                        dealsTotalCount : resp.data.maximumDealCount
                    });
                } else {
                    console.log("ERROR FETCHING COUNTS");
                    console.log(resp.error);
                }
            })
            .catch(function(err) {
                console.log("ERROR FETCHING COUNTS");
                console.log(err);
            });
    }

    networkDataDidChange(data) {
        let hasMore = data.deals.length > 0;
        let dealData = null;
        if (this.state.viewMode === this.DEAL_VIEW_GRID) {
            dealData = this.state.dealData
        }
        if (dealData == null) {
            dealData = [];
        }
        if (hasMore) {
            for (var i = 0; i < data.deals.length; i++) {
                dealData.push(data.deals[i]);
            }
        }

        this.setState({
            dealData : dealData,
            dealTotal : data.total,
            merchant : data.merchant,
            hasMore : hasMore
        });

        localStorage.setItem("disclaimer", data.disclaimer);

    }

    dealItemWasClicked(id) {
        window.location.href = "/deals/" + id;
    }

    statsClicked() {
        window.location.href = "/deals/stats/";
    }


    tableDidSort = (sort) => {
        this.currentPage = 1;
        this.fetchDealsFromNetwork(this.state.segmentedSelectedId, this.state.selectedLoc, sort);

        this.setState({
            sortedColumn : sort
        });
    }

    tableRowWasClicked = (row) => {
        this.dealItemWasClicked(row.id);
    }

    tablePageDidChange = (page) => {
        this.currentPage = page;

        this.fetchDealsFromNetwork(this.state.segmentedSelectedId, this.state.selectedLoc);
    }

    filterDidChange = (event) => {
        let keyword = event.target.value;

        clearTimeout(this.filterKeywordTimeout);
        this.filterKeywordTimeout = setTimeout(() => {
            this.currentPage = 1;
            this.fetchDealsFromNetwork(this.state.segmentedSelectedId, this.state.selectedLoc);
        }, 800);

        this.handleChange(event);
    }


    locDidChange(event) {
        if (event === "all") {
            event = undefined
        }
        this.setState({
            selectedLoc: event
        });

        clearTimeout(this.locTimeout);
        this.locTimeout = setTimeout(() => {
            this.currentPage = 1;
            this.fetchDealsFromNetwork(this.state.segmentedSelectedId, this.state.selectedLoc);
        }, 800);

       // this.handleChange(event);
    }

    tableDeleteWasClicked = (event, row) => {
        event.stopPropagation();
        event.preventDefault();

        console.log("ROW?");
        console.log(row);

        this.showModal("Cancel Deal", "Are you sure you want to Cancel this deal? This action cannot be undone.", [
            { label : "Cancel Deal", style : "btn-danger", click : () => { this.endDeal(row.id); this.hideModal(); }},
            { label : "Do not Cancel", style: "btn-default", click : () => {this.hideModal()}}
        ]);
    }

    endDeal = (id) => {
        console.log("END DEAL");
        console.log(id);

        var formData = new FormData();
        formData.append("massiveDealId", id);

        Axios.post(ENDPOINTS.deal.cancel, formData)
            .then((r) => {
                let resp = API.parseResponse(r);
                if (resp.success) {
                    this.fetchDealsFromNetwork(this.state.segmentedSelectedId, this.state.selectedLoc);
                } else {
                    this.showModal("Error", resp.error);
                }
            })
            .catch((e) => {
                this.showModal("Error", "An unknown error has occurred. Please try again later.");
            });
    }

    render() {
        let remainingCountElem = [];
        console.log(this.state.user.merchantTypeId);
        if (this.state.user.merchantTypeId == API.MERCHANT_TYPES.CORPORATE) {
            remainingCountElem = (
                <RemainingCountComponent
                    total={this.state.dealTotal}
                    suffix={"Deals"} />
            );
        } else {
            remainingCountElem = (
                <RemainingCountComponent
                    remaining={this.state.dealsAvailableCount}
                    total={this.state.dealsTotalCount}
                    suffix={"Deals remaining"} />
            );
        }

        let dealView = [];
        if (this.state.viewMode === this.DEAL_VIEW_GRID) {
            dealView = (
                <DealListComponent
                    items={this.state.dealData}
                    mode={this.state.segmentedSelectedId}
                    clickHandler={(id) => this.dealItemWasClicked(id) }
                    loadMore={(page) => this.changePage(page)}
                    isLoading={this.state.networkInFlight}
                    hasMore={this.state.hasMore && !this.state.networkInFlight} />
            );
        } else {
            dealView = (
                <div style={{marginTop : "10px"}}>
                    <TableComponent
                        className={"alternating clickable"}
                        data={this.state.dealData}
                        onRowClick={(row) => { this.tableRowWasClicked(row);}}
                        onSort={(sort) => { this.tableDidSort(sort) }}
                        sortedColumn={this.state.sortedColumn}>
                        <Column name={"title"} title={"Title"} sortable={true} />
                        <Column name={"longDescription"} title={"Product Description"} sortable={true} />
                        <Column name={"startDate"} title={"Start"} className={"text-center"} sortable={true} render={(column) => {
                            return moment(column).format("DD/MM/YYYY");
                        }} />
                        <Column name={"endDate"} title={"End"} className={"text-center"} sortable={true} render={(column) => {
                            return moment(column).format("DD/MM/YYYY");
                        }} />
                        <Column name={"impressions"} title={"Impressions"} className={"text-center"} sortable={true} />
                        <Column name={"clicks"} title={"Clicks"} className={"text-center"} sortable={true} />
                        <Column name={"id"} title={""}  className={"text-center"} render={(column, row) => {
                            if (this.state.segmentedSelectedId === this.DEAL_TYPE_ACTIVE) {
                                return (
                                    <span className={"btn btn-danger"} onClick={(e) => {
                                        this.tableDeleteWasClicked(e, row)
                                    }}>Cancel Deal</span>
                                );
                            } else {
                                return (
                                    <span className={"btn btn-warning disabled"}>Deal ended</span>
                                )
                            }
                        }} />
                    </TableComponent>

                    <div className={"form-block ep-table-paginate-container"}>
                        <div className={"push"} />
                        <TablePaginator
                            page={this.currentPage}
                            totalCount={this.state.dealTotal}
                            pageSize={this.state.tableLimit}
                            onClick={(page) => this.tablePageDidChange(page)} />
                    </div>
                </div>
            );
        }



        return (
            <div className={"container"}>
                <HeaderComponent />

                <div className={"page-header"}>
                    <div className={"page-title"}>Deals</div>
                    <div className={"page-actions"}>
                        {remainingCountElem}
                        {(this.state.user.merchantUserTypeId === API.USER_TYPES.ADMINISTRATOR) && <button className={"btn btn-success"} onClick={() => this.statsClicked()} >Total Impressions</button>}
                        <button className={"btn btn-success"} onClick={() => this.dealItemWasClicked("new")} disabled={ this.state.dealsAvailableCount <= 0 }>Add a new Deal</button>
                    </div>
                </div>

                <div className={"row"}>
                    <div className={"col-12 col-md-4 filter-area"}>
                        <label>&nbsp;</label>
                        <SegmentedButtonComponent buttons={this.segmentedButtons} changeHandler={this.segmentedButtonsDidChange} selectedId={this.state.segmentedSelectedId} />
                    </div>

                    <div className={"col-12 col-md-4 filter-area"}>
                        <label htmlFor={"dealFilter"}>Search</label>
                        <input type={"text"} className={"form-control"} name={"keyword"} value={this.state.keyword} onChange={this.filterDidChange} placeholder={"Search for Deals"} />
                    </div>
                    <div className={"col-12 col-md-4 filter-area"}>
                        <label htmlFor={"dealFilter"}>Branch</label>
                        <SelectSearch
                                options={this.state.locations}
                                search
                                onChange={this.locDidChange}
                                name={"selectedLoc"}
                                value={ this.state.selectedLoc}
                                //filterOptions={fuzzySearch}
                                placeholder="Select a branch (optional)"
                            />
                    </div>

                </div>

                <div className={"row"}>
                    <div className={"col-12"}>
                        {dealView}
                    </div>
                </div>

                <AlertModal
                    open={ (this.state.modal != null) ? this.state.modal.open : false }
                    title={ (this.state.modal != null) ? this.state.modal.title : "" }
                    content={ (this.state.modal != null) ? this.state.modal.content : [] }
                    buttons={ (this.state.modal != null) ? this.state.modal.buttons : [] }
                    dismissHandler={ () => this.modalWasDismissed() }
                />
            </div>
        );
    }
}

export default DealListScreenComponent;