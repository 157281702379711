import React from "react";

import StatefulComponent from "../StatefulComponent";
import {API, ENDPOINTS} from "../../networking/API";
import RemainingCountComponent from "../common/RemainingCountComponent";
import DealListComponent from "./DealListComponent";
import TableComponent, {Column, TablePaginator} from "../common/TableComponent";
import moment from "moment";
import HeaderComponent from "../common/HeaderComponent";
import AlertModal from "../common/AlertModal";
import Axios from "axios";
import DatePicker from "react-datepicker/es";
import SegmentedButtonComponent from "../common/SegmentedButtonComponent";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";


class DealStatsScreenComponent extends StatefulComponent {

    QUICK_60_DAYS = 1;
    QUICK_90_DAYS = 2;
    QUICK_12_MONTHS = 3;

    constructor(props, context) {
        super(props, context);


        this.initState({
            impressions : "...loading",
            quickSelectID : this.QUICK_60_DAYS,
            toDate : moment().toDate(),
            fromDate : moment().subtract(60, "days").toDate()

    });

        this.handleDateChange = this.handleDateChange.bind(this);
        this.segmentedButtonsDidChange = this.segmentedButtonsDidChange.bind(this);

        this.segmentedButtons = [
            { id : this.QUICK_60_DAYS, label : "Last 60 Days" },
            { id : this.QUICK_90_DAYS, label : "Last 90 Days" },
            { id : this.QUICK_12_MONTHS, label : "Last 12 Months" }
        ];
    }



    componentDidMount() {
        super.componentDidMount()

        this.fetchDealCountsFromNetwork()

    }

    segmentedButtonsDidChange(id) {
        if (id !== this.state.segmentedSelectedId) {

            var fromDate = undefined

            if (id === this.QUICK_60_DAYS){
                fromDate = moment().subtract(60, "days").toDate()
            } else if (id === this.QUICK_90_DAYS){
                fromDate = moment().subtract(90, "days").toDate()
            } else if (id === this.QUICK_12_MONTHS){
                fromDate = moment().subtract(1, "years").toDate()
            }

            this.setState({
                quickSelectID: id,
                toDate : moment().toDate(),
                fromDate : fromDate
            }, this.fetchDealCountsFromNetwork);


        }
    }



    handleDateChange(dataKey, date) {
        console.log(date);

        var state = {quickSelectID: undefined};
        state[dataKey] = date;

        this.setState(state, this.fetchDealCountsFromNetwork);
    }


    fetchDealCountsFromNetwork() {
        var context = this;

        let formData = new FormData();
        formData.append("fromDate", moment(this.state.fromDate).format('YYYY-MM-DD')+" 00:00:01");
        formData.append("toDate", moment(this.state.toDate).format('YYYY-MM-DD')+" 23:59:59");

        Axios.post(ENDPOINTS.deal.getImpressions, formData)
            .then(function(response) {
                var resp = API.parseResponse(response);
                if (resp.success) {
                    context.setState({
                        impressions : resp.data.impressions
                    });
                } else {
                    console.log("ERROR FETCHING COUNTS");
                    console.log(resp.error);
                }
            })
            .catch(function(err) {
                console.log("ERROR FETCHING COUNTS");
                console.log(err);
            });
    }

    render() {



        return (
            <div className={"container"}>
                <HeaderComponent />

                <div className={"page-header"}>
                    <div className={"page-title"}>
                        <span className={"btn btn-outline-primary"} onClick={() => window.location.href = "/deals/"}>
                            <FontAwesomeIcon icon={"chevron-left"} />
                        </span>
                        Total Impressions
                    </div>
                    <div className={"page-actions"}>


                    </div>
                </div>

                <div className={"row"}>

                    <div className={"col-12 col-md-3"}>
                        <div style={{fontSize : "x-large", fontWeight: "600", textAlign : "center"}}>From</div>
                        <div style={{textAlign : "center"}}>

                        <DatePicker
                            className={"form-control text-center"}
                            name={"fromDate"}
                            selected={this.state.fromDate}
                            dateFormat="dd/MM/yyyy"
                            onChange={(date) => this.handleDateChange("fromDate", date)}
                             />
                        </div>

                    </div>
                    <div className={"col-12 col-md-3"}>

                        <div style={{fontSize : "x-large", fontWeight: "600", textAlign : "center"}}>To</div>
                        <div style={{textAlign : "center"}}>
                        <DatePicker
                            className={"form-control text-center"}
                            name={"toDate"}
                            selected={this.state.toDate}
                            dateFormat="dd/MM/yyyy"
                            onChange={(date) => this.handleDateChange("toDate", date)}
                        />
                        </div>
                    </div>

                    <div className={"col-12 col-md-6"}>
                        <div style={{fontSize : "x-large", fontWeight: "600", textAlign : "center"}}>Quick Select</div>

                        <SegmentedButtonComponent buttons={this.segmentedButtons} changeHandler={this.segmentedButtonsDidChange} selectedId={this.state.quickSelectID} />

                    </div>
                </div>

                <div className={"row"} style={{marginTop: "30px"}}>
                    <div className={"col-12"}>
                        <div className={"section-container deal-info-widget"}>
                            <div className={"section-content"}>
                                <div className={"widget-heading"}>
                                    <div className={"widget-title"}>Total Deals Stats</div>
                                    <div className={"widget-actions"}>
                                    </div>
                                </div>

                                <div className={"info-segments"}>
                                    <div className={"info-segment"}>
                                        <div className={"segment-title"}>
                                            From:
                                        </div>
                                        <div className={"segment-value"}>
                                            {this.state.fromDate.toLocaleDateString()}
                                        </div>
                                    </div>

                                    <div className={"info-segment"}>
                                        <div className={"segment-title"}>
                                            To:
                                        </div>
                                        <div className={"segment-value"}>
                                            {this.state.toDate.toLocaleDateString()}
                                        </div>
                                    </div>


                                    <div className={"info-segment"}>
                                        <div className={"segment-title"}>
                                            Impressions
                                        </div>
                                        <div className={"segment-value"}>
                                           { this.state.impressions.toLocaleString(navigator.language, { minimumFractionDigits: 0 })}
                                        </div>
                                    </div>

                                </div>
                                <div className={"info-segments"}> </div>
                                <div className={"info-segments"}> </div>
                            </div>


                        </div>


                    </div>
                </div>


            </div>
        );
    }
}


export default DealStatsScreenComponent;