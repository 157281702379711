import React from 'react';
import { NavLink } from "react-router-dom";
import {API} from "../../networking/API";

class HeaderComponent extends React.Component {

    constructor(props, context) {
        super(props, context);
    }

    componentDidMount() {
        this.user = JSON.parse(localStorage.getItem("user"));
    }

    render() {
        var extraButtons = [];

        var user = localStorage.getItem("user");
        if (user != null) {
            user = JSON.parse(user);
            if (user.merchantUserTypeId === API.USER_TYPES.ADMINISTRATOR) {
                extraButtons.push(
                    <NavLink to={"/locations"} className={"item"} activeClassName={"active"}>Locations</NavLink>
                );
                extraButtons.push(
                    <NavLink to={"/users"} className={"item"} activeClassName={"active"}>User Accounts</NavLink>
                );
                extraButtons.push(
                    <NavLink to={"/images"} className={"item"} activeClassName={"active"}>Image Library</NavLink>
                );
            }
        }

        var navLinks = [];

        var showNavigation = true;
        if (this.user != null) {
            if (this.user.merchantTypeId == API.MERCHANT_TYPES.INDEPENDENT) {
                if (!this.user.hasPricePlan) {
                    showNavigation = false;
                }
            }
        }

        if (showNavigation) {
            navLinks.push(<NavLink exact to={"/"} className={"item"} activeClassName={"active"}>Account Details</NavLink>);
            navLinks.push(<NavLink to={"/deals"} className={"item"} activeClassName={"active"}>Deals</NavLink>);
            navLinks.push(extraButtons);
        }

        return(
            <div className={"header"}>
                <div className={"logo"} />
                <div className={"navigation-items"}>
                    {navLinks}
                </div>
            </div>
        );
    }

}

export default HeaderComponent;