import React from "react";
import {API} from "../networking/API";

/**
 * A Stateful Component which extends React.Component.
 *
 * When creating the Component you may pass a "state" parameter to "props" to pass an initial state.
 */
class StatefulComponent extends React.Component {

    constructor(props, context) {
        super(props, context);

        if (props.hasOwnProperty("state")) {
            this.initState(props.state);
        }

        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
        this.handleChange = this.handleChange.bind(this);

        this.showModal = this.showModal.bind(this);
        this.hideModal = this.hideModal.bind(this);
    }

    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        // Stub
    }

    handleScreenRestriction(admissibleUserTypes) {
        let admissible = false;
        var user = JSON.parse(localStorage.getItem("user"));
        for (var i = 0; i < admissibleUserTypes.length; i++) {
            if (user.merchantUserTypeId === admissibleUserTypes[i]) {
                admissible = true;
                break;
            }
        }

        if (!admissible) {
            window.location.href = "/";
        }
    }

    /**
     * Initialises the state for this component
     * @param state     State Object
     */
    initState(state) {
        this.state = state;
        this.setState(state);
    }

    /**
     * Handler for input items to ensure the value of the input is put into the state
     * @param event     Event from the onChange property
     * @param callback  Function to trigger after completing change
     */
    handleChange(event, callback) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name] : value
        }, callback);
    }

    validateCreateFormData(inObj, ruleArray) {
        var outBundle = {
            success : true
        };

        var formData = new FormData();

        for (var i = 0; i < ruleArray.length; i++) {
            var rules = ruleArray[i];

            var keyName = rules.key;
            var type = rules.type;
            var label = rules.label;
            var postName = rules.postName;
            var optional = rules.optional;
            var defaultValue = rules.default;

            if (keyName === undefined) {
                console.log("Validation rule was passed with no key. Skipping.");
                continue;
            }

            if (type === undefined) {
                type = "string";
            }

            if (optional === undefined) {
                optional = false;
            }

            if (inObj.hasOwnProperty(keyName)) {
                var valid = true;
                var invalidReason = "";

                var value = inObj[keyName];
                if (value === undefined && defaultValue !== undefined) {
                    value = defaultValue;
                }

                if (type !== undefined) {
                    if (type === "int" || type === "integer" || type === "float" || type === "double") {
                        if (type === "int" || type === "integer") {
                            value = parseInt(value);
                        } else if (type === "float" || type === "double") {
                            value = parseFloat(value);
                        }

                        if (isNaN(value)) {
                            valid = false;
                            invalidReason = "Please provide a valid number for " + label;
                        }

                        if (valid && rules.hasOwnProperty("greaterZero")) {
                            if (rules.greaterZero) {
                                if (value <= 0) {
                                    valid = false;
                                    invalidReason = "Please provide a number greater than zero for " + label;
                                }
                            }
                        }

                        if (valid && rules.hasOwnProperty("greaterEqualsZero")) {
                            if (rules.greaterEqualsZero) {
                                if (value < 0) {
                                    valid = false;
                                    invalidReason = "Please provide a number greater or equal to zero for " + label;
                                }
                            }
                        }
                    } else if (type === "string") {
                        var notEmpty = true;
                        if (rules.hasOwnProperty("notEmpty")) {
                            notEmpty = rules.notEmpty
                        }

                        if (notEmpty) {
                            if (value === "") {
                                valid = false;
                                invalidReason = "Please provide a " + label;
                            }
                        }
                    }
                }

                if (valid) {
                    if (postName !== undefined) {
                        formData.append(postName, value);
                    }
                } else if (!optional) {
                    outBundle.success = false;
                    outBundle.error = invalidReason;
                    break;
                }
            } else if (!optional) {
                if (defaultValue !== undefined && postName !== undefined) {
                    formData.append(postName, defaultValue);
                } else {
                    outBundle.success = false;
                    outBundle.error = "Please provide a " + label;
                    break;
                }
            }
        }

        if (outBundle.success) {
            outBundle.formData = formData;
        }

        return outBundle;
    }

    showModal(title, content, buttons) {
        if (buttons === undefined) {
            buttons = [
                {
                    label : "OK",
                    style : "btn-default",
                    click : this.hideModal
                }
            ];
        }

        var modal = {
            open : true,
            title : title,
            content : content,
            buttons : buttons
        };
        this.setState({
            modal : modal
        });
    }

    hideModal() {
        this.setState({
            modal : {
                open : false
            }
        });
    }

    modalWasDismissed() {
        this.hideModal();
    }

}

export default StatefulComponent;