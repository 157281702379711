import React from "react";
import {Elements, CardElement, useStripe, useElements} from "@stripe/react-stripe-js";
import {loadStripe} from "@stripe/stripe-js";
import StatefulComponent from "../StatefulComponent";
import HeaderComponent from "../common/HeaderComponent";

import '../../assets/css/price-tier-screen.css';
import PriceTierInfoComponent from "./PriceTierInfoComponent";
import Axios from "axios";
import {API, ENDPOINTS} from "../../networking/API";
import AlertModal from "../common/AlertModal";
import StripeCheckout from "react-stripe-checkout";

import stripeLogo from "../../assets/img/powered_by_stripe.svg";

class PriceTierScreenComponent extends StatefulComponent {

    constructor(props, context) {
        super(props, context);

        this.initState({
            pricing : null,
            selectedPriceOptionId : null,
            priceOptionPrice : 0,
            dealCount: 0,
            locationCount: 0
        });

        this.user = JSON.parse(localStorage.getItem("user"));

        // Do not allow non-independent merchant users access this screen
        if (this.user.merchantTypeId != API.MERCHANT_TYPES.INDEPENDENT) {
            window.location.href = "/";
        }

        this.getPricingFromNetwork = this.getPricingFromNetwork.bind(this);
        this.priceItemWasSelected = this.priceItemWasSelected.bind(this);
        this.onToken = this.onToken.bind(this);
        this.refreshUser = this.refreshUser.bind(this);
        this.getDealCountFromNetwork = this.getDealCountFromNetwork.bind(this);
        this.getLocationCountFromNetwork = this.getLocationCountFromNetwork.bind(this);
    }

    componentDidMount() {
        super.componentDidMount();

        this.getPricingFromNetwork();
        this.getDealCountFromNetwork();
        this.getLocationCountFromNetwork();
    }

    getDealCountFromNetwork() {
        Axios.get(ENDPOINTS.deal.getCounts)
            .then((r) => {
                let resp = API.parseResponse(r);
                if (resp.success) {
                    this.setState({
                        dealCount : resp.data.activeDealCount
                    });
                } else {
                    console.log(resp.error);
                }
            })
            .catch((e) => {
                console.log(e);
            });
    }

    getLocationCountFromNetwork() {
        Axios.get(ENDPOINTS.location.getCounts)
            .then((r) => {
                let resp = API.parseResponse(r);
                if (resp.success) {
                    this.setState({
                        locationCount : resp.data.usedLocations
                    });
                } else {
                    console.log(resp.error);
                }
            })
            .catch((e) => {
                console.log(e);
            });
    }

    getPricingFromNetwork() {
        Axios.get(ENDPOINTS.pricing.getPrices)
            .then((r) => {
                let resp = API.parseResponse(r);
                if (resp.success) {
                    this.setState({
                        pricing : resp.data
                    });
                } else {
                    // TODO Handle error
                }
            })
            .catch((e) => {
                console.log(e);
                // TODO Handle error
            })
    }

    priceItemWasSelected(id, price) {
        console.log("Selection: " + id);

        var priceObj = null;
        for (var i = 0; i < this.state.pricing.length; i++) {
            if (this.state.pricing[i].id == id) {
                priceObj = this.state.pricing[i];
                break;
            }
        }

        var dealCounts = this.state.dealCount;
        var locationCounts = this.state.locationCount;

        if (this.state.dealCount > priceObj.dealSlots) {
            this.showModal("Error", "Cannot select this price tier. You have more active deals than this price tier allows. Please cancel or wait for enough deals to expire to bring your currently active deals within the number of deals permitted for your desired price tier.");
            return;
        }

        if (this.state.locationCount > priceObj.locationSlots) {
            this.showModal("Error", "Cannot select this price tier. You have more locations than this price tier allows. Please delete Locations to bring your Location count below the amount permitted of this price tier.");
            return;
        }

        this.setState({
            selectedPriceOptionId : id,
            priceOptionPrice : price,
            selectedPriceOption : priceObj
        });
    }

    onToken(token, address) {
        var formData = new FormData();
        formData.append("stripeToken", token.id);
        formData.append("priceOptionId", this.state.selectedPriceOptionId);
        formData.append("type", token.type);
        formData.append("email", token.email);

        Axios.post(ENDPOINTS.pricing.changePricingOption, formData)
            .then((r) => {
                let resp = API.parseResponse(r);
                if (resp.success) {
                    this.refreshUser();
                } else {
                    this.showModal("Error", resp.error);
                }
            })
            .catch((e) => {
                console.log(e);
                this.showModal("Error", "An unknown error has occurred. Please try again later. [910]");
            });
    }

    refreshUser() {
        var formData = new FormData();
        formData.append("id", this.user.id);

        Axios.post(ENDPOINTS.user.getUser, formData)
            .then((r) => {
                let resp = API.parseResponse(r);
                if (resp.success) {
                    if (this.props.userRefresh != null) {
                        this.props.userRefresh(resp.data);
                    }

                    this.setState({
                        processComplete : true
                    });
                } else {
                    this.showModal("Error", "Could not retrieve user details. Please try again.", [
                        {
                            label : "Retry",
                            click : () => {
                                this.refreshUser()
                            }
                        }
                    ])
                }
            })
            .catch((e) => {
                console.log(e);
                this.showModal("Error", "An unknown error has occurred. Please try again later. [105]");
            })
    }

    completeProcess() {
        window.location.href = "/";
    }

    render() {
        var priceOptions = [];

        if (this.state.pricing != null) {
            for (var i = 0; i < this.state.pricing.length; i++) {
                var price = this.state.pricing[i];
                priceOptions.push(
                    <div className={"col-12 col-md-4"}>
                        <PriceTierInfoComponent
                            id={price.id}
                            price={"£" + (price.price / 100).toFixed(0)}
                            pricePence={price.price}
                            duration={price.billingInterval}
                            sections={[
                                {
                                    title: "Includes",
                                    items: [
                                        {title: price.dealSlots + " Merchant Deal Slots at one time"},
                                        {title: price.locationSlots + " Location at one time"}
                                    ]
                                }
                            ]}
                            selected={this.state.selectedPriceOptionId == price.id}
                            selectionHandler={(id) => this.priceItemWasSelected(id)} />
                    </div>
                );
            }
        }

        var stripeCheckout = [];

        if (this.state.selectedPriceOption == null) {
            stripeCheckout.push(
                <em>Please select a Plan</em>
            );
        } else {
            // pk_test_iabuoNdy2gLsCMsntyNwa9Jx
            stripeCheckout.push(
                <StripeCheckout
                    stripeKey="pk_test_dh90eeaTCUJVLBItOKLqKkln"
                    token={this.onToken}
                    name="Gas App Uk Merchant"
                    description={(this.state.selectedPriceOption != null) ? this.state.selectedPriceOption.title : ""}
                    amount={this.state.priceOptionPrice}
                    email={this.user.email}
                    currency="GBP"
                    locale="auto"
                    label="Proceed to Payment" />
            );
        }

        var content = [];

        if (!this.state.processComplete) {
            content.push(
                <div>
                    <div className={"pricing-title"}>
                        Select your pricing plan
                    </div>

                    <div className={"row pricing-options"}>
                        {priceOptions}
                    </div>


                    <div className={"row"}>
                        <div className={"hidden-xs col-sm-2 col-md-4"} />
                        <div className={"col-12 col-sm-8 col-md-4 text-center"}>
                            <div>
                                {stripeCheckout}
                            </div>
                            <div class={"stripe-icon"}>
                                <a href={"https://stripe.com/gb"}><img src={stripeLogo} alt={"Powered by Stripe"} /></a>
                            </div>
                        </div>
                    </div>

                    <div className={"row form-block"}>
                        <div className={"col-12 smallprint"}>
                            Your subscription will renew every month until cancelled.
                        </div>
                    </div>
                </div>
            );
        } else {
            content.push(<div>
                <div className={"pricing-title"}>
                    Your price plan has been updated
                </div>

                <div className={"row"}>
                    <div className={"col-12 text-center"}>
                        <span className={"btn btn-outline-primary"} onClick={this.completeProcess}>Get Started</span>
                    </div>
                </div>
            </div>);
        }

        return (
            <div className={"container price-tier-screen"}>
                <HeaderComponent />

                <div className={"page-header"}>
                    <div className={"page-title"}>Pricing</div>
                    <div className={"page-actions"}>
                        <a href={"#"} onClick={() => this.props.logoutCallback()}>Sign out</a>
                    </div>
                </div>

                {content}

                <AlertModal
                    open={ (this.state.modal != null) ? this.state.modal.open : false }
                    title={ (this.state.modal != null) ? this.state.modal.title : "" }
                    content={ (this.state.modal != null) ? this.state.modal.content : [] }
                    buttons={ (this.state.modal != null) ? this.state.modal.buttons : [] }
                    dismissHandler={ () => this.modalWasDismissed() }
                />

            </div>
        );
    }

}

export default PriceTierScreenComponent;