import React from "react";
import LoginScreen from "./login/LoginScreen";
import HomeScreen from "./home/HomeScreen";
import { API } from "../networking/API";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import StatefulComponent from "./StatefulComponent";
import DealListScreenComponent from "./deals/DealListScreenComponent";
import DealEditorScreenComponent from "./deals/DealEditorScreenComponent";
import UserListScreenComponent from "./users/UserListScreenComponent";
import UserEditorScreenComponent from "./users/UserEditorScreenComponent";
import LocationListScreenComponent from "./locations/LocationListScreenComponent";
import LocationEditorScreenComponent from "./locations/LocationEditorScreenComponent";
import PasswordResetRequestScreen from "./login/PasswordResetRequestScreen";
import PasswordResetSubmitScreen from "./login/PasswordResetSubmitScreen";
import PriceTierScreenComponent from "./payment/PriceTierScreenComponent";
import DealStatsScreenComponent from "./deals/DealStatsScreenComponent";
import ImageLibScreenComponent from "./image-lib/ImageLibScreenComponent"
import ImageEditorScreenComponent from "./image-lib/ImageEditorScreenComponent"
import LocationDealReportScreenComponent from "./locations/LocationDealReportScreenComponent";
import LocationDealReportContainerComponent from "./locations/LocationDealReportContainerComponent";


class MainComponent extends StatefulComponent {

    constructor(props, context) {
        super(props, context);
    }

    componentDidMount() {
        super.componentDidMount();

        // If we have a local user already set, reload now
        var user = localStorage.getItem("user");
        var sessionToken = localStorage.getItem("authToken");

        if (user !== null && sessionToken !== null) {
            user = JSON.parse(user);

            this.onUserLogin(user, sessionToken);
        }

        // When authentication fails, sign the user out
        API.onAuthFailed(() => {
            this.onUserLogout();
            window.location.href = "/";
        });

        this.onUserLogin = this.onUserLogin.bind(this);
    }

    /**
     * General login handler
     * @param user              User object
     * @param sessionToken      User Authentication Token
     */
    onUserLogin(user, sessionToken) {
        localStorage.setItem("user", JSON.stringify(user));

        if (sessionToken !== undefined) {
            localStorage.setItem("authToken", sessionToken);
            API.setAuthenticationToken(sessionToken);
        }

        this.setState({ user : user });
    }

    /**
     * General logout handler. Clears up user data and state.
     */
    onUserLogout() {
        localStorage.removeItem("user");
        localStorage.removeItem("authToken");

        API.setAuthenticationToken(null);
        this.setState({ user : null });
    }

    render() {
        if (this.state == null || this.state.user == null) {
            // Not logged in, force user to login screen
            return (
                <BrowserRouter>
                    <Switch>
                        <Route
                            path={"/"} exact
                            render={(props) =>
                                <LoginScreen loginCallback={(user, sessionToken) => this.onUserLogin(user, sessionToken)}/>
                            }
                            forceReg={true} />

                        <Route
                            path={"/forgotten"} exact
                            render={(props) =>
                                <PasswordResetRequestScreen {...props} />
                            }
                            forceReg={true} />

                        <Route
                            path={"/forgotten/:token"}
                            render={(props) =>
                                <PasswordResetSubmitScreen {...props} />
                            }
                            forceReg={true} />
                    </Switch>
                </BrowserRouter>
            )
        } else {
            // Logged in
            if (this.state.user.merchantTypeId == API.MERCHANT_TYPES.INDEPENDENT) {
                if (!this.state.user.hasPricePlan) {
                    return (
                        <BrowserRouter>
                            <Switch>
                                <Route
                                    path={"/"}
                                    render={(props) =>
                                        <PriceTierScreenComponent {...props} userRefresh={this.onUserLogin} logoutCallback={() => this.onUserLogout()} />
                                    }
                                    forceReg={true} />
                            </Switch>
                        </BrowserRouter>
                    )
                }
            }

            return(
                <BrowserRouter>
                    <Switch>
                        <Route
                            path={"/"} exact
                            render={(props) =>
                                <HomeScreen logoutCallback={() => this.onUserLogout()} />
                            }
                            forceReg={true} />

                        <Route
                            path={"/images"} exact
                            render={(props) =>
                                <ImageLibScreenComponent />
                            }
                            forceReg={true} />

                        <Route
                            path={"/images/:id"}
                            render={(props) =>
                                <ImageEditorScreenComponent {...props} />
                            }
                            forceReg={true} />

                        <Route
                            path={"/deals"} exact
                            render={(props) =>
                                <DealListScreenComponent />
                            }
                            forceReg={true} />

                        <Route
                            path={"/deals/stats"} exact
                            render={(props) =>
                                <DealStatsScreenComponent />
                            }
                            forceReg={true} />

                        <Route
                            path={"/deals/:id"}
                            render={(props) =>
                                <DealEditorScreenComponent {...props} user={this.state.user} />
                            }
                            forceReg={true} />

                        <Route
                            path={"/deal-renew/:id"}
                            render={(props) =>
                                <DealEditorScreenComponent {...props} user={this.state.user} renewal={true} />
                            }
                            forceReg={true} />

                        <Route
                            path={"/users/"} exact
                            render={(props) =>
                                <UserListScreenComponent {...props} />
                            }
                            forceReg={true} />

                        <Route
                            path={"/users/:id"}
                            render={(props) =>
                                <UserEditorScreenComponent {...props} />
                            }
                            forceReg={true} />

                        <Route
                            path={"/locations/"} exact
                            render={(props) =>
                                <LocationListScreenComponent {...props} />
                            }
                            forceReg={true} />

                        <Route
                            path={"/locations/:id"}
                            render={(props) =>
                                <LocationEditorScreenComponent {...props} />
                            }
                            forceReg={true} />

                        <Route
                            path={"/locations-deal-report/"} exact
                            render={(props) =>
                                <LocationDealReportContainerComponent {...props} />
                            }
                            forceReg={true} />

                        <Route
                            path={"/payment"}
                            render={(props) =>
                                <PriceTierScreenComponent {...props} userRefresh={this.onUserLogin} />
                            }
                            forceReg={true} />
                    </Switch>
                </BrowserRouter>
            )
        }
    }
}

export default MainComponent;