import React from 'react';
import '../../assets/css/alert-modal.css';

class AlertModal extends React.Component {

    constructor(props, context) {
        super(props, context);
    }

    dismissClicked() {
        console.log("Dismiss clicked");
        if (this.props.dismissHandler != null) {
            this.props.dismissHandler();
        }
    }

    render() {
        let isOpen = false;
        if (this.props.open !== null) {
            isOpen = this.props.open;
        }

        console.log("AlertModal, show? " + isOpen);

        var buttons = [];

        if (this.props.buttons != null) {
            this.props.buttons.forEach(function(element, index) {
                var buttonClass = "btn";
                if (element.style != null) {
                    buttonClass += " " + element.style;
                }

                var clickListener = () => {};
                if (element.click != null) {
                    clickListener = element.click;
                }

                buttons.push(<span key={index} className={ buttonClass } onClick={() => clickListener()}>{ (element.label != null) ? element.label : "" }</span>);
            });
        }

        if (isOpen) {
            return (
                <div className={"alert-modal"}>
                    <div className={"modal-dialog"}>
                        <div className={"modal-header"}>
                            {(this.props.title != null) ? this.props.title : "Alert"}
                            <span className={"close"} onClick={() => this.dismissClicked() }>X</span>
                        </div>
                        <div className={"modal-body"}>
                            {(this.props.content != null) ? this.props.content : ""}
                        </div>
                        <div className={"modal-footer"}>
                            { buttons }
                        </div>
                    </div>
                </div>
            );
        } else {
            return(<React.Fragment />);
        }
    }

}

export default AlertModal;