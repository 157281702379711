import React from "react";

import '../../assets/css/price-tier-info.css';

class PriceTierInfoComponent extends React.Component {

    constructor(props, context) {
        super(props, context);

        this.itemWasSelected = this.itemWasSelected.bind(this);
    }

    itemWasSelected() {
        console.log("Price item click!");
        console.log(this.props.selectionHandler);
        if (this.props.selectionHandler != null) {
            console.log("Trigger callback");
            this.props.selectionHandler(this.props.id, this.props.pricePence);
        }
    }

    render() {
        var sections = [];

        if (this.props.sections != null) {
            for (var i = 0; i < this.props.sections.length; i++) {
                let section = this.props.sections[i];

                var listItems = [];

                for (var x = 0; x < section.items.length; x++) {
                    let item = section.items[x];

                    listItems.push(
                        <li className={item.className}>{item.title}</li>
                    );
                }

                sections.push(
                    <span className={"section-title"}>{section.title}</span>,
                    <ul className={"section-items"}>
                        {listItems}
                    </ul>
                );
            }
        }

        var button = [];

        if (this.props.selected) {
            button.push(
                <span className={"btn btn-primary"}>Selected</span>
            );
        } else {
            button.push(
                <span className={"btn btn-outline-primary"} onClick={this.itemWasSelected}>Select</span>
            );
        }

        return (
            <div className={"price-option"}>
                <span className={"price"}>{this.props.price}</span>
                <span className={"price-sub"}>{(this.props.duration != null) ? this.props.duration : "per month" }</span>

                {sections}

                <span className={"buttons"}>
                    {button}
                </span>
            </div>
        );
    }

}

export default PriceTierInfoComponent;