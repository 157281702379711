import React from "react";
import StatefulComponent from "../StatefulComponent";
import {API, ENDPOINTS} from "../../networking/API";
import Axios from "axios";
import '../../assets/css/login.css';
import AlertModal from "../common/AlertModal";
import {Link} from "react-router-dom";

class PasswordResetSubmitScreen extends StatefulComponent {

    constructor(props, context) {
        super(props, context);

        this.initState({
            token : null,
            isValidating : true,
            isInvalid : false,
            resetSuccess : false
        });

        this.loginBox = React.createRef();

        this.requestWasSubmitted = this.requestWasSubmitted.bind(this);
    }

    componentDidMount() {
        super.componentDidMount();

        if (this.props.match.params.token != null) {
            this.validateTokenOverNetwork(this.props.match.params.token);
        } else {
            this.setState({
                isValidating : false,
                isInvalid : true
            });
        }

        this.alignLoginBox();
    }

    updateWindowDimensions() {
        super.updateWindowDimensions();

        this.alignLoginBox();
    }

    alignLoginBox() {
        var loginBoxStyle = window.getComputedStyle(this.loginBox.current, null);

        var marginTop = (window.innerHeight - parseInt(loginBoxStyle.getPropertyValue("height"))) / 2;
        marginTop = Math.max(0, marginTop);

        this.loginBox.current.style.marginTop = marginTop + "px";
    }

    validateTokenOverNetwork(token) {
        var formData = new FormData();
        formData.append("token", token);

        Axios.post(ENDPOINTS.login.validatePasswordReset, formData)
            .then((r) => {
                let resp = API.parseResponse(r);
                this.setState({
                    token : token,
                    isValidating : false,
                    isInvalid : !resp.success
                });
            })
            .catch((e) => {
                this.setState({
                    isValidating : false,
                    isInvalid : true
                });
            });
    }

    requestWasSubmitted() {
        if (this.state.networkInFlight) return;

        var validationResult = this.validateCreateFormData(this.state, [
            {
                key : "password",
                label : "Password",
                postName : "password"
            }
        ]);

        if (validationResult.success) {
            this.setState({
                networkInFlight : true
            });

            let formData = validationResult.formData;
            formData.append("token", this.state.token);

            Axios.post(ENDPOINTS.login.submitPasswordReset, formData)
                .then((response) => {
                    let resp = API.parseResponse(response);
                    if (resp.success) {
                        this.setState({
                            resetSuccess : true
                        });
                    } else {
                        this.showModal("Error", resp.error);
                    }
                })
                .catch((err) => {
                    this.showModal("Error", "An unknown error occurred. [900]");
                });
        } else {
            this.showModal("Error", validationResult.error);
        }
    }

    render() {
        setTimeout(() => {
            this.alignLoginBox();
        }, 100);

        var formFields = [];

        if (this.state.isValidating) {
            formFields.push(
                <div>
                    <div className={"form-block text-center"}><strong>Please wait</strong></div>
                </div>
            );
        } else if (!this.state.isInvalid) {
            if (!this.state.resetSuccess) {
                formFields.push(
                    <div>
                        <div className="form-block">
                            <label>New Password</label><br/>
                            <input type="password" name="password" className="form-control" value={this.state.password}
                                   onChange={this.handleChange}/>
                        </div>

                        <div className="form-block">
                            <label>Confirm Password</label><br/>
                            <input type="password" name="passwordRetype" className="form-control"
                                   value={this.state.passwordRetype} onChange={this.handleChange}/>
                        </div>

                        <div className="form-block text-centre">
                            <button className="btn btn-success" onClick={() => this.requestWasSubmitted()}>Change
                                Password
                            </button>
                        </div>

                        <div className="form-block text-centre">
                            <Link to={"/"}>Return to Login</Link>
                        </div>
                    </div>
                );
            } else {
                formFields.push(
                    <div>
                        <div className={"form-block text-center"}><strong>Successfully changed Password. Please <Link to={"/"}>click here</Link> to login.</strong></div>
                    </div>
                );
            }
        } else {
            formFields.push(
                <div>
                    <div className={"form-block text-center"}><strong>There was a problem with your Password Reset request. This request may have expired or have been already used. Please request a new Password Reset.</strong></div>
                </div>
            );
        }

        var message = [];


        return (
            <div className="container">
                <div className="row">
                    <div className="hidden-xs col-md-2 col-lg-3 col-xl-4" />
                    <div className="col-xs-12 col-md-8 col-lg-9 col-xl-4">
                        <div className="login-box" ref={this.loginBox}>
                            <div className="form-block text-center">
                                <img src={require("../../assets/img/gas-app-logo-blue.png")} alt="Gas App Uk&reg;" className="logo" />
                            </div>

                            <div className="form-block text-center">
                                <strong>Password Reset</strong>
                            </div>

                            { message }

                            {formFields}
                        </div>
                    </div>
                </div>

                <AlertModal
                    open={ (this.state.modal != null) ? this.state.modal.open : false }
                    title={ (this.state.modal != null) ? this.state.modal.title : "" }
                    content={ (this.state.modal != null) ? this.state.modal.content : [] }
                    buttons={ (this.state.modal != null) ? this.state.modal.buttons : [] }
                    dismissHandler={ () => this.modalWasDismissed() }
                />
            </div>
        );
    }

}

export default PasswordResetSubmitScreen;