import React from "react";

import '../../assets/css/remaining-count-widget.css';

class RemainingCountComponent extends React.Component {

    render() {
        var remainingCount = undefined;
        if (this.props.remaining !== undefined) {
            remainingCount = this.props.remaining;
        }

        var totalCount = 0;
        if (this.props.total != null) {
            totalCount = this.props.total;
        }

        var ofLabel = "of";
        if (this.props.of != null) {
            ofLabel = this.props.of;
        }

        var suffixLabel = "remaining";
        if (this.props.suffix != null) {
            suffixLabel = this.props.suffix;
        }

        let elements = [];
        if (remainingCount !== undefined) {
            elements.push(<span className={"circle"}>{ remainingCount }</span>);
            elements.push(<span className={"text"}>{ ofLabel }</span>);
        }

        return(
            <div className={"remaining-widget"}>
                {elements}
                <span className={"circle"}>{ totalCount }</span>
                <span className={"text"}>{ suffixLabel }</span>
            </div>
        );
    }

}

export default RemainingCountComponent;