import StatefulComponent from "../StatefulComponent";
import {API, ENDPOINTS} from "../../networking/API";
import React from "react";
import HeaderComponent from "../common/HeaderComponent";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Axios from "axios";
import AlertModal from "../common/AlertModal";


class ImageEditorScreenComponent extends StatefulComponent {

    constructor(props, context) {
        super(props, context);

        this.handleScreenRestriction([API.USER_TYPES.ADMINISTRATOR]);

        var userSubmissionId = localStorage.getItem("successfulImageSubmissionId");
        localStorage.removeItem("successfulImageSubmissionId");

        this.initState({
            image: null,
            userSubmissionId : userSubmissionId
        });
        this.imageInput = React.createRef();
        this.imageSelectionDidChange = this.imageSelectionDidChange.bind(this);
        this.imageWasSubmitted = this.imageWasSubmitted.bind(this);
        this.getImageFromNetwork = this.getImageFromNetwork.bind(this);
        this.deleteImageOverNetwork = this.deleteImageOverNetwork.bind(this);
    }

    componentDidMount() {
        if (this.props.match.params.id != null && this.props.match.params.id !== "new") {
            this.getImageFromNetwork()
        }
    }

    getImageFromNetwork() {
        let formData = new FormData();
        formData.append("id", this.props.match.params.id);

        var context = this;

        Axios.post(ENDPOINTS.images.getImage, formData)
            .then(function(response) {
                let resp = API.parseResponse(response);
                if (resp.success) {
                    context.putImageInState(resp.data);
                } else {
                    context.showModal("Error", resp.error);
                }
            })
            .catch(function(err) {
                context.showModal("Error", "An unknown error occurred. [101]");
            });
    }

    putImageInState(image) {
        this.setState({
            image : image,
            imagePreviewUri : image.publicUrl
        });
    }


    imageSelectionDidChange(event) {
        if (event.target.files.length > 0) {
            var uploadImage = event.target.files[0];

            this.setState({
                uploadImage : uploadImage
            });

            var context = this;

            var reader = new FileReader();
            reader.onload = function() {
                context.setState({
                    imagePreviewUri : reader.result
                });
            };
            reader.readAsDataURL(uploadImage);
        }
    }


    deleteImageOverNetwork(event, confirmed) {
        if (this.state.image == null) return;

        if (confirmed === undefined || !confirmed) {
            this.showModal("Please confirm", "Are you sure you want to delete this Image? This action cannot be undone.", [
                {
                    label : "Delete Image",
                    style : "btn-danger",
                    click : () => {
                        this.deleteImageOverNetwork(event, true);
                    }
                },
                {
                    label : "Cancel",
                    style : "btn-success",
                    click : () => {
                        this.hideModal();
                    }
                }
            ]);
        } else {
            let formData = new FormData();
            formData.append("id", this.state.image.id);

            Axios.post(ENDPOINTS.images.deleteImage, formData)
                .then((r) => {
                    let resp = API.parseResponse(r);
                    if (resp.success) {
                        window.location.href = "/images";
                    } else {
                        this.showModal("Error", resp.error);
                    }
                })
                .catch((e) => {
                    console.log(e);
                    this.showModal("Error", "An unknown error has occurred. [1709]");
                });
        }
    }


    imageWasSubmitted() {

        if (this.state.isUploading) return;

        if (this.state.uploadImage != null) {
            this.setState({
                isUploading : true
            });
            var formData = new FormData();
            formData.append("image", this.state.uploadImage);

            if (this.state.image) {
                formData.append("id", this.state.image.id);
            }
            var context = this;

            Axios.post(ENDPOINTS.images.uploadImage, formData, {
                onUploadProgress : function(data) {
                    if (data.lengthComputable) {
                        var percent = ((data.loaded / data.total) * 100);

                        console.log("UPLOAD: " + percent + "%");
                        context.setState({
                            uploadProgressPercent : percent
                        });
                    }
                }
            })
                .then(function(response) {
                    var resp = API.parseResponse(response);
                    if (resp.success) {
                        localStorage.setItem("successfulImageSubmissionId", resp.data);
                        window.location.href = "/images/" + resp.data;
                    } else {
                        context.showModal("Error", resp.error);
                    }
                    context.setState({
                        isUploading : false
                    });
                })
                .catch(function(err) {
                    console.log(err);
                    context.showModal("Error", "An unknown error has occurred. Please try again later. [1170]")
                    context.setState({
                        isUploading : false
                    });
                });
        } else {
            if (this.state.image) {
                this.showModal("Error","No changes made, please select a new image first.");
            } else {
                this.showModal("Error","Please select an image to upload.");
            }
        }
    }


    render() {

        var messageElem = [];

        if (this.state.image != null) {
            if (this.state.userSubmissionId != null) {
                if (this.state.userSubmissionId == this.state.image.id) {
                    messageElem.push(
                        <div className="alert alert-success" role="alert">Changes have been saved.</div>
                    );
                }
            }
        }

        return (
            <div className={"container"}>
                <HeaderComponent />

                <div className={"page-header"}>
                    <div className={"page-title"}>
                        <span className={"btn btn-outline-primary"} onClick={() => window.location.href = "/images/"}>
                            <FontAwesomeIcon icon={"chevron-left"} />
                        </span>
                        { (this.state.image != null) ? "Edit Image" : "New Image" }
                    </div>
                    <div className={"page-actions"}>
                        {this.state.image && <button className={"btn btn-danger"} onClick={this.deleteImageOverNetwork}>Delete Image</button>}
                    </div>
                </div>

                {messageElem}

                <div className={"section-container user-editor-screen"}>
                    <div className={"section-content"}>


                        <div className={"row form-block"}>
                            <div className={"col-12"}>
                                <label className={"no-margin"}>Library Image</label>
                                <div className={"image-selection recess-area form-block"}>
                                    <div className={"row"}>
                                        <div className={"col-12 col-md-4 image-container"}>
                                            <div className={"image-preview"} ref={this.imagePreview} style={{ backgroundImage : (this.state.imagePreviewUri != null) ? "url(" + this.state.imagePreviewUri + ")" : "" }} />
                                        </div>
                                        <div className={"col-12 col-md-8 text-center"}>
                                            <div className={"image-filename"}>{ (this.state.uploadImage != null) ? this.state.uploadImage.name : (this.state.image == null) && "No image selected" }</div>
                                            <div className={"form-block"}>
                                                <div className={"input-hide"}>
                                                    <input type={"file"} ref={this.imageInput} onChange={ this.imageSelectionDidChange } />
                                                </div>
                                                <span className={"btn btn-primary"} onClick={() => this.imageInput.current.click()}>{ (this.state.image == null) ? "Select Image" : "Change Image" }</span>
                                            </div>
                                            <div className={"form-block text-small"}>
                                                <p>Please note the following when selecting your image:</p>
                                                <ul className={"text-left"}>
                                                    <li>Maximum allowed file size is 2 megabytes.</li>
                                                    <li>Images will be displayed in a 16:9 aspect ratio in the deal screen regardless of the aspect ratio of the uploaded image.</li>
                                                    <li>In deal lists images will be displayed in a 1:1 aspect ratio.</li>
                                                    <li>Please avoid including text in your image, it may not be legible in certain situations</li>
                                                    <li>Please consider using using a lower quality setting when mastering images to keep file sizes lower.</li>
                                                    <li>Consider uploading images of sizes (pixels): 1280 x 720, 1920 x 1080 or multiples of.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>



                        <div className={"row form-block"}>
                            <div className={"col-12 text-center"}>
                                <div className={"submission-buttons"} style={{ display : (!this.state.isUploading) ? "block" : "none" }}>
                                    <button className={"btn btn-success"} onClick={this.imageWasSubmitted}>{ "Save Image" }</button>
                                </div>
                                <div className={"upload-progress-area"} style={{ display : (this.state.isUploading) ? "block" : "none" }}>
                                    <span className={"upload-notice-label"}>Saving, please wait...</span>
                                    <div className={"progress"}>
                                        <div className={"progress-bar progress-bar-striped progress-bar-animated"} style={{ width : this.state.uploadProgressPercent + "%" }} />
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                <AlertModal
                    open={ (this.state.modal != null) ? this.state.modal.open : false }
                    title={ (this.state.modal != null) ? this.state.modal.title : "" }
                    content={ (this.state.modal != null) ? this.state.modal.content : [] }
                    buttons={ (this.state.modal != null) ? this.state.modal.buttons : [] }
                    dismissHandler={ () => this.modalWasDismissed() }
                />
            </div>
        )
    }

}

export default ImageEditorScreenComponent;