import React  from 'react';
import StatefulComponent from "../StatefulComponent";

import '../../assets/css/deal-info-widget.css';
import AlertModal from "../common/AlertModal";
import Axios from "axios";
import {API, ENDPOINTS} from "../../networking/API";
import moment from 'moment';

class DealInfoComponent extends StatefulComponent {

    constructor(props, context) {
        super(props, context);

        this.initState({
            deal : this.props.deal
        });

        this.cancelButtonWasClicked = this.cancelButtonWasClicked.bind(this);
        this.cancelDeal = this.cancelDeal.bind(this);
        this.renewDeal = this.renewDeal.bind(this);
    }

    renewDeal() {
        window.location.href = "/deal-renew/" + this.props.dealID;
    }

    cancelButtonWasClicked() {
        var context = this;
        this.showModal("Please confirm", "You are about to cancel this Deal. This action cannot be undone. Please confirm you wish to cancel this Deal.", [
            {
                label : "Abort",
                style : "btn btn-default",
                click: function() {
                    context.hideModal();
                }
            },
            {
                label : "Cancel Deal",
                style : "btn btn-danger",
                click : function() {
                    console.log("OK!");
                    context.cancelDeal();
                }
            }
        ]);
    }

    cancelDeal() {
        if (this.state.cancelInProgress) return;
        this.setState({
            cancelInProgress : true
        });

        var context = this;

        var formData = new FormData();
        formData.append("massiveDealId", this.props.deal.id);

        Axios.post(ENDPOINTS.deal.cancel, formData)
            .then(function(response) {
                var resp = API.parseResponse(response);
                if (resp.success) {
                    console.log("Successfully cancelled deal!");
                    window.location.reload();
                } else {
                    console.log(resp.error);
                    context.showModal("Error", resp.error);
                }
            })
            .catch(function(err) {
                console.log(err);
                context.showModal("Error", "An unknown error has occurred. Please try again later. [110]");
            });
    }

    render() {
        if (this.props.deal == null) {
            return (
                <div />
            );
        }

        var dealInDate = true;
        if (this.props.deal.endDate != null) {
            var dealDate = moment(this.props.deal.endDate).toDate();
            var today = new Date();
            dealInDate = dealDate > today;

            console.log("DATES:");
            console.log(dealDate);
            console.log(today);
            console.log("DEAL IN DATE: " + dealInDate);
        }

        var cancelButton = (<div />);
        var cancelNotice = (<div />);
        console.log("ACTIVE: " + this.props.deal.active);
        if (this.props.deal.active == 1 && dealInDate) {
            cancelButton = (
                <span className={"btn btn-danger"} onClick={this.cancelButtonWasClicked}>Cancel this Deal</span>
            );
        } else {
            cancelNotice = (<div>
                    <div className={"cancel-notice"}>This deal has expired or was cancelled</div>
                    <div className={"notice"} style={{textAlign:'center'}}>Click below to re-activate deal, please make any necessary changes, then click save.</div>
                    <div style={{textAlign:'center'}}><span className={"btn btn-primary"} onClick={this.renewDeal}>Re-activate Deal</span></div>
                </div>
            );
        }

        return(
            <div className={"section-container deal-info-widget"}>
                <div className={"section-content"}>
                    <div className={"widget-heading"}>
                        <div className={"widget-title"}>Deal Stats</div>
                        <div className={"widget-actions"}>
                            { cancelButton }
                        </div>
                    </div>

                    { cancelNotice }

                    <div className={"info-segments"}>
                        <div className={"info-segment"}>
                            <div className={"segment-title"}>
                                Impressions
                            </div>
                            <div className={"segment-value"}>
                                { this.props.deal.impressions }
                            </div>
                        </div>

                        <div className={"info-segment"}>
                            <div className={"segment-title"}>
                                Click Through
                            </div>
                            <span style={{fontSize:"9pt"}}>(number of times call to action URL is clicked)</span>
                            <div className={"segment-value"}>
                                { this.props.deal.clicks }
                            </div>
                        </div>

                        {/* <div className={"info-segment"}>
                            <div className={"segment-title"}>
                                Massiveness
                            </div>
                            <div className={"segment-value"}>
                                { this.props.deal.karmaCache }
                            </div>
                        </div> */}

                        <div className={"info-segment"}>
                            <div className={"segment-title"}>
                                Total Likes
                            </div>
                            <div className={"segment-value"}>
                                { this.props.deal.karmaCache }
                            </div>
                        </div>

                        {/* <div className={"info-segment"}>
                            <div className={"segment-title"}>
                                Downvotes
                            </div>
                            <div className={"segment-value"}>
                                { this.props.deal.downvoteCount }
                            </div>
                        </div>*/}
                    </div>
                </div>

                <AlertModal
                    open={ (this.state.modal != null) ? this.state.modal.open : false }
                    title={ (this.state.modal != null) ? this.state.modal.title : "" }
                    content={ (this.state.modal != null) ? this.state.modal.content : [] }
                    buttons={ (this.state.modal != null) ? this.state.modal.buttons : [] }
                    dismissHandler={ () => this.modalWasDismissed() } />
            </div>
        );
    }

}

export default DealInfoComponent;