import React from 'react';
import StatefulComponent from "../StatefulComponent";
import HeaderComponent from "../common/HeaderComponent";
import TableComponent, {Column, TablePaginator} from "../common/TableComponent";
import Axios from "axios";
import {API, ENDPOINTS} from "../../networking/API";

class UserListScreenComponent extends StatefulComponent {

    filterKeywordTimeout = undefined;

    constructor(props) {
        super(props);

        this.handleScreenRestriction([API.USER_TYPES.ADMINISTRATOR]);

        var userWasDeleted = false;
        if (localStorage.getItem("userWasDeleted") != null) {
            userWasDeleted = true;
            localStorage.removeItem("userWasDeleted");
        }

        this.initState({
            tablePage : 1,
            tableTotal : 0,
            tableData : [],
            tableLimit : 20,
            userWasDeleted : userWasDeleted,
            sortedColumn : {name : "name", direction: "asc"},
            userFilter : ""
        });

        this.tablePageDidChange = this.tablePageDidChange.bind(this);
        this.tableRowWasClicked = this.tableRowWasClicked.bind(this);
        this.moveToUserEditor = this.moveToUserEditor.bind(this);
        this.getTableDataFromNetwork = this.getTableDataFromNetwork.bind(this);
    }

    componentDidMount() {
        this.getTableDataFromNetwork();
    }

    tablePageDidChange(page) {
        this.setState({
            tablePage : page
        });

        this.getTableDataFromNetwork(page);
    }

    tableRowWasClicked(row) {
        this.moveToUserEditor(row.id);
    }

    moveToUserEditor(id) {
        window.location.href = "/users/" + id;
    }

    getTableDataFromNetwork(page, sort) {
        if (this.state.fetchingTableData) return;
        this.setState({
            fetchingTableData : true
        });

        if (page === undefined) {
            page = this.state.tablePage;
        }

        if (sort === undefined) {
            sort = this.state.sortedColumn;
        }

        let formData = new FormData();
        formData.append("page", page);
        formData.append("limit", this.state.tableLimit);

        if (sort !== undefined) {
            formData.append("sort", JSON.stringify(sort));
        }

        if (this.state.userFilter !== undefined && this.state.userFilter !== "") {
            formData.append("keyword", this.state.userFilter);
        }

        var context = this;

        Axios.post(ENDPOINTS.user.getList, formData)
            .then(function(response) {
               let resp = API.parseResponse(response);
               if (resp.success) {
                   context.setState({
                       fetchingTableData : false,
                       tableData : resp.data.data,
                       tableTotal : resp.data.count
                   });
               } else {
                   context.setState({
                       fetchingTableData : false
                   });
                   context.showModal("Error", resp.error);
               }
            })
            .catch(function(err) {
                console.log(err);
                context.setState({
                    fetchingTableData : false
                });
                context.showModal("Error", "An unknown error occurred. [101]");
            });
    }

    tableWasSorted = (sort) => {
        this.getTableDataFromNetwork(this.state.tablePage, sort);

        this.setState({
            sortedColumn : sort
        });
    }

    filterDidChange = (event) => {
        clearTimeout(this.filterKeywordTimeout);
        this.filterKeywordTimeout = setTimeout(() => {
            this.getTableDataFromNetwork(1);
        }, 800);

        this.setState({
            tablePage : 1
        });

        this.handleChange(event);
    }

    render() {
        var messageElem = [];
        if (this.state.userWasDeleted) {
            messageElem.push(
                <div className="alert alert-success" role="alert">User was successfully deleted.</div>
            )
        }

        return (
            <div className={"container"}>
                <HeaderComponent />

                <div className={"page-header"}>
                    <div className={"page-title"}>Users</div>
                    <div className={"page-actions"}>
                        <button className={"btn btn-success"} onClick={() => this.moveToUserEditor("new")}>Add a new User</button>
                    </div>
                </div>

                {messageElem}

                <div className={"row form-block filter-area"}>
                    <div className={"col-12"}>
                        <label htmlFor={"userFilter"}>Search</label>
                        <input type={"text"} className={"form-control"} name={"userFilter"} value={this.state.userFilter} onChange={this.filterDidChange} placeholder={"Search for Users"} />
                    </div>
                </div>

                <div className={"row form-block"}>
                    <div className={"col-12"}>
                        <TableComponent
                            className={"alternating clickable"}
                            data={this.state.tableData}
                            onRowClick={(row) => { console.log(row); this.tableRowWasClicked(row);}}
                            sortedColumn={this.state.sortedColumn}
                            onSort={(sort) => this.tableWasSorted(sort)}>
                            <Column name={"name"} title={"User Name"} sortable={true} />
                            <Column name={"email"} title={"User Email"} sortable={true} />
                            <Column name={"merchantUserType"} title={"User Type"} sortable={true} />
                            <Column name={"merchantLocationName"} title={"Assigned To"} sortable={true} />
                        </TableComponent>

                        <div className={"form-block ep-table-paginate-container"}>
                            <div className={"push"} />
                            <TablePaginator
                                page={this.state.tablePage}
                                totalCount={this.state.tableTotal}
                                pageSize={this.state.tableLimit}
                                onClick={(page) => this.tablePageDidChange(page)} />
                        </div>

                    </div>
                </div>
            </div>
        );
    }

}

export default UserListScreenComponent;