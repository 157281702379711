import React from 'react';
import '../../assets/css/ep-slider.css'

/*
    Exploding Phone Slider
    chris@explodingphone.com

    Simple slider element that a user can drag and interact with.
    Clicking anywhere within the slider will modify the value, not just dragging the handle.

    Uses following CSS classes:
        .ep-slider : Base slider
        .bar : The bar element, use background-color to modify bar colour.
        .handle : The handle element, use border-color to modify handle colour

 */
class SliderComponent extends React.Component {

    constructor(props, context) {
        super(props, context);

        /*var min = 0;
        var max = 100;
        var value = 0;

        if (props.min != null) {
            min = parseInt(props.min);
        }

        if (props.max != null) {
            max = parseInt(props.max);
        }

        if (props.value != null) {
            value = parseInt(props.max);
        }*/

        this.state = {
            /*min : min,
            max : max,
            value : value,*/
            mousedown : false
        };
        this.setState(this.state);

        this.slider = React.createRef();
        this.sliderBar = React.createRef();
        this.sliderHandle = React.createRef();

        this.setMouseState = this.setMouseState.bind(this);
        this.onMouseMove = this.onMouseMove.bind(this);
        this.onSliderInteract = this.onSliderInteract.bind(this);
        this.getSliderCurrentLeft = this.getSliderCurrentLeft.bind(this);
    }

    setMouseState(status) {
        this.setState({
            mousedown : status
        });
    }

    onMouseMove(e) {
        if (!this.state.mousedown) return;
        this.onSliderInteract(e);
    }

    onSliderInteract(event) {
        var min = this._getMin();
        var max = this._getMax();

        var elemBounds = this.slider.current.getBoundingClientRect();

        var elemLeft = elemBounds.left;

        var pointerX = event.clientX - elemLeft;
        var elemWidth = elemBounds.right - elemBounds.left;

        var percent = pointerX / elemWidth;
        percent = Math.min(percent, 1);
        percent = Math.max(percent, 0);

        var value = parseInt(max - min) * percent;
        value = this._rangeConvert(value, (max - min), 0, max, min);

      //  if (this.props.onChange != null) {
            this.props.onChange(parseInt(value));
      //  }
    }

    getSliderCurrentLeft() {
        var value = this._getValue();

        var min = this._getMin();
        var max = this._getMax();

        var valueAdjust = this._rangeConvert(value, max, min, 100, 0);
        var rangeAdjust = this._rangeConvert(max, max, min, 100, 0);

        var percent = parseFloat(valueAdjust / rangeAdjust);
        percent = Math.min(percent, 1);
        percent = Math.max(percent, 0);

        return percent * 100;
    }

    _getMin() {
        if (this.props.min != null) {
            return this.props.min;
        }
        return 0;
    }

    _getMax() {
        if (this.props.max != null) {
            return this.props.max;
        }
        return 100;
    }

    _getValue() {
        if (this.props.value) {
            return this.props.value;
        }
        return this._getMin();
    }

    _rangeConvert(input,oldMax, oldMin, newMax, newMin) {
        return (((input - oldMin) * (newMax - newMin)) / (oldMax - oldMin)) + newMin;
    }

    render() {
        var handleLeft = this.getSliderCurrentLeft();

        return (
            <div className={"ep-slider"} ref={this.slider} onMouseDown={() => this.setMouseState(true)} onMouseUp={() => this.setMouseState(false)} onMouseLeave={() => this.setMouseState(false)} onMouseMove={this.onMouseMove}>
                <div className={"bar"} ref={this.sliderBar} />
                <div className={"handle"} ref={this.sliderHandle} style={{ left : handleLeft + "%" }}/>
            </div>
        );
    }

}

export default SliderComponent;