import React from "react";
import StatefulComponent from "../StatefulComponent";
import {API, ENDPOINTS} from "../../networking/API";
import Axios from "axios";
import '../../assets/css/login.css';
import AlertModal from "../common/AlertModal";
import {Link} from "react-router-dom";

class LoginScreen extends StatefulComponent {

    constructor(props, context) {
        super(props, context);

        this.initState({
            email : "",
            password : ""
        });

        this.handleChange = this.handleChange.bind(this);
        this.handleFormKeyPress = this.handleFormKeyPress.bind(this);

        this.loginBox = React.createRef();
    }

    componentDidMount() {
        super.componentDidMount();

        this.alignLoginBox();
    }

    updateWindowDimensions() {
        super.updateWindowDimensions();

        this.alignLoginBox();
    }

    alignLoginBox() {
        var loginBoxStyle = window.getComputedStyle(this.loginBox.current, null);

        var marginTop = (window.innerHeight - parseInt(loginBoxStyle.getPropertyValue("height"))) / 2;
        marginTop = Math.max(0, marginTop);

        this.loginBox.current.style.marginTop = marginTop + "px";
    }

    onLoginClicked() {
        if (this.state.email.length > 0 && this.state.password.length > 0) {
            var formData = new FormData();
            formData.append("email", this.state.email);
            formData.append("password", this.state.password);

            Axios.post(ENDPOINTS.login.submit, formData)
                .then((response) => {
                    if (response.data.success) {
                        this.props.loginCallback(response.data.data.user, response.data.data.authenticationToken)
                    } else {
                        console.log(response.data.error);
                        this.showModal("Error", response.data.error);
                    }
                })
                .catch(error => {
                    console.log("ERROR");
                    console.log(error);

                    this.showModal("Error", "Could not login at this time. Please try again later. [100]")
                });
        } else {
            console.log("VALIDATION ERROR");
            this.showModal("Error", "Please provide an Email Address and Password");
        }
    }

    handleFormKeyPress(e) {
        if (e.key === 'Enter') {
            this.onLoginClicked();
        }
    }

    render() {
        return (
            <div className="container">
                <div className="row">
                    <div className="hidden-xs col-md-2 col-lg-3 col-xl-4" />
                    <div className="col-xs-12 col-md-8 col-lg-9 col-xl-4">
                        <div className="login-box" ref={this.loginBox}>
                            <div className="form-block text-center">
                                <img src={require("../../assets/img/gas-app-logo-blue.png")} alt="Gas App Uk&reg;" className="logo" />
                            </div>

                            <div className="form-block text-center">
                                <strong>Merchant Portal</strong>
                            </div>

                            <div className="form-block">
                                <label>Email Address</label><br />
                                <input type="text" name="email" placeholder="Email" className="form-control" value={this.state.email} onChange={this.handleChange} onKeyPress={this.handleFormKeyPress} />
                            </div>

                            <div className="form-block">
                                <label>Password</label><br />
                                <input type="password" name="password" placeholder="Password" className="form-control" value={this.state.password} onChange={this.handleChange} onKeyPress={this.handleFormKeyPress} />
                            </div>

                            <div className="form-block text-centre">
                                <input type="submit" value="Login" className="btn btn-success" onClick={ () => this.onLoginClicked() } />
                            </div>

                            <div className="form-block text-centre">
                                <Link to={"/forgotten"}>Forgotten Password?</Link>
                            </div>
                        </div>
                    </div>
                </div>

                <AlertModal
                    open={ (this.state.modal != null) ? this.state.modal.open : false }
                    title={ (this.state.modal != null) ? this.state.modal.title : "" }
                    content={ (this.state.modal != null) ? this.state.modal.content : [] }
                    buttons={ (this.state.modal != null) ? this.state.modal.buttons : [] }
                    dismissHandler={ () => this.modalWasDismissed() }
                />
            </div>
        );
    }

}

export default LoginScreen;