import StatefulComponent from "../StatefulComponent";
import DatePicker from "react-datepicker/es";
import React from "react";
import TableComponent, {Column, TablePaginator} from "../common/TableComponent";
import moment from "moment";
import LoadingSpinner from "../common/LoadingSpinner";
import Axios from "axios";
import {API, ENDPOINTS} from "../../networking/API";

export default class LocationNoDealReportContainerComponent extends StatefulComponent {

    constructor(props, context) {
        super(props, context);

        this.initState({
            reportNetworkInFlight : false,
            reportData : [],
            tableData : null,
            startDate : moment().add(-6, "months").toDate(),
            endDate : moment().toDate(),
            currentPage : 1,
            totalCount : 0,
            pageSize : 50,
            reportKeyword : ""
        });
    }

    handleDateChange = (key, date) => {
        let state = this.state;

        state[key] = date;

        this.setState(state);
    }

    pageDidChange = (page) => {
        let tableData = [];

        let reportData = this.state.reportData;
        let totalCount = reportData.length;

        if (this.state.reportKeyword !== null && this.state.reportKeyword !== "") {
            let filteredData = [];

            for (let i = 0; i < reportData.length; i++) {
                if (reportData[i].locationName.toLowerCase().includes(this.state.reportKeyword.toLowerCase())) {
                    filteredData.push(reportData[i]);
                }
            }

            reportData = filteredData;
            totalCount = filteredData.length;
        }

        if (reportData != null && reportData.length > 0) {
            let start = this.state.pageSize * (page - 1);

            for (let i = start; i < reportData.length; i++) {
                tableData.push(reportData[i]);

                if (tableData.length >= this.state.pageSize) {
                    break;
                }
            }
        }

        this.setState({
            tableData,
            totalCount,
            currentPage : page
        });
    }

    fetchReportFromNetwork = () => {
        if (this.state.reportNetworkInFlight) return;
        this.setState({
            reportNetworkInFlight : true
        });

        if (this.state.startDate === null || this.state.endDate === null) {
            this.showModal("Error", "Please provide a Start and End Date.");
            return;
        }

        if (moment(this.state.endDate).isBefore(moment(this.state.startDate))) {
            this.showModal("Error", "The Start Date cannot be before the End Date. Please adjust the date range and try agan.");
            return;
        }

        this.setState({
            reportNetworkInFlight : true,
            widgetsCollapsed : true
        });

        let formData = new FormData();
        formData.append("startDate", (this.state.startDate.getTime() / 1000).toFixed(0));
        formData.append("endDate", (this.state.endDate.getTime() / 1000).toFixed(0));

        Axios.post(ENDPOINTS.deal.getBranchesWithoutDeals, formData)
            .then((r) => {
                let reportData = [];
                let totalCount = 0;

                let resp = API.parseResponse(r);
                if (resp.success) {
                    reportData = resp.data.reportData;
                    totalCount = reportData.length;
                } else {
                    this.showModal("Error", resp.error);
                }

                this.setState({
                    reportNetworkInFlight : false,
                    reportData,
                    totalCount
                }, () => {
                    this.pageDidChange(1);
                });
            })
            .catch((e) => {
                console.log(e);
            });
    }

    render() {
        let reportArea = [];
        if (this.state.reportNetworkInFlight) {
            reportArea = (
                <div className={"row mt-4"}>
                    <div className={"col-12 text-center"}>
                        <LoadingSpinner shown={true} />
                    </div>
                </div>
            )
        } else if (this.state.tableData != null) {
            reportArea.push(
                <TableComponent
                    className={"alternating clickable"}
                    data={this.state.tableData}
                    suppressNulls={true}
                    onRowClick={(row) => { console.log(row); }}>

                    <Column name={"name"} title={"Location"} className={"text-center"} sortable={false} />
                    <Column name={"addressName"} title={"Address Name"} sortable={false} />
                    <Column name={"addressLine1"} title={"Address Line 1"} sortable={false} />
                    <Column name={"addressLine2"} title={"Address Line 2"} className={"text-center"} sortable={false} />
                    <Column name={"addressCity"} title={"City"} className={"text-center"} sortable={false} />
                    <Column name={"addressCounty"} title={"County"} className={"text-center"} sortable={false} />
                    <Column name={"addressPostcode"} title={"Postcode"} className={"text-center"} sortable={false} />

                </TableComponent>
            )

            reportArea.push(
                <div className={"form-block ep-table-paginate-container"}>
                    <div className={"push"} />
                    <TablePaginator
                        page={this.state.currentPage}
                        totalCount={this.state.totalCount}
                        pageSize={this.state.pageSize}
                        onClick={(page) => this.pageDidChange(page)} />
                </div>
            )

            reportArea = (
                <div className={"row mt-4"}>
                    <div className={"col-12"}>
                        {reportArea}
                    </div>
                </div>
            );
        }

        return (
            <div>
                <div className={"row form-block filter-area"}>
                    <div className={"col-12 col-md-6 date-fields"}>
                        <div className={"date-row"}>
                            <label>Start Date</label>
                            <DatePicker
                                className={"form-control text-center"}
                                name={"fromDate"}
                                selected={this.state.startDate}
                                dateFormat="dd/MM/yyyy"
                                onChange={(date) => this.handleDateChange("startDate", date)} />
                        </div>
                    </div>

                    <div className={"col-12 col-md-6 date-fields"}>
                        <div className={"date-row"}>
                            <label>End Date</label>
                            <DatePicker
                                className={"form-control text-center"}
                                name={"fromDate"}
                                selected={this.state.endDate}
                                dateFormat="dd/MM/yyyy"
                                onChange={(date) => this.handleDateChange("endDate", date)} />
                        </div>
                    </div>

                    <div className={"col-12 text-right"}>
                        <span className={"btn btn-primary"} onClick={this.fetchReportFromNetwork}>Generate Report</span>
                    </div>
                </div>

                {reportArea}
            </div>
        )
    }

}